import Resizer from "react-image-file-resizer";
import { IUserInfo } from "../../account/account-models";
import { IS_PROD } from "../../globals";
import { sendWarningMessage } from "../../utils/slack";

export interface IFileWrapper {
  file: File;
  type: EFileType;
  width?: number;
  height?: number;
  duration?: number;
  elm?: any; // HTMLImageElement | HTMLVideoElement
  error?: string;
}

export enum EFileType {
  Image = "image",
  Video = "video",
  Other = "other",
}

export const ALLOWED_IMAGE_TYPES = ["image/*"]; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
export const FORBIDDEN_IMAGE_TYPES: string[] = [
  // "image/heic"
];
export const ALLOWED_VIDEO_TYPES = [
  "video/quicktime",
  "video/mp4",
  "video/x-m4v",
  "video/mov",
  "video/flv",
  "video/ogv",
  "video/webm",
  "video/*", // https://stackoverflow.com/questions/19107685/safari-input-type-file-accept-video-ignores-mp4-files
];

const VIDEO_LOAD_FALLBACK_TIMEOUT = 5000;
const MAX_VIDEO_DURATION = Number.MAX_SAFE_INTEGER; // no limit
const MAX_FILE_SIZE_PROD_BENDINARY = 499;
const MAX_FILE_SIZE_DEV_BENDINARY = 499;
const MAX_FILE_SIZE_PROD_CLOUDINARY = 499;
const MAX_FILE_SIZE_DEV_CLOUDINARY = 99;
const MAX_IMAGE_WIDTH = 1440;
const MAX_IMAGE_HEIGHT = 1440;

export function bytesToMegaBytes(bytes: number) {
  return bytes / 1024 / 1024;
}

export function getFileSizeInMegaBytes(file: File) {
  return bytesToMegaBytes(file.size);
}

export function bytesToHumanReadable(sizeInBytes: number): string {
  const i =
    sizeInBytes === 0 ? 0 : Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  return `${Number((sizeInBytes / Math.pow(1024, i)).toFixed(1))} ${
    ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][i]
  }`;
}

const resizeFile = (file: File): Promise<File> =>
  new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(
        file,
        MAX_IMAGE_WIDTH,
        MAX_IMAGE_HEIGHT,
        "JPEG",
        100,
        0,
        (result) => {
          resolve(
            new File([result as Blob], file.name, {
              type: "image/jpeg",
              lastModified: file.lastModified,
            })
          );
        },
        "blob"
      );
    } catch (error) {
      reject(error);
    }
  });

export function processFiles(
  files: File[],
  isImagesOnly: boolean,
  isVideosOnly: boolean,
  isBendinary: boolean,
  user: IUserInfo
): Promise<IFileWrapper[]> {
  const MAX_FILE_SIZE = IS_PROD
    ? isBendinary
      ? MAX_FILE_SIZE_PROD_BENDINARY
      : MAX_FILE_SIZE_PROD_CLOUDINARY
    : isBendinary
      ? MAX_FILE_SIZE_DEV_BENDINARY
      : MAX_FILE_SIZE_DEV_CLOUDINARY;

  return Promise.all<IFileWrapper>(
    files.map((file, i) => {
      return new Promise((resolve, reject) => {
        const fileSize = getFileSizeInMegaBytes(file);

        if (file.type.startsWith("image/")) {
          // IMAGE
          const origImgElm = document.createElement("img");
          origImgElm.src = URL.createObjectURL(file); // original
          let width = 0;
          let height = 0;

          if (isVideosOnly) {
            resolve({
              file,
              error:
                "Photos are not allowed here. Please choose a video instead.",
              type: EFileType.Image,
              elm: origImgElm,
            });
          }

          // const isImageSupported = ALLOWED_IMAGE_TYPES.indexOf(file.type) > -1;
          const isImageSupported =
            FORBIDDEN_IMAGE_TYPES.indexOf(file.type) === -1;
          if (!isImageSupported) {
            sendWarningMessage(
              `\`${user.username}\` is attempting to upload unsupported file type: \`${file.type}\`.`
            );
            resolve({
              file,
              error: `Sorry, we cannot process this type of file (${file.type}). Please choose different file type and try again.`,
              type: EFileType.Other,
              elm: null,
            });
          }

          if (fileSize > MAX_FILE_SIZE) {
            resolve({
              file,
              error:
                "This photo is too large. Please use a smaller photo or set lower camera quality in your camera settings.",
              type: EFileType.Image,
              elm: origImgElm,
            });
          }

          origImgElm.onload = () => {
            width = origImgElm.naturalWidth;
            height = origImgElm.naturalHeight;

            resizeFile(file).then(
              (compressedFile) => {
                const compressedImgElm = document.createElement("img");
                compressedImgElm.src = URL.createObjectURL(compressedFile); // compressed

                compressedImgElm.onload = () => {
                  width = compressedImgElm.naturalWidth;
                  height = compressedImgElm.naturalHeight;

                  resolve({
                    file: compressedFile,
                    error: undefined,
                    type: EFileType.Image,
                    width,
                    height,
                    elm: compressedImgElm,
                  });
                };

                compressedImgElm.onerror = () => {
                  console.warn("compressImage failed to load compressed image");
                  sendWarningMessage(
                    `\`${user.username}\` compressImage failed to load compressed image, attempting to upload original file instead: \`${file.type}\`}.`
                  );

                  // proceed with original image
                  resolve({
                    file,
                    error: undefined,
                    type: EFileType.Image,
                    width,
                    height,
                    elm: origImgElm,
                  });
                };
              },
              (error) => {
                console.warn("compressImage failed", error);
                sendWarningMessage(
                  `\`${
                    user.username
                  }\` compressImage failed with error, attempting to upload original file instead: \`${
                    file.type
                  }\` \`\`\`${JSON.stringify(error)}\`\`\`}.`
                );

                // proceed with original image
                resolve({
                  file,
                  error: undefined,
                  type: EFileType.Image,
                  width,
                  height,
                  elm: origImgElm,
                });
              }
            );
          };

          origImgElm.onerror = () => {
            resolve({
              file,
              error:
                "There was an error processing this image. Please try again later.",
              type: EFileType.Image,
              elm: origImgElm,
            });
          };
        } else if (file.type.startsWith("video/")) {
          // VIDEO
          const videoElm = document.createElement("video");
          videoElm.muted = true;
          videoElm.preload = "auto";
          videoElm.src = URL.createObjectURL(file);
          videoElm.load(); // try to force load (mobile browsers can prevent from loading)

          let width = 0;
          let height = 0;
          let duration = 0;

          if (isImagesOnly) {
            resolve({
              file,
              error:
                "Videos are not allowed here. Please choose a photo instead.",
              type: EFileType.Video,
              width,
              height,
              elm: videoElm,
            });
          }

          const isVideoSupported = ALLOWED_VIDEO_TYPES.indexOf(file.type) > -1;
          if (!isVideoSupported) {
            sendWarningMessage(
              `\`${user.username}\` is attempting to upload unsupported file type: \`${file.type}\`.`
            );
            resolve({
              file,
              error: `Sorry, we cannot process this type of file (${file.type}). Please choose different file type and try again.`,
              type: EFileType.Other,
              elm: null,
            });
          }

          if (fileSize > MAX_FILE_SIZE) {
            resolve({
              file,
              error:
                "This video is too large. Please use a smaller video or set lower video quality in your camera settings.",
              type: EFileType.Video,
              width,
              height,
              elm: videoElm,
            });
          }

          // FALLBACK - start a backup timer here for processing the video
          // in case onloadedmetadata gets never fired or takes too long to fire
          let timeout = setTimeout(() => {
            sendWarningMessage(
              `\`${
                user.username
              }\` processing the video to upload is taking too long, media type: \`${
                file.type
              }\` file size: \`${getFileSizeInMegaBytes(
                file
              )}\`. Continuing without any dimension or duration info...`
            );
            resolve({
              file,
              error: undefined,
              type: EFileType.Video,
              elm: videoElm,
            });
          }, VIDEO_LOAD_FALLBACK_TIMEOUT);

          videoElm.onloadedmetadata = () => {
            clearTimeout(timeout);

            width = videoElm.videoWidth;
            height = videoElm.videoHeight;
            duration = Math.floor(videoElm.duration);

            if (duration > MAX_VIDEO_DURATION) {
              resolve({
                file,
                error:
                  "Maximum video length is one minute, please choose a shorter video.",
                type: EFileType.Video,
                width,
                height,
                duration,
                elm: videoElm,
              });
            } else {
              resolve({
                file,
                error: undefined,
                type: EFileType.Video,
                width,
                height,
                duration,
                elm: videoElm,
              });
            }
          };

          videoElm.onerror = () => {
            clearTimeout(timeout);
            resolve({
              file,
              error:
                "There was an error processing this video. Please try again later.",
              type: EFileType.Video,
              elm: videoElm,
            });
          };
        } else {
          // OTHER
          sendWarningMessage(
            `\`${user.username}\` is attempting to upload unsupported file type: \`${file.type}\`.`
          );
          resolve({
            file,
            error: `Sorry, we cannot process this type of file (${file.type}). Please choose different file type and try again.`,
            type: EFileType.Other,
            elm: null,
          });
        }
      });
    })
  );
}
