export const ICONS_ACCOUNT = {
  Plus: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M4.74167 0V4.74167H0V6.63833H4.74167V11.38H6.63833V6.63833H11.38V4.74167H6.63833V0H4.74167Z"
        transform="translate(0.307617 0.307617)"
        fill="#AEAEAE"
        stroke="#AEAEAE"
        strokeWidth="0.3"
      />
    </svg>
  ),
  VerifiedStar: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.14726 14.8006C7.91404 14.7008 7.53355 14.7018 7.30268 14.8006L5.90212 15.3998C5.6689 15.4996 5.36269 15.4072 5.22141 15.1982L4.36434 13.9302C4.22162 13.719 3.901 13.5123 3.65383 13.4695L2.15446 13.2098C1.90478 13.1665 1.6967 12.9218 1.68987 12.6689L1.6484 11.1347C1.6415 10.8792 1.48253 10.5305 1.29755 10.3597L0.175393 9.32341C-0.0114735 9.15084 -0.0553552 8.83147 0.0744269 8.61499L0.861724 7.30175C0.992829 7.08306 1.04599 6.70298 0.981922 6.45839L0.593263 4.97458C0.528542 4.72749 0.662785 4.43488 0.88798 4.32355L2.25408 3.64818C2.48157 3.53572 2.72998 3.24497 2.80717 3.00426L3.2754 1.54402C3.35337 1.30086 3.62312 1.12792 3.87223 1.15708L5.3834 1.33401C5.63505 1.36347 5.99985 1.25437 6.19378 1.09396L7.37024 0.120915C7.56615 -0.0411211 7.88576 -0.039487 8.0797 0.120915L9.25616 1.09396C9.45207 1.256 9.81743 1.36318 10.0665 1.33401L11.5777 1.15708C11.8294 1.12762 12.0974 1.30331 12.1745 1.54402L12.6428 3.00426C12.7207 3.24742 12.9707 3.53685 13.1959 3.64818L14.562 4.32355C14.7894 4.43601 14.9207 4.72998 14.8567 4.97458L14.468 6.45839C14.4033 6.70548 14.4584 7.08527 14.5882 7.30175L15.3755 8.61499C15.5066 8.83367 15.4595 9.15258 15.2745 9.32341L14.1524 10.3597C13.9655 10.5323 13.8084 10.8818 13.8015 11.1347L13.7601 12.6689C13.7532 12.9244 13.5426 13.167 13.2955 13.2098L11.7961 13.4695C11.5464 13.5128 11.2269 13.7211 11.0856 13.9302L10.2285 15.1982C10.0858 15.4093 9.77869 15.4986 9.54781 15.3998L8.14726 14.8006Z"
        fill="currentColor"
      />
      <path
        d="M10.3277 6L6.94453 9.62903L5.3999 8.3048"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  ),
  Location: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
      <circle cx="12" cy="10" r="3" />
    </svg>
  ),
  Padlock: (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        d="M8.33325 4.16667H7.49992V2.5C7.49992 1.83696 7.23653 1.20107 6.76769 0.732233C6.29885 0.263392 5.66296 0 4.99992 0C4.33688 0 3.70099 0.263392 3.23215 0.732233C2.76331 1.20107 2.49992 1.83696 2.49992 2.5V4.16667H1.66659C1.44557 4.16667 1.23361 4.25446 1.07733 4.41074C0.921049 4.56702 0.833252 4.77899 0.833252 5V9.16667C0.833252 9.38768 0.921049 9.59964 1.07733 9.75592C1.23361 9.9122 1.44557 10 1.66659 10H8.33325C8.55427 10 8.76623 9.9122 8.92251 9.75592C9.07879 9.59964 9.16659 9.38768 9.16659 9.16667V5C9.16659 4.77899 9.07879 4.56702 8.92251 4.41074C8.76623 4.25446 8.55427 4.16667 8.33325 4.16667ZM5.83325 4.16667H4.16659V2.5C4.16659 2.27899 4.25438 2.06702 4.41066 1.91074C4.56694 1.75446 4.77891 1.66667 4.99992 1.66667C5.22093 1.66667 5.43289 1.75446 5.58917 1.91074C5.74545 2.06702 5.83325 2.27899 5.83325 2.5V4.16667Z"
        fill="#049EF6"
      />
    </svg>
  ),
  Padlock2: (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#padlock2-clip0)">
        <path
          d="M13.6603 8.45391V6.85391C13.6603 5.25391 13.2603 3.75391 12.3603 2.55391C11.3603 1.45391 9.9603 0.753906 8.2603 0.753906C6.5603 0.753906 5.0603 1.45391 4.1603 2.65391C3.2603 3.75391 2.8603 5.25391 2.8603 6.85391V8.45391C1.5603 8.45391 0.560303 9.45391 0.560303 10.7539V18.4539C0.560303 19.7539 1.5603 20.7539 2.8603 20.7539H13.6603C14.9603 20.7539 15.9603 19.7539 15.9603 18.4539V10.7539C15.9603 9.45391 14.9603 8.45391 13.6603 8.45391ZM5.9603 4.05391C6.4603 3.45391 7.1603 3.05391 8.2603 3.05391C9.3603 3.05391 10.0603 3.35391 10.5603 4.05391C11.0603 4.65391 11.3603 5.65391 11.3603 6.85391V8.45391H5.1603V6.85391C5.1603 5.65391 5.4603 4.65391 5.9603 4.05391ZM9.0603 15.1539V16.9539C9.0603 17.3539 8.7603 17.7539 8.2603 17.7539C7.7603 17.7539 7.4603 17.4539 7.4603 16.9539V15.1539C6.9603 14.8539 6.6603 14.3539 6.6603 13.8539C6.6603 13.0539 7.3603 12.3539 8.1603 12.3539C8.9603 12.3539 9.6603 13.0539 9.6603 13.8539C9.7603 14.3539 9.4603 14.8539 9.0603 15.1539Z"
          fill="#0094F4"
        />
        <path
          d="M13.6603 8.45391V6.85391C13.6603 5.25391 13.2603 3.75391 12.3603 2.55391C11.3603 1.45391 9.9603 0.753906 8.2603 0.753906C6.5603 0.753906 5.0603 1.45391 4.1603 2.65391C3.2603 3.75391 2.8603 5.25391 2.8603 6.85391V8.45391C1.5603 8.45391 0.560303 9.45391 0.560303 10.7539V18.4539C0.560303 19.7539 1.5603 20.7539 2.8603 20.7539H13.6603C14.9603 20.7539 15.9603 19.7539 15.9603 18.4539V10.7539C15.9603 9.45391 14.9603 8.45391 13.6603 8.45391ZM5.9603 4.05391C6.4603 3.45391 7.1603 3.05391 8.2603 3.05391C9.3603 3.05391 10.0603 3.35391 10.5603 4.05391C11.0603 4.65391 11.3603 5.65391 11.3603 6.85391V8.45391H5.1603V6.85391C5.1603 5.65391 5.4603 4.65391 5.9603 4.05391ZM9.0603 15.1539V16.9539C9.0603 17.3539 8.7603 17.7539 8.2603 17.7539C7.7603 17.7539 7.4603 17.4539 7.4603 16.9539V15.1539C6.9603 14.8539 6.6603 14.3539 6.6603 13.8539C6.6603 13.0539 7.3603 12.3539 8.1603 12.3539C8.9603 12.3539 9.6603 13.0539 9.6603 13.8539C9.7603 14.3539 9.4603 14.8539 9.0603 15.1539Z"
          fill="#303637"
        />
      </g>
      <defs>
        <clipPath id="padlock2-clip0">
          <rect
            width="15.4"
            height="20"
            fill="white"
            transform="translate(0.560303 0.753906)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  Unlock: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#unlock-clip0)">
        <path
          d="M5.76931 4.15294V3.6531C5.76931 1.95094 4.92423 0.691406 3.23084 0.691406C1.53746 0.691406 0.692383 1.96387 0.692383 3.6531V5.07602"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          d="M10.6157 4.61328H4.15415C3.38984 4.61328 2.76953 5.23359 2.76953 5.9979V10.6133C2.76953 11.3776 3.38984 11.9979 4.15415 11.9979H10.6157C11.38 11.9979 12.0003 11.3776 12.0003 10.6133V5.9979C12.0003 5.23359 11.38 4.61328 10.6157 4.61328ZM7.84645 8.63928V9.69021C7.84645 9.94451 7.63922 10.1517 7.38492 10.1517C7.13061 10.1517 6.92338 9.94451 6.92338 9.69021V8.63928C6.6483 8.47913 6.46184 8.18513 6.46184 7.84405C6.46184 7.33451 6.87538 6.92097 7.38492 6.92097C7.89445 6.92097 8.30799 7.33451 8.30799 7.84405C8.30799 8.18467 8.12153 8.47913 7.84645 8.63928Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="unlock-clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Tick: (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none">
      <rect
        width="2.20873"
        height="14.7249"
        rx="1.10437"
        transform="matrix(0.707961 0.706251 -0.707961 0.706251 14.1597 0)"
        fill="#0CB549"
      />
      <rect
        y="6.85645"
        width="2.20873"
        height="7.36244"
        rx="1.10437"
        transform="rotate(-45 0 6.85645)"
        fill="#0CB549"
      />
    </svg>
  ),
  TickFancy: (
    <svg width="25" height="21" viewBox="0 0 25 21" fill="none">
      <path
        d="M7.49857 15.8516C12.2543 9.35505 23.8325 -0.350124 24.4947 0.422329C23.3309 1.78898 22.0867 3.11601 21.0032 4.56188C17.5518 9.11738 14.1204 13.7125 10.7292 18.3274C9.445 20.0704 8.16076 20.7438 6.53539 20.1892C5.95347 19.9911 5.35148 19.5554 4.97022 19.08C3.42512 17.1192 2.00042 15.0791 0.495447 13.0985C-0.0262757 12.4053 0.21452 11.9497 0.816507 11.5338C1.88002 10.8009 3.34486 11.1178 4.54883 12.3062C5.77287 13.5144 6.37486 14.3067 7.49857 15.8516Z"
        fill="#0CB549"
      />
    </svg>
  ),
  Cross: (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
      <path
        d="M10 1L1 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L10 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  BigLock: (
    <svg width="47" height="60" viewBox="0 0 47 60" fill="none">
      <path
        d="M39.1853 23.0369V18.2261C39.1853 13.4153 38.0037 8.94202 35.2185 5.48159C32.5177 2.10557 28.1288 0.0799561 23.0648 0.0799561C18.0008 0.0799561 13.6119 2.18997 10.8267 5.6504C8.1259 9.11082 6.94429 13.4997 6.94429 18.3105V23.1213C3.14626 23.1213 0.0234375 26.2441 0.0234375 30.0422V52.9991C0.0234375 56.7972 3.14626 59.92 6.94429 59.92H39.1009C42.8989 59.92 46.0218 56.7972 46.0218 52.9991V29.9578C46.1062 26.1597 42.9833 23.0369 39.1853 23.0369ZM16.2284 9.95483C17.6632 8.09801 19.6888 7.0008 23.0648 7.0008C26.4408 7.0008 28.382 8.01361 29.9012 9.87043C31.3361 11.7272 32.2645 14.5969 32.2645 18.3105V23.1213H13.8651V18.3105C13.8651 14.5969 14.7935 11.7272 16.2284 9.95483ZM25.428 43.1243V48.3571C25.428 49.6231 24.4152 50.6359 23.1492 50.6359C21.8832 50.6359 20.8704 49.6231 20.8704 48.3571V43.1243C19.52 42.3647 18.5916 40.8454 18.5916 39.1574C18.5916 36.6254 20.6172 34.5998 23.1492 34.5998C25.6812 34.5998 27.7068 36.6254 27.7068 39.1574C27.7068 40.8454 26.7784 42.2803 25.428 43.1243Z"
        fill="#E3930F"
      />
    </svg>
  ),
};
