import React from "react";

export function handleGuardEvent(e: Event) {
  e.stopPropagation();
  e.preventDefault();
}

export function preventContextMenu(
  e: React.MouseEvent<HTMLVideoElement, MouseEvent>
) {
  e.preventDefault();
  e.stopPropagation();
  return false;
}
