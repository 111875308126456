import { handleActions } from "redux-actions";
import { IAccountActions } from "../../account/account-actions";
import {
  ERequestStatus,
  IPostRequest,
  IRequest,
  requestInit,
} from "../../utils/async";
import { IHeaderModalActions } from "./header-modal-actions";

export interface IHeaderModalModel {
  openHeaderMenuId: string;
  patchUpdateChatFavorite: IPostRequest<any, any>;
  patchUpdateChatRemoveFavorite: IPostRequest<any, any>;
  patchBlockUser: IPostRequest<any, any>;
  deleteBlockUser: IRequest<any>;
  patchBlockMedia: IPostRequest<any, any>;
  deleteBlockMedia: IRequest<any>;
  postChangeRate: IPostRequest<any, any>;
  patchReportUser: IPostRequest<any, any>;
  patchUpdateChatHide: IPostRequest<any, any>;
  patchUpdateChatDelete: IPostRequest<any, any>;
  deleteFeedItem: IRequest<any>;
  // cancelSubscription: IRequest<any>;
  removeCard: IRequest<any>;
}

const INITIAL_STATE: IHeaderModalModel = {
  openHeaderMenuId: "",
  patchUpdateChatFavorite: requestInit(),
  patchUpdateChatRemoveFavorite: requestInit(),
  patchBlockUser: requestInit(),
  deleteBlockUser: requestInit(),
  patchBlockMedia: requestInit(),
  deleteBlockMedia: requestInit(),
  postChangeRate: requestInit(),
  patchReportUser: requestInit(),
  patchUpdateChatHide: requestInit(),
  patchUpdateChatDelete: requestInit(),
  deleteFeedItem: requestInit(),
  // cancelSubscription: requestInit(),
  removeCard: requestInit(),
};

/** Reducer */
export const headerModalReducer = handleActions<
  IHeaderModalModel,
  IHeaderModalModel
>(
  {
    [IHeaderModalActions.Type.RESET_OPTIONS]: (state, action: any) => {
      return {
        ...INITIAL_STATE,
        openHeaderMenuId: state.openHeaderMenuId,
      };
    },
    [IHeaderModalActions.Type.SET_OPEN_HEADER_MENU]: (state, action: any) => {
      return {
        ...state,
        openHeaderMenuId: action.payload.id,
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_FAVORITE__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatFavorite: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_FAVORITE__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatFavorite: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_FAVORITE__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatFavorite: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_REMOVE_FAVORITE__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatRemoveFavorite: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_REMOVE_FAVORITE__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatRemoveFavorite: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_REMOVE_FAVORITE__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatRemoveFavorite: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__BLOCK_USER__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchBlockUser: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__BLOCK_USER__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchBlockUser: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__BLOCK_USER__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchBlockUser: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__BLOCK_USER__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        deleteBlockUser: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__BLOCK_USER__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        deleteBlockUser: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__BLOCK_USER__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        deleteBlockUser: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__BLOCK_MEDIA__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchBlockMedia: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__BLOCK_MEDIA__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchBlockMedia: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__BLOCK_MEDIA__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchBlockMedia: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__BLOCK_MEDIA__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        deleteBlockMedia: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__BLOCK_MEDIA__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        deleteBlockMedia: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__BLOCK_MEDIA__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        deleteBlockMedia: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.POST__CHANGE_RATE__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        postChangeRate: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.POST__CHANGE_RATE__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        postChangeRate: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.POST__CHANGE_RATE__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        postChangeRate: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__REPORT_USER__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchReportUser: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__REPORT_USER__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchReportUser: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__REPORT_USER__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchReportUser: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_HIDE__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatHide: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_HIDE__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatHide: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_HIDE__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatHide: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_DELETE__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatDelete: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_DELETE__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatDelete: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_DELETE__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        patchUpdateChatDelete: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__FEED_ITEM__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        deleteFeedItem: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__FEED_ITEM__SUCCESS]: (
      state,
      action: any
    ) => {
      const { res } = action.payload;
      return {
        ...state,
        deleteFeedItem: {
          responseData: res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IHeaderModalActions.Type.DELETE__FEED_ITEM__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        deleteFeedItem: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    // [IAccountActions.Type.DELETE__SUBSCRIPTION__INIT]: (state, action: any) => {
    //   return {
    //     ...state,
    //     cancelSubscription: {
    //       status: ERequestStatus.Fetching
    //     }
    //   };
    // },
    // [IAccountActions.Type.DELETE__SUBSCRIPTION__SUCCESS]: (state, action: any) => {
    //   return {
    //     ...state,
    //     cancelSubscription: {
    //       responseData: action.payload.res,
    //       status: ERequestStatus.Fetched
    //     }
    //   };
    // },
    // [IAccountActions.Type.DELETE__SUBSCRIPTION__FAILURE]: (state, action: any) => {
    //   return {
    //     ...state,
    //     cancelSubscription: {
    //       error: action.error,
    //       status: ERequestStatus.Error
    //     }
    //   };
    // },
    [IAccountActions.Type.DELETE__SAVED_CREDIT_CARD__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        removeCard: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.DELETE__SAVED_CREDIT_CARD__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        removeCard: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.DELETE__SAVED_CREDIT_CARD__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        removeCard: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
  },
  INITIAL_STATE
);
