import React from "react";
import styles from "./CryptoPaymentSuccessPopup.module.scss";

export namespace ICryptoPaymentSuccessPopup {
  export interface Props {
    credits: number;
    paidAmount: number;
    paidCurrency: string;
    fee: number;
  }
}

export const CryptoPaymentSuccessPopup = React.memo(
  function CryptoPaymentSuccessPopup(props: ICryptoPaymentSuccessPopup.Props) {
    return (
      <div className={styles.CryptoPaymentSuccessPopup}>
        <svg viewBox="0 0 52 52">
          <circle
            className={styles.circle}
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className={styles.check}
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
        <h2>Crypto top up successful!</h2>
        <p>
          <strong>
            {props.paidAmount} {props.paidCurrency}
          </strong>{" "}
          received and <strong>{props.credits} Credits</strong> have been added
          to your account
          {props.fee > 0 ? ` (fee ${props.fee} ${props.paidCurrency})` : ""}.
        </p>
      </div>
    );
  }
);
