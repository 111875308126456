import React from "react";
import { Button, EButtonType } from "../button";
import styles from "./ApplicationApprovedPopup.module.scss";

export namespace IApplicationApprovedPopup {
  export interface Props {
    onContinue: () => void;
  }
}

export const ApplicationApprovedPopup = React.memo(
  function ApplicationApprovedPopup(props: IApplicationApprovedPopup.Props) {
    return (
      <article className={styles.ApplicationApprovedPopup}>
        <header>
          <h1>ACCOUNT VERIFIED!</h1>
        </header>

        <div className={styles.iconWrapper}>
          <div style={{ fontSize: "90px" }}>🎉</div>
        </div>

        <section>
          <p>Congratulations! Your account has been verified.</p>
        </section>

        <Button
          type="button"
          buttonType={EButtonType.BlueWide}
          onClick={props.onContinue}
        >
          Continue
        </Button>
      </article>
    );
  }
);
