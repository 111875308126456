import { IBannerTop } from "../../../common/banner-top";
import { IDictionary } from "../../../models";

export enum EBannerUserFmType {
  GetMoreChats = "GetMoreChats",
  PenaltyProfileVisibility = "PenaltyProfileVisibility",
  BoostActive = "BoostActive",
  WarningInvisibleUploadPhoto = "WarningInvisibleUploadPhoto",
  WarningInvisibleFeaturedDisabled = "WarningInvisibleFeaturedDisabled",
  CompleteProfile = "CompleteProfile",
  StartFirstChat = "StartFirstChat",
  VerifyEmailAddress = "VerifyEmailAddress",
  VerifyEmailAddressUsingCode = "VerifyEmailAddressUsingCode",
}

export const BANNER_DIRECT_PROPS: IDictionary<IBannerTop.Props> = {
  [EBannerUserFmType.GetMoreChats]: {
    background: "#E3930F",
    title: "Get more chats now!",
    titleColor: "#FFF",
    sentence:
      "Boost your rank on the featured screen by promoting your Alua link on social media.",
    sentenceColor: "#FFF",
  },
  [EBannerUserFmType.PenaltyProfileVisibility]: {
    background: "#0094F4",
    title: "Boost your profiles visibility!",
    titleColor: "#FFF",
    sentence:
      "Use your Alua link to invite followers across your platforms. The more fans you invite the more exposure you’ll get!",
    sentenceColor: "rgba(255,255,255, 0.7)",
  },
  [EBannerUserFmType.BoostActive]: {
    background: "#2A2B46",
    title: "Profile BOOST Active!",
    titleColor: "#5EFAC4",
    sentence: "Reward for promoting",
    sentenceColor: "#FFF",
  },
  [EBannerUserFmType.WarningInvisibleUploadPhoto]: {
    background: "linear-gradient(to left, #EB3550 0%, #E46534 100%)",
    title: "WARNING - Your profile is invisible!",
    titleColor: "#FFF",
    sentence:
      "Upload at least one conservative photo to be shown on the Featured Screen!",
    sentenceColor: "#FFF",
    redirect: "/account/edit-profile",
  },
  [EBannerUserFmType.WarningInvisibleFeaturedDisabled]: {
    background: "linear-gradient(to left, #EB3550 0%, #E46534 100%)",
    title: "WARNING - Your profile is invisible!",
    titleColor: "#FFF",
    sentence:
      "You have disabled your profile from being featured. Tap here to turn it back on.",
    sentenceColor: "#FFF",
    redirect: "/account/settings/privacy",
  },
  [EBannerUserFmType.CompleteProfile]: {
    background: "#024A5B",
    title: "Complete Your Profile",
    titleColor: "#FFF",
    sentence: "Upload some photos to your profile!",
    sentenceColor: "#FFF",
  },
  [EBannerUserFmType.StartFirstChat]: {
    background: "#24BC5B",
    title: "Welcome to Alua! Tap your models profile below",
    titleColor: "#FFF",
    mainInlineStyle: {
      width: "calc(100% - 20px)",
      textAlign: "center",
    },
  },
  [EBannerUserFmType.VerifyEmailAddress]: {
    background: "#024A5B",
    title: "Verify Your Email Address",
    titleColor: "#FFF",
    sentence: "You need to verify email address to complete your profile.",
    sentenceColor: "#FFF",
  },
  [EBannerUserFmType.VerifyEmailAddressUsingCode]: {
    background: "#E3930F",
    title: "Verify your email to secure your Alua account",
    titleColor: "#FFF",
    mainInlineStyle: {
      width: "calc(100% - 20px)",
    },
  },
};
