import { handleActions } from "redux-actions";
import { IDictionary, IPopupConfig } from "../models";
import {
  addArrayToArrayIfNotThere,
  addToArrayIfNotThere,
  removeInArray,
} from "../utils/array";
import { IMessageAlertConfig } from "./pubnub-action-creators";
import { IPubnubActions } from "./pubnub-actions";

export interface IPubnubModel {
  messageAlert: IMessageAlertConfig | null;
  unreadMessages: number;
  unreadMessagesFavorites: number;
  usersTyping: string[];
  usersOnline: string[];
  usersLastOnline: IDictionary<string>;
  pubnubPopup: IPopupConfig | null;
}

const INITIAL_STATE: IPubnubModel = {
  messageAlert: null,
  unreadMessages: 0,
  unreadMessagesFavorites: 0,
  usersTyping: [],
  usersOnline: [],
  usersLastOnline: {},
  pubnubPopup: null,
};

/** Reducer */
export const pubnubReducer = handleActions<IPubnubModel, IPubnubModel>(
  {
    [IPubnubActions.Type.ADD_TYPING_USER]: (state, action: any) => {
      return {
        ...state,
        usersTyping: addToArrayIfNotThere(state.usersTyping, action.payload),
      };
    },
    [IPubnubActions.Type.REMOVE_TYPING_USER]: (state, action: any) => {
      return {
        ...state,
        usersTyping: removeInArray(state.usersTyping, action.payload),
      };
    },
    [IPubnubActions.Type.SET_UNREAD_MESSAGES]: (state, action: any) => {
      return {
        ...state,
        unreadMessages: action.payload.unread_messages,
      };
    },
    [IPubnubActions.Type.SET_UNREAD_MESSAGES_FAVORITES]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        unreadMessagesFavorites: action.payload.unread_messages,
      };
    },
    [IPubnubActions.Type.ADD_ONLINE_USER]: (state, action: any) => {
      return {
        ...state,
        usersOnline: addToArrayIfNotThere(state.usersOnline, action.payload),
        usersLastOnline: {
          ...state.usersLastOnline,
          [action.payload]: new Date().toJSON(),
        },
      };
    },
    [IPubnubActions.Type.ADD_ONLINE_USERS]: (state, action: any) => {
      const updatedUsersLastOnline = { ...state.usersLastOnline };
      action.payload.forEach((userId: string) => {
        updatedUsersLastOnline[userId] = new Date().toJSON();
      });
      return {
        ...state,
        usersOnline: addArrayToArrayIfNotThere(
          state.usersOnline,
          action.payload
        ),
        usersLastOnline: updatedUsersLastOnline,
      };
    },
    [IPubnubActions.Type.REMOVE_ONLINE_USER]: (state, action: any) => {
      return {
        ...state,
        usersOnline: removeInArray(state.usersOnline, action.payload.userId),
        usersLastOnline: {
          ...state.usersLastOnline,
          [action.payload.userId]: action.payload.lastOnline,
        },
      };
    },
    [IPubnubActions.Type.SET_PUBNUB_POPUP]: (state, action: any) => {
      return {
        ...state,
        pubnubPopup: action.payload,
      };
    },
    [IPubnubActions.Type.SET_MESSAGE_ALERT]: (state, action: any) => {
      return {
        ...state,
        messageAlert: action.payload.messageAlertConfig,
      };
    },
  },
  INITIAL_STATE
);
