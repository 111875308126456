import { createAction } from "redux-actions";

export namespace IModelProfileActions {
  export enum Type {
    GET__PROFILE_USER__INIT = "GET__PROFILE_USER__INIT",
    GET__PROFILE_USER__SUCCESS = "GET__PROFILE_USER__SUCCESS",
    GET__PROFILE_USER__FAILURE = "GET__PROFILE_USER__FAILURE",
    GET__PROFILE_USER__RESET = "GET__PROFILE_USER__RESET",

    GET__PROFILE_FEED__INIT = "GET__PROFILE_FEED__INIT",
    GET__PROFILE_FEED__SUCCESS = "GET__PROFILE_FEED__SUCCESS",
    GET__PROFILE_FEED__FAILURE = "GET__PROFILE_FEED__FAILURE",
    GET__PROFILE_FEED_PHOTOS__INIT = "GET__PROFILE_FEED_PHOTOS__INIT",
    GET__PROFILE_FEED_PHOTOS__SUCCESS = "GET__PROFILE_FEED_PHOTOS__SUCCESS",
    GET__PROFILE_FEED_PHOTOS__FAILURE = "GET__PROFILE_FEED_PHOTOS__FAILURE",
    GET__PROFILE_FEED_VIDEOS__INIT = "GET__PROFILE_FEED_VIDEOS__INIT",
    GET__PROFILE_FEED_VIDEOS__SUCCESS = "GET__PROFILE_FEED_VIDEOS__SUCCESS",
    GET__PROFILE_FEED_VIDEOS__FAILURE = "GET__PROFILE_FEED_VIDEOS__FAILURE",
    GET__PROFILE_FEED__RESET = "GET__PROFILE_FEED__RESET",

    GET__PROFILE_CHAT__INIT = "GET__PROFILE_CHAT__INIT",
    GET__PROFILE_CHAT__SUCCESS = "GET__PROFILE_CHAT__SUCCESS",
    GET__PROFILE_CHAT__FAILURE = "GET__PROFILE_CHAT__FAILURE",
    GET__PROFILE_CHAT__RESET = "GET__PROFILE_CHAT__RESET",

    PATCH__FOLLOW__INIT = "PATCH__FOLLOW__INIT",
    PATCH__FOLLOW__SUCCESS = "PATCH__FOLLOW__SUCCESS",
    PATCH__FOLLOW__FAILURE = "PATCH__FOLLOW__FAILURE",

    DELETE__FOLLOW__INIT = "DELETE__FOLLOW__INIT",
    DELETE__FOLLOW__SUCCESS = "DELETE__FOLLOW__SUCCESS",
    DELETE__FOLLOW__FAILURE = "DELETE__FOLLOW__FAILURE",

    SET_MODEL_PROFILE_POPUP = "SET_MODEL_PROFILE_POPUP",
  }

  export const initGetProfileUser = createAction(Type.GET__PROFILE_USER__INIT);
  export const successGetProfileUser = createAction<any>(
    Type.GET__PROFILE_USER__SUCCESS
  );
  export const failureGetProfileUser = createAction<any>(
    Type.GET__PROFILE_USER__FAILURE
  );
  export const resetGetProfileUser = createAction(
    Type.GET__PROFILE_USER__RESET
  );

  export const initGetProfileFeed = createAction<any>(
    Type.GET__PROFILE_FEED__INIT
  );
  export const successGetProfileFeed = createAction<any>(
    Type.GET__PROFILE_FEED__SUCCESS
  );
  export const failureGetProfileFeed = createAction<any>(
    Type.GET__PROFILE_FEED__FAILURE
  );
  export const initGetProfileFeedPhotos = createAction<any>(
    Type.GET__PROFILE_FEED_PHOTOS__INIT
  );
  export const successGetProfileFeedPhotos = createAction<any>(
    Type.GET__PROFILE_FEED_PHOTOS__SUCCESS
  );
  export const failureGetProfileFeedPhotos = createAction<any>(
    Type.GET__PROFILE_FEED_PHOTOS__FAILURE
  );
  export const initGetProfileFeedVideos = createAction<any>(
    Type.GET__PROFILE_FEED_VIDEOS__INIT
  );
  export const successGetProfileFeedVideos = createAction<any>(
    Type.GET__PROFILE_FEED_VIDEOS__SUCCESS
  );
  export const failureGetProfileFeedVideos = createAction<any>(
    Type.GET__PROFILE_FEED_VIDEOS__FAILURE
  );
  export const resetGetProfileFeed = createAction(
    Type.GET__PROFILE_FEED__RESET
  );

  export const initGetProfileChat = createAction(Type.GET__PROFILE_CHAT__INIT);
  export const successGetProfileChat = createAction<any>(
    Type.GET__PROFILE_CHAT__SUCCESS
  );
  export const failureGetProfileChat = createAction<any>(
    Type.GET__PROFILE_CHAT__FAILURE
  );
  export const resetGetProfileChat = createAction(
    Type.GET__PROFILE_CHAT__RESET
  );

  export const initDeleteFollow = createAction(Type.DELETE__FOLLOW__INIT);
  export const successDeleteFollow = createAction<any>(
    Type.DELETE__FOLLOW__SUCCESS
  );
  export const failureDeleteFollow = createAction<any>(
    Type.DELETE__FOLLOW__FAILURE
  );

  export const initPatchFollow = createAction(Type.PATCH__FOLLOW__INIT);
  export const successPatchFollow = createAction<any>(
    Type.PATCH__FOLLOW__SUCCESS
  );
  export const failurePatchFollow = createAction<any>(
    Type.PATCH__FOLLOW__FAILURE
  );

  export const setModelProfilePopup = createAction<any>(
    Type.SET_MODEL_PROFILE_POPUP
  );
}

export type ModelProfileActions = Omit<typeof IModelProfileActions, "Type">;
