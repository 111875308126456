import { EBannerUserFmType } from "../discover/discover-banners/banner-types";
import { AUTH_TOKEN_NAME, URL_LOGIN } from "../globals";
import { deleteSubscription } from "../pwa";
import { deleteCookie } from "../utils/cookie";
import { getLocalStorageValue } from "../utils/localStorage";
import { defined } from "../utils/variable-evaluation";
import {
  EDiscoverStatus,
  EFeature,
  EIdVerificationStatus,
  EUserType,
  IProviderConfig,
  IUserInfo,
} from "./account-models";

export function isModelGovernmentIdApproved(otherUser: IUserInfo | null) {
  if (!otherUser) {
    return false;
  }
  return otherUser.type === EUserType.Featured && otherUser.id_verified;
}

export function isFeature(
  feature: EFeature,
  user: IUserInfo | null,
  providerConfig: IProviderConfig | null
): boolean {
  if (!defined(user)) {
    return false;
  }
  if (!defined(providerConfig) || !defined(providerConfig!.features)) {
    return false;
  }
  return !!providerConfig!.features![feature];
}

export function getIsNewUserGreeting(
  user: IUserInfo,
  discoverProfiles: IUserInfo[]
): boolean {
  if (!defined(user) || !defined(discoverProfiles)) {
    return false;
  }
  return (
    getLocalStorageValue("wasGreeted") !== "true" &&
    !user.__wasGreeted && // custom property
    user.type === EUserType.User &&
    !!user.onboarding_v2 &&
    user.code_used !== "tiger1" &&
    !user.profile_complete_config.is_affiliate_profile_visited &&
    !user.profile_complete_config.is_bought_credit_package &&
    defined(discoverProfiles[0]) && // discover is loaded
    discoverProfiles[0]._id === user.affiliate // first discover item is affiliate
  );
}

export function getIsChatAccessLock(user: IUserInfo): boolean {
  if (!defined(user)) {
    return false;
  }
  return (
    user.type === EUserType.User &&
    !!user.onboarding_v2 &&
    !user.email_verified &&
    !user.profile_complete_config.is_bought_credit_package
  );
}

export function getIsEarningsAccessLock(user: IUserInfo): boolean {
  if (!defined(user)) {
    return false;
  }
  return (
    user.type === EUserType.Featured &&
    ((user.requires_moderation &&
      (!user.id_verification_status ||
        user.id_verification_status === EIdVerificationStatus.Pending)) ||
      user.discover_status === EDiscoverStatus.Denied)
  );
}

export function getIsShowFeedCaption(
  user: IUserInfo,
  otherUser: IUserInfo
): boolean {
  if (!defined(user)) {
    // defaults to false
    return false;
  }

  // for models show only for their own profile
  if (user.type === EUserType.Featured) {
    if (!defined(otherUser)) {
      return true;
    }
    return user._id === otherUser._id;
  }

  // for users
  return (
    user.code_used !== "tiger1" ||
    !!user.profile_complete_config.is_bought_credit_package
  );
}

export function getIsShowDiscover(user: IUserInfo): boolean {
  return (
    user.type === EUserType.User ||
    user.discover_status === EDiscoverStatus.Featured ||
    !!user.force_discover
  );
}

export function purgeUserData(
  redirectToLogin = true,
  customRedirectUrl?: string
): void {
  // remove user related stuff in browser storage
  Object.keys(EBannerUserFmType).forEach((key) => deleteCookie(key));
  // deleteInBrowserStorage(IS_BANNED_NAME);
  deleteCookie(AUTH_TOKEN_NAME);

  // inform service worker about logout
  deleteSubscription();

  // redirect to login
  if (redirectToLogin) {
    // delay the execution to let other cleaning activities finish first
    setTimeout(() => {
      window.location.href = URL_LOGIN;
    }, 200);
  } else if (customRedirectUrl) {
    // delay the execution to let other cleaning activities finish first
    setTimeout(() => {
      window.location.href = customRedirectUrl;
    }, 200);
  }
}

export function allowForPercentageOfUsers(percentage: number, userId: string) {
  const lastTwoCharsOfUserId = userId.substr(-2);
  const val = parseInt(lastTwoCharsOfUserId, 16);
  const max = 255;
  return percentage > 0 && percentage >= val / (max / 100);
}
