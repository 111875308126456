import { createAction } from "redux-actions";

export namespace IEarningsActions {
  export enum Type {
    GET__PAYOUT_TOTALS__INIT = "GET__PAYOUT_TOTALS__INIT",
    GET__PAYOUT_TOTALS__SUCCESS = "GET__PAYOUT_TOTALS__SUCCESS",
    GET__PAYOUT_TOTALS__FAILURE = "GET__PAYOUT_TOTALS__FAILURE",

    GET__PAYOUT_STATS__INIT = "GET__PAYOUT_STATS__INIT",
    GET__PAYOUT_STATS__SUCCESS = "GET__PAYOUT_STATS__SUCCESS",
    GET__PAYOUT_STATS__FAILURE = "GET__PAYOUT_STATS__FAILURE",

    GET__PAYOUTS__INIT = "GET__PAYOUTS__INIT",
    GET__PAYOUTS__SUCCESS = "GET__PAYOUTS__SUCCESS",
    GET__PAYOUTS__FAILURE = "GET__PAYOUTS__FAILURE",

    GET__ACCOUNT_INFO__INIT = "GET__ACCOUNT_INFO__INIT",
    GET__ACCOUNT_INFO__SUCCESS = "GET__ACCOUNT_INFO__SUCCESS",
    GET__ACCOUNT_INFO__FAILURE = "GET__ACCOUNT_INFO__FAILURE",

    PATCH__PAYOUT_METHOD__INIT = "PATCH__PAYOUT_METHOD__INIT",
    PATCH__PAYOUT_METHOD__SUCCESS = "PATCH__PAYOUT_METHOD__SUCCESS",
    PATCH__PAYOUT_METHOD__FAILURE = "PATCH__PAYOUT_METHOD__FAILURE",

    POST__PAYOUT_REQUEST__INIT = "POST__PAYOUT_REQUEST__INIT",
    POST__PAYOUT_REQUEST__SUCCESS = "POST__PAYOUT_REQUEST__SUCCESS",
    POST__PAYOUT_REQUEST__FAILURE = "POST__PAYOUT_REQUEST__FAILURE",

    POST__ANTI_MONEY_LAUNDERING__INIT = "POST__ANTI_MONEY_LAUNDERING__INIT",
    POST__ANTI_MONEY_LAUNDERING__SUCCESS = "POST__ANTI_MONEY_LAUNDERING__SUCCESS",
    POST__ANTI_MONEY_LAUNDERING__FAILURE = "POST__ANTI_MONEY_LAUNDERING__FAILURE",

    GET__SUBSCRIBERS__INIT = "GET__SUBSCRIBERS__INIT",
    GET__SUBSCRIBERS__SUCCESS = "GET__SUBSCRIBERS__SUCCESS",
    GET__SUBSCRIBERS__FAILURE = "GET__SUBSCRIBERS__FAILURE",

    GET__REFERRERS__INIT = "GET__REFERRERS__INIT",
    GET__REFERRERS__SUCCESS = "GET__REFERRERS__SUCCESS",
    GET__REFERRERS__FAILURE = "GET__REFERRERS__FAILURE",

    GET__BROADCAST_STATS__INIT = "GET__BROADCAST_STATS__INIT",
    GET__BROADCAST_STATS__SUCCESS = "GET__BROADCAST_STATS__SUCCESS",
    GET__BROADCAST_STATS__FAILURE = "GET__BROADCAST_STATS__FAILURE",

    // GET__PAYOUT_BANKS__INIT = 'GET__PAYOUT_BANKS__INIT',
    // GET__PAYOUT_BANKS__SUCCESS = 'GET__PAYOUT_BANKS__SUCCESS',
    // GET__PAYOUT_BANKS__FAILURE = 'GET__PAYOUT_BANKS__FAILURE',

    // POST__PAYOUT_BANK__INIT = 'POST__PAYOUT_BANK__INIT',
    // POST__PAYOUT_BANK__SUCCESS = 'POST__PAYOUT_BANK__SUCCESS',
    // POST__PAYOUT_BANK__FAILURE = 'POST__PAYOUT_BANK__FAILURE',

    // PUT__PAYOUT_BANK__INIT = 'PUT__PAYOUT_BANK__INIT',
    // PUT__PAYOUT_BANK__SUCCESS = 'PUT__PAYOUT_BANK__SUCCESS',
    // PUT__PAYOUT_BANK__FAILURE = 'PUT__PAYOUT_BANK__FAILURE',

    // DELETE__PAYOUT_BANK__INIT = 'DELETE__PAYOUT_BANK__INIT',
    // DELETE__PAYOUT_BANK__SUCCESS = 'DELETE__PAYOUT_BANK__SUCCESS',
    // DELETE__PAYOUT_BANK__FAILURE = 'DELETE__PAYOUT_BANK__FAILURE'
  }
  export const initGetPayoutTotals = createAction(
    Type.GET__PAYOUT_TOTALS__INIT
  );
  export const successGetPayoutTotals = createAction<any>(
    Type.GET__PAYOUT_TOTALS__SUCCESS
  );
  export const failureGetPayoutTotals = createAction<any>(
    Type.GET__PAYOUT_TOTALS__FAILURE
  );

  export const initGetPayoutStats = createAction(Type.GET__PAYOUT_STATS__INIT);
  export const successGetPayoutStats = createAction<any>(
    Type.GET__PAYOUT_STATS__SUCCESS
  );
  export const failureGetPayoutStats = createAction<any>(
    Type.GET__PAYOUT_STATS__FAILURE
  );

  export const initGetPayouts = createAction(Type.GET__PAYOUTS__INIT);
  export const successGetPayouts = createAction<any>(
    Type.GET__PAYOUTS__SUCCESS
  );
  export const failureGetPayouts = createAction<any>(
    Type.GET__PAYOUTS__FAILURE
  );

  export const initGetAccountInfo = createAction(Type.GET__ACCOUNT_INFO__INIT);
  export const successGetAccountInfo = createAction<any>(
    Type.GET__ACCOUNT_INFO__SUCCESS
  );
  export const failureGetAccountInfo = createAction<any>(
    Type.GET__ACCOUNT_INFO__FAILURE
  );

  export const initPatchPayoutMethod = createAction(
    Type.PATCH__PAYOUT_METHOD__INIT
  );
  export const successPatchPayoutMethod = createAction<any>(
    Type.PATCH__PAYOUT_METHOD__SUCCESS
  );
  export const failurePatchPayoutMethod = createAction<any>(
    Type.PATCH__PAYOUT_METHOD__FAILURE
  );

  export const initPostPayoutRequest = createAction(
    Type.POST__PAYOUT_REQUEST__INIT
  );
  export const successPostPayoutRequest = createAction<any>(
    Type.POST__PAYOUT_REQUEST__SUCCESS
  );
  export const failurePostPayoutRequest = createAction<any>(
    Type.POST__PAYOUT_REQUEST__FAILURE
  );

  export const initPostAntiMoneyLaundering = createAction(
    Type.POST__ANTI_MONEY_LAUNDERING__INIT
  );
  export const successPostAntiMoneyLaundering = createAction<any>(
    Type.POST__ANTI_MONEY_LAUNDERING__SUCCESS
  );
  export const failurePostAntiMoneyLaundering = createAction<any>(
    Type.POST__ANTI_MONEY_LAUNDERING__FAILURE
  );

  export const initGetSubscribers = createAction(Type.GET__SUBSCRIBERS__INIT);
  export const successGetSubscribers = createAction<any>(
    Type.GET__SUBSCRIBERS__SUCCESS
  );
  export const failureGetSubscribers = createAction<any>(
    Type.GET__SUBSCRIBERS__FAILURE
  );

  export const initGetReferrers = createAction(Type.GET__REFERRERS__INIT);
  export const successGetReferrers = createAction<any>(
    Type.GET__REFERRERS__SUCCESS
  );
  export const failureGetReferrers = createAction<any>(
    Type.GET__REFERRERS__FAILURE
  );

  export const initGetBroadcastStats = createAction(
    Type.GET__BROADCAST_STATS__INIT
  );
  export const successGetBroadcastStats = createAction<any>(
    Type.GET__BROADCAST_STATS__SUCCESS
  );
  export const failureGetBroadcastStats = createAction<any>(
    Type.GET__BROADCAST_STATS__FAILURE
  );

  // export const initGetPayoutBanks = createAction(Type.GET__PAYOUT_BANKS__INIT);
  // export const successGetPayoutBanks = createAction<any>(Type.GET__PAYOUT_BANKS__SUCCESS);
  // export const failureGetPayoutBanks = createAction<any>(Type.GET__PAYOUT_BANKS__FAILURE);

  // export const initPostPayoutBank = createAction(Type.POST__PAYOUT_BANK__INIT);
  // export const successPostPayoutBank = createAction<any>(Type.POST__PAYOUT_BANK__SUCCESS);
  // export const failurePostPayoutBank = createAction<any>(Type.POST__PAYOUT_BANK__FAILURE);

  // export const initPutPayoutBank = createAction(Type.PUT__PAYOUT_BANK__INIT);
  // export const successPutPayoutBank = createAction<any>(Type.PUT__PAYOUT_BANK__SUCCESS);
  // export const failurePutPayoutBank = createAction<any>(Type.PUT__PAYOUT_BANK__FAILURE);

  // export const initDeletePayoutBank = createAction(Type.DELETE__PAYOUT_BANK__INIT);
  // export const successDeletePayoutBank = createAction<any>(Type.DELETE__PAYOUT_BANK__SUCCESS);
  // export const failureDeletePayoutBank = createAction<any>(Type.DELETE__PAYOUT_BANK__FAILURE);
}

export type EarningsActions = Omit<typeof IEarningsActions, "Type">;
