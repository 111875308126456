export function promisifyXhr(
  xhr: XMLHttpRequest,
  onProgress?: (progress: number, loaded: number, total: number) => any
) {
  return new Promise((resolve, reject) => {
    if (onProgress) {
      xhr.upload.onprogress = (event: ProgressEvent) => {
        const progress = Math.round((event.loaded * 100.0) / event.total);
        onProgress(progress, event.loaded, event.total);
      };
    }
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({
          status: xhr.status,
          response: JSON.parse(xhr.responseText || "{}"),
          isAbort: false,
        });
      } else {
        reject({
          status: xhr.status,
          response: JSON.parse(xhr.responseText || "{}"),
          isAbort: false,
        });
      }
    };
    xhr.onerror = () => {
      reject({
        status: xhr.status,
        response: JSON.parse(xhr.responseText || "{}"),
        isAbort: false,
      });
    };
    xhr.onabort = () => {
      reject({
        status: xhr.status,
        response: JSON.parse(xhr.responseText || "{}"),
        isAbort: true,
      });
    };
  });
}
