import { handleActions } from "redux-actions";
import { IWebsocketActions } from "./websocket-actions";

export interface IWebsocketModel {
  isConnected: boolean;
}

const INITIAL_STATE: IWebsocketModel = {
  isConnected: true, // we start as true here to prevent from showing the "offline" message on the first load
};

/** Reducer */
export const websocketReducer = handleActions<IWebsocketModel, IWebsocketModel>(
  {
    [IWebsocketActions.Type.SET__IS_CONNECTED]: (state): IWebsocketModel => {
      return {
        ...state,
        isConnected: true,
      };
    },
    [IWebsocketActions.Type.SET__IS_DISCONNECTED]: (state): IWebsocketModel => {
      return {
        ...state,
        isConnected: false,
      };
    },
  },
  INITIAL_STATE
);
