export const exists = (x: any) => Boolean(x);

export const defined = (x: any) => typeof x !== "undefined" && x !== null;

export const isString = (x: any) => typeof x === "string";

export const isNumber = (x: any) => typeof x === "number";

export const isArray = (x: any) => defined(x) && x.constructor === Array;

export const isObject = (x: any) => defined(x) && x.constructor === Object;
