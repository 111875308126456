export const ICONS_BUTTON = {
  PlusCircle: (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
      <circle cx="13.8993" cy="13.8613" r="13.1511" stroke="currentColor" />
      <line
        x1="13.8528"
        y1="8.35449"
        x2="13.8528"
        y2="19.3675"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="8.39246"
        y1="13.9073"
        x2="19.4055"
        y2="13.9073"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  ),
  Check: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-check"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  ),
  CheckFull: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8" r="8" fill="#0CB549" />
      <rect
        x="12.2474"
        y="4.24749"
        width="1.39513"
        height="9.30084"
        rx="0.697563"
        transform="rotate(45 12.2474 4.24749)"
        fill="white"
      />
      <rect
        x="3.31433"
        y="8.58355"
        width="1.39513"
        height="4.65042"
        rx="0.697563"
        transform="rotate(-45 3.31433 8.58355)"
        fill="white"
      />
    </svg>
  ),
  CrossCircle: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-x-circle"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="15" y1="9" x2="9" y2="15" />
      <line x1="9" y1="9" x2="15" y2="15" />
    </svg>
  ),
  ArrowDown: (
    <svg width="8" height="4" viewBox="0 0 8 4" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0L4 4L0 0"
        fill="#8C8C8C"
      />
    </svg>
  ),
  GrowingHamburger: (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none">
      <rect width="44" height="44" rx="3" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.0436C12 15.4672 12.4672 15 13.0436 15H31.8289C32.4053 15 32.8726 15.4672 32.8726 16.0436C32.8726 16.62 32.4053 17.0873 31.8289 17.0873H13.0436C12.4672 17.0873 12 16.62 12 16.0436Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.3054C12 21.729 12.4672 21.2618 13.0436 21.2618H27.6544C28.2308 21.2618 28.6981 21.729 28.6981 22.3054C28.6981 22.8818 28.2308 23.349 27.6544 23.349H13.0436C12.4672 23.349 12 22.8818 12 22.3054Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 28.5672C12 27.9908 12.4672 27.5235 13.0436 27.5235H23.4799C24.0563 27.5235 24.5235 27.9908 24.5235 28.5672C24.5235 29.1436 24.0563 29.6108 23.4799 29.6108H13.0436C12.4672 29.6108 12 29.1436 12 28.5672Z"
        fill="black"
      />
    </svg>
  ),
  GrowingHamburgerActive: (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none">
      <rect width="44" height="44" rx="3" fill="#0094F4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.0436C12 15.4672 12.4672 15 13.0436 15H31.8289C32.4053 15 32.8726 15.4672 32.8726 16.0436C32.8726 16.62 32.4053 17.0873 31.8289 17.0873H13.0436C12.4672 17.0873 12 16.62 12 16.0436Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.3054C12 21.729 12.4672 21.2618 13.0436 21.2618H27.6544C28.2308 21.2618 28.6981 21.729 28.6981 22.3054C28.6981 22.8818 28.2308 23.349 27.6544 23.349H13.0436C12.4672 23.349 12 22.8818 12 22.3054Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 28.5672C12 27.9908 12.4672 27.5236 13.0436 27.5236H23.4799C24.0563 27.5236 24.5235 27.9908 24.5235 28.5672C24.5235 29.1436 24.0563 29.6108 23.4799 29.6108H13.0436C12.4672 29.6108 12 29.1436 12 28.5672Z"
        fill="white"
      />
    </svg>
  ),
  OK: (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
      <g>
        <path
          id="Vector"
          d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M60.1904 22.0957L34.2856 48.0005L23.619 37.3338L18.2856 42.6671L34.2856 58.6671L65.5237 27.429L60.1904 22.0957Z"
          fill="white"
        />
      </g>
    </svg>
  ),
  Error: (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9C18 4.05 13.95 0 9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9ZM8 12V14H10V12H8ZM8 4V10H10V4H8Z"
        fill="#EB5757"
      />
    </svg>
  ),
};
