import { AccountActions } from "../../account/account-actions";
import { IVaultMedia } from "../../account/account-models";
import { IFileWrapper } from "../../common/uploader/uploader-helpers";
import { IMediaType } from "../../models";
import { ChatsActions } from "../chats-actions";
import { MessengerActions } from "./messenger-actions";

export const CHAT_CLOSED_ERROR_MESSAGE = "Chat was closed";
export const CHAT_BLOCKED_ERROR_MESSAGE =
  "Sorry, this user has ended the chat.";
export const CHAT_BLOCKED_COUNTRY_ERROR_MESSAGE =
  "Sorry this creator not available in your country";
export const CHAT_ACCOUNT_NO_LONGER_EXISTS_ERROR_MESSAGE =
  "Sorry, this account no longer exists.";
export const CHAT_FROZEN_ERROR_MESSAGE_USER =
  "Your account has been temporarily frozen. Please respond to your chat with alua support.";
export const CHAT_FROZEN_ERROR_MESSAGE_MODEL =
  "Your account has been temporarily frozen. Please respond to your chat with alua support or your account manager.";
export const NO_CREDITS_ERROR_MESSAGE = "You have no credits."; // special handling for corner cases
export const MESSAGE_ALREADY_PURCHASED_ERROR = "Message was already purchased.";
export const BALANCE_TOO_LOW_ERROR = "Balance too low.";
export const SENDING_MEDIA_BLOCKED = "Sending media blocked.";
export const CHAT_MESSAGE_INVALID_JSON_UNKNOWN_JSON_ENCODING_DECODING_ERROR =
  "Invalid JSON data in request body: Unknown JSON encoding/decoding error.";
export const CHAT_MESSAGE_INVALID_JSON_SINGLE_UNPAIRED_UTF16_SURROGATE_ERROR =
  "Invalid JSON data in request body: Single unpaired UTF-16 surrogate in unicode escape";

export enum EMessageType {
  // --- normal ---
  Text = "text",
  Image = "image",
  Video = "video",
  Gif = "gif",
  TipUser = "tip_user",
  TipModel = "tip_model",
  // --- system ---
  // ChatStarted = 'chat_started', // deprecated
  // ChatEnded = 'chat_ended', // deprecated
  // CreditRateUpdated = 'credit_rate_updated', // not used?
  // ScreenshotTaken = 'screenshot_taken', // do we need this on web?
  IntroEnded = "intro_ended", // for user
  IntroEndedModel = "intro_ended_model",
  ForModel = "for_model", // new paid chat with fan
  ForBothModels = "for_both_models", // new chat with both models, also for both users
  ForModelIntro = "for_model_intro", // new paid chat with not a fan
  ForModelRateChanged = "for_model_rate_changed",
  ForModelSubscription = "for_model_subscription",
  ForUser = "for_user", // new paid chat with model as fan
  ForUserRateChanged = "for_user_rate_changed",
  ForUserIntro = "for_user_intro", // new paid chat with model as not a fan
  ForUserSubscription = "for_user_subscription",
  // PrivateFeedBanner = 'private_feed_banner', // not used?
  // --- client side ---
  Typing = "typing",
}

export interface IMessageInfo {
  _id?: string;
  chat_id: string;
  sender_id: string;
  recipient_id: string;
  is_paid?: boolean;
  is_paid_by_credit?: boolean;
  type?: EMessageType;
  text?: string;
  tip?: number;
  tipMedia?: IMediaType; // TODO: confirm from backend
  credits?: number; // earned or payed
  price?: number; // price to unlock
  price_in_dollars?: boolean; // price in usd instead of Credits
  created_at?: string;
  is_read?: boolean;
  read_at?: string;
  is_system?: boolean;
  self_destruct?: boolean;
  destruct_at?: string; // ?
  image?: IMediaType;
  video?: IMediaType;
  video_length?: number;
  new_rate?: number; // https://trello.com/c/V2oOylcG/1-show-users-cr-rate-on-system-messages
  // client side properties
  isRevealed?: boolean;
  error?: any;
  clientSideReceiveId?: string;
  __clientSideSendId?: string;
  mediaFileWrapper?: IFileWrapper;
  vaultMedia?: IVaultMedia;
  uploadProgress?: number;
  loaded?: number;
  total?: number;
  abortUpload?: () => void;
  md5?: string;
  media_id?: string;
  gif?: {
    gif_url: string;
    height: number;
    width: number;
  };
}

export interface IGetFromLastIdMessagesConfig {
  chat_id: string;
  // recipient_id: string;
  // sender_id: string;
  last_message_id: string;
  // clientSideReceiveId?: string; // timestamp in milliseconds
  unread_messages: number;
  actions: MessengerActions;
  accountActions: AccountActions;
  chatsActions: ChatsActions;
}

export interface IUpdateMessageAsPaidConfig {
  messageId: string;
  destructAt: string;
}

export interface IDestructibleMessage {
  message_id: string;
  destruct_at: string;
}

export interface IUpdateMessagesReadConfig {
  messageIds: string[];
  chatId: string;
  recipientId: string;
  destructibles: IDestructibleMessage[];
  timestampString: string;
}
