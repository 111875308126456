import { IS_PROD } from "../globals";

const SLACK_HOOK_URL = process.env.REACT_APP_SLACK_HOOK_URL!;

// curl -X POST 'https://hooks.slack.com/services/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' \
//   -H 'Content-type: application/json' \
//   --data '{"channel": "web-logs", "username": "github-action", "icon_emoji": ":octocat:", "text":"Allow me to reintroduce myself!"}'

export function sendWarningMessage(
  text: string,
  channel?: string,
  botname?: string,
  iconEmoji?: string
): void {
  fetch(SLACK_HOOK_URL, {
    method: "POST",
    body: JSON.stringify({
      channel: channel || "#web-logs",
      username: `${botname || "webhookbot"}[${process.env
        .REACT_APP_ENVIRONMENT!}] (${process.env.REACT_APP_GIT_BRANCH}@${
        process.env.REACT_APP_GIT_SHA1
      })`,
      text,
      icon_emoji: iconEmoji || ":warning:",
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    credentials: "same-origin",
  });
}

export function sendAPIErrorMessage(
  text: string,
  channel?: string,
  botname?: string,
  iconEmoji?: string
): void {
  fetch(SLACK_HOOK_URL, {
    method: "POST",
    body: JSON.stringify({
      channel: channel || `#web-logs-api-${IS_PROD ? "prod" : "dev"}`,
      username: `${botname || "inspector"}[${process.env
        .REACT_APP_ENVIRONMENT!}] (${process.env.REACT_APP_GIT_BRANCH}@${
        process.env.REACT_APP_GIT_SHA1
      })`,
      text,
      icon_emoji: iconEmoji || ":male-detective:",
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    credentials: "same-origin",
  });
}
