import React from "react";
import { PopupEmoj } from "../../common/popups/common/popup-emoj";
import { IPopupWrapper } from "../../common/popups/common/popup-wrapper";
import { defined } from "../../utils/variable-evaluation";

export namespace IBasicEmptyPopup {
  export interface Props extends IPopupWrapper.Props {
    title?: string;
    emoj?: string;
    image?: string;
    sentence?: string;
    inlineStyle?: React.CSSProperties;
    children?: React.ReactNode;
  }
}

export const BasicEmptyPopup = React.memo(function BasicEmptyPopup(
  props: IBasicEmptyPopup.Props
) {
  return (
    <PopupEmoj
      title={props.title}
      emoj={props.emoj}
      image={props.image}
      sentence={props.sentence}
      inlineStyle={props.inlineStyle}
    >
      <div
        style={{
          marginTop: !defined(props.emoj) && !defined(props.image) ? -16 : "",
        }}
      >
        {props.children}
      </div>
    </PopupEmoj>
  );
});
