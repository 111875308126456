import React from "react";
import { PopupBackground } from "../popup-background";
import { PopupContent } from "../popup-content";

export namespace IPopupEmoj {
  export interface Props {
    title?: React.ReactNode;
    emoj?: string;
    image?: string;
    sentence?: string;
    inlineStyle?: React.CSSProperties;
    children?: React.ReactNode;
  }
}

export const PopupEmoj = React.memo(function PopupEmoj(
  props: IPopupEmoj.Props
) {
  return (
    <PopupBackground title={props.title} inlineStyle={props.inlineStyle}>
      <PopupContent {...props}>{props.children}</PopupContent>
    </PopupBackground>
  );
});
