import differenceInDays from "date-fns/difference_in_days";
import differenceInHours from "date-fns/difference_in_hours";
import differenceInMinutes from "date-fns/difference_in_minutes";
import differenceInMonths from "date-fns/difference_in_months";
import differenceInSeconds from "date-fns/difference_in_seconds";
import differenceInWeeks from "date-fns/difference_in_weeks";
import differenceInYears from "date-fns/difference_in_years";
import distanceInWordsToNow from "date-fns/distance_in_words_to_now";
import format from "date-fns/format";
import isAfter from "date-fns/is_after";
import isBefore from "date-fns/is_before";
import isValid from "date-fns/is_valid";
import { defined } from "./variable-evaluation";

let throttleTimeoutId: any;

const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
const ONE_YEAR_IN_MILLIS = ONE_DAY_IN_MILLIS * 365;

export function timestampString(timestamp: string) {
  return new Date(timestamp).toISOString();
}

export function timestampNowString() {
  return new Date().toISOString();
}

export function throttle(fn: () => void, TIME?: number) {
  if (defined(throttleTimeoutId)) {
    clearTimeout(throttleTimeoutId);
  }
  throttleTimeoutId = setTimeout(
    () => {
      fn();
      clearTimeout(throttleTimeoutId);
    },
    defined(TIME) ? TIME : 1000
  );
}

export const timeStampInMs = () => Date.now().toString();
// window.performance &&
// window.performance.now &&
// window.performance.timing &&
// window.performance.timing.navigationStart
//   ? (window.performance.now() + window.performance.timing.navigationStart).toString()
//   : Date.now().toString();

export function isOnline(lastOnline: string) {
  return numberOfMinutes(lastOnline) <= 10;
}

export const differenceInUnits = (dateA: Date, dateB: Date) => ({
  year: differenceInYears(dateA, dateB),
  month: differenceInMonths(dateA, dateB),
  week: differenceInWeeks(dateA, dateB),
  day: differenceInDays(dateA, dateB),
  hour: differenceInHours(dateA, dateB),
  minute: differenceInMinutes(dateA, dateB),
  second: differenceInSeconds(dateA, dateB),
});

export enum EUnit {
  Year = "year",
  Month = "month",
  Week = "week",
  Day = "day",
  Hour = "hour",
  Minute = "minute",
  Second = "second",
}

export function timeDiff(
  timeA: string | "now",
  timeB: string | "now",
  unit?: EUnit
) {
  const dateA = timeA === "now" ? new Date() : new Date(timeA);
  const dateB = timeB === "now" ? new Date() : new Date(timeB);
  if (unit) {
    return differenceInUnits(dateA, dateB)[unit];
  } else {
    return differenceInSeconds(dateA, dateB);
  }
}

export function formatTime(time: string, formatStr: string) {
  const date = new Date(time);
  if (isValid(date)) {
    return format(date, formatStr);
  } else {
    return "";
  }
}

const numberOfYears = (date: string) => timeDiff("now", date, EUnit.Year);
const numberOfMonths = (date: string) => timeDiff("now", date, EUnit.Month);
const numberOfWeeks = (date: string) => timeDiff("now", date, EUnit.Week);
const numberOfMinutes = (date: string) => timeDiff("now", date, EUnit.Minute);

const betweenOneAndTwoYears = (date: string) =>
  numberOfYears(date) >= 1 && numberOfYears(date) < 2;
const moreThanTwoYears = (date: string) => numberOfYears(date) >= 2;

const betweenOneAndTwoMonths = (date: string) =>
  numberOfMonths(date) >= 1 && numberOfMonths(date) < 2;
const moreThanTwoMonths = (date: string) => numberOfMonths(date) >= 2;

const betweenOneAndTwoWeeks = (date: string) =>
  numberOfWeeks(date) >= 1 && numberOfWeeks(date) < 2;
const moreThanTwoWeeks = (date: string) => numberOfWeeks(date) >= 2;

export function dateFromNow(date: string) {
  if (moreThanTwoYears(date)) {
    return `${numberOfYears(date)} years ago`;
  } else if (betweenOneAndTwoYears(date)) {
    return "1 year ago";
  } else if (moreThanTwoMonths(date)) {
    return `${numberOfMonths(date)} months ago`;
  } else if (betweenOneAndTwoMonths(date)) {
    return "1 month ago";
  } else if (moreThanTwoWeeks(date)) {
    return `${numberOfWeeks(date)} weeks ago`;
  } else if (betweenOneAndTwoWeeks(date)) {
    return "1 week ago";
  } else {
    const fromNow = distanceInWordsToNow(new Date(date), {
      addSuffix: true,
    });
    return formatFromNow(fromNow);
  }
}

export function formatFromNow(fromNow: string) {
  if (fromNow.indexOf("about ") > -1) {
    return fromNow.replace("about ", "");
  } else if (fromNow.indexOf("less than a minute ago") > -1) {
    return fromNow.replace("less than a minute ago", "just now");
  } else {
    return fromNow;
  }
}

export function isAfterNow(time: string) {
  return isAfter(new Date(time), new Date());
}

export function isXAfterY(timeX: string, timeY: string) {
  return isAfter(new Date(timeX), new Date(timeY));
}

export function isBeforeNow(time: string) {
  return isBefore(new Date(time), new Date());
}

export function isXBeforeY(timeX: string, timeY: string) {
  return isBefore(new Date(timeX), new Date(timeY));
}

export function formatLastMessageTime(time: string) {
  const date = new Date(time);
  if (!isValid(date)) return "";
  const isBeforeOneDay = isXBeforeY(
    time,
    new Date(Date.now() - ONE_DAY_IN_MILLIS).toISOString()
  );
  if (!isBeforeOneDay) {
    return format(time, "h:mm a");
  }
  const isBeforeOneYear = isXBeforeY(
    time,
    new Date(Date.now() - ONE_YEAR_IN_MILLIS).toISOString()
  );
  if (!isBeforeOneYear) {
    return format(time, "DD MMM");
  } else {
    return format(time, "DD MMM 'YY");
  }
}
