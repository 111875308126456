// organize-imports-ignore

import "./polyfills";
import "./allowExternalDomManipulators";

import React from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { history } from "./history";
import { configureStore } from "./store";
import { lazyWithRetry } from "./utils/lazy";
import "./style/reset.css";
import "./style/index.scss";
import { IS_PROD } from "./globals";

const App = lazyWithRetry(() => import("./App"));
const LoginEntry = lazyWithRetry(() => import("./LoginEntry"));
const MobileEntry = lazyWithRetry(() => import("./MobileEntry"));
const BranchLinkEntry = lazyWithRetry(() => import("./BranchLinkEntry"));
const ThreeDSCallbackEntry = lazyWithRetry(
  () => import("./ThreeDSCallbackEntry")
);

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);

  if (window.location.pathname.startsWith("/login")) {
    root.render(
      // <React.StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey="6LekTtoZAAAAALR9v3BudFjeqW2_Nktw2nhNWhXG">
        <React.Suspense fallback={null}>
          <LoginEntry />
        </React.Suspense>
      </GoogleReCaptchaProvider>
      // </React.StrictMode>
    );
  } else if (window.location.pathname.startsWith("/mobile")) {
    root.render(
      // <React.StrictMode>
      <React.Suspense fallback={null}>
        <MobileEntry />
      </React.Suspense>
      // </React.StrictMode>
    );
  } else if (window.location.pathname.startsWith("/branch")) {
    root.render(
      // <React.StrictMode>
      <React.Suspense fallback={null}>
        <BranchLinkEntry />
      </React.Suspense>
      // </React.StrictMode>
    );
  } else if (window.location.pathname.startsWith("/3ds-callback")) {
    root.render(
      // <React.StrictMode>
      <React.Suspense fallback={null}>
        <ThreeDSCallbackEntry />
      </React.Suspense>
      // </React.StrictMode>
    );
  } else {
    const store = configureStore();
    posthog.init(process.env.REACT_APP_POSTHOG_KEY!, {
      api_host: "https://app.posthog.com",
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false,
      capture_performance: false,
      debug: !IS_PROD,
    });

    root.render(
      // <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <PostHogProvider client={posthog}>
            <React.Suspense fallback={null}>
              <App />
            </React.Suspense>
          </PostHogProvider>
        </Router>
      </Provider>
      // </React.StrictMode>
    );
  }
}
