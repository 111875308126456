import React from "react";
import { Button, EButtonType } from "../../../common/button";
import { ICONS_ACCOUNT } from "../../../common/icons/icons-account";
import styles from "./UnlockFreeChatPopup.module.scss";

export namespace IUnlockFreeChatPopup {
  export interface Props {
    message: string;
    onConfirm: () => void;
  }
}

export const UnlockFreeChatPopup = React.memo(function UnlockFreeChatPopup(
  props: IUnlockFreeChatPopup.Props
) {
  return (
    <article className={styles.UnlockFreeChatPopup}>
      <header>
        <h1>Unlock Free Chat</h1>
      </header>

      <div className={styles.iconWrapper}>{ICONS_ACCOUNT.BigLock}</div>

      <section>
        <p>
          {props.message ||
            "You must make at least one purchase before you can start a free chat."}
        </p>
      </section>

      <Button
        type="button"
        buttonType={EButtonType.BlueWide}
        onClick={props.onConfirm}
      >
        Buy Credits
      </Button>
    </article>
  );
});
