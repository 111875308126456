import { IPopupConfig } from "../models";
import { PopupActions } from "./popup-actions";

export function pushPopup(config: IPopupConfig, actions: PopupActions) {
  actions.pushPopup(config);
}

export function popPopup(id: string | null, actions: PopupActions) {
  actions.popPopup(id);
}
