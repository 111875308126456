// Environment

import { ensureSubscription, pushNotification } from "../pwa";

function getIsBrowser(): boolean {
  return typeof window !== "undefined";
}
export const isBrowser = getIsBrowser();
process.env.NODE_ENV === "development" && isBrowser && console.log("isBrowser");

// Device

// iOS

function getIsIos(): boolean {
  return isBrowser && !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
}
export const isIos = getIsIos();
process.env.NODE_ENV === "development" && isIos && console.log("isIos");

function getIsIosStandalonePossible(): boolean {
  return isBrowser && "standalone" in window.navigator;
}
export const isIosStandalonePossible = getIsIosStandalonePossible();
process.env.NODE_ENV === "development" &&
  isIosStandalonePossible &&
  console.log("isIosStandalonePossible");

export const isSafariMobile = /iP(ad|hone|od).+Version\/[\d.]+.*Safari/i.test(
  navigator.userAgent
);

export const isInstagramInAppBrowser = /Instagram/.test(navigator.userAgent);

export const iosVersion = getIosVersion();
export function getIosVersion(): number[] | undefined {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)!;
    const v3: string = v[3] || "0";
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v3, 10)];
  }
}

// Android

function getIsAndroid(): boolean {
  return isBrowser && !!navigator.userAgent.match(/Android/i);
}
export const isAndroid = getIsAndroid();
process.env.NODE_ENV === "development" && isAndroid && console.log("isAndroid");

// Desktop

function getIsDesktop(): boolean {
  return isBrowser && !isIos && !isAndroid && window.innerWidth >= 640;
}
export const isDesktop = getIsDesktop();
process.env.NODE_ENV === "development" && isDesktop && console.log("isDesktop");

// Common

function getIsStandalone(): boolean {
  if (!isBrowser) {
    return false;
  }
  if (isIos) {
    return !!(window.navigator as Navigator & { standalone: boolean })
      .standalone;
  }
  return window.matchMedia("(display-mode: standalone)").matches;
}
export const isStandalone = getIsStandalone();
process.env.NODE_ENV === "development" &&
  isStandalone &&
  console.log("isStandalone");

// General

export function browserCheck() {
  let N = navigator.appName,
    ua = navigator.userAgent,
    tem;
  let M = ua.match(
    /(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i
  );
  if (M && (tem = ua.match(/version\/([.\d]+)/i)) != null) {
    M[2] = tem[1];
  }
  M = M ? [M[1], M[2]] : [N, navigator.appVersion, "-?"];
  return M;
}

export const userAgent = navigator.userAgent;

// Feature support

// better check for support by trying
// because many times the feature support is there
// but is blocked to use because of security
// (https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/)
function getIsLocalStorageSupported() {
  try {
    const testKey = "__test_key__";
    localStorage.setItem(testKey, testKey);
    localStorage.getItem(testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}
// export const isLocalStorageSupported = typeof Storage !== 'undefined' && 'localStorage' in window && localStorage !== null;
export const isLocalStorageSupported = getIsLocalStorageSupported();

// better check for support by trying
// because many times the feature support is there
// but is blocked to use because of security
// (https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/)
function getIsSessionStorageSupported() {
  try {
    const testKey = "__test_key__";
    sessionStorage.setItem(testKey, testKey);
    sessionStorage.getItem(testKey);
    sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}
// export const isSessionStorageSupported = typeof Storage !== 'undefined' && 'sessionStorage' in window && sessionStorage !== null;
export const isSessionStorageSupported = getIsSessionStorageSupported();

export const supportsNativeSmoothScroll =
  "scrollBehavior" in document.documentElement.style;

export const isServiceWorkerSupported = "serviceWorker" in navigator;

export const isNotificationSupported =
  "Notification" in window && "PushManager" in window;

export const isWebSocketsSupported = "WebSocket" in window;

// This can be used to hide the button if the user has already answered the notification prompt (if the value is not ‘default’).
export const isDefaultNotificationPermission = (): boolean => {
  return Notification.permission === "default";
};

export const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    ensureSubscription();
    pushNotification(
      "Notifications enabled 🎉",
      "Thank you for enabling notifications!"
    );
  }
};
