import { createAction } from "redux-actions";

export namespace IWebsocketActions {
  export enum Type {
    SET__IS_CONNECTED = "SET__IS_CONNECTED",

    SET__IS_DISCONNECTED = "SET__IS_DISCONNECTED",
  }

  export const setIsConnected = createAction(Type.SET__IS_CONNECTED);

  export const setIsDisconnected = createAction(Type.SET__IS_DISCONNECTED);
}

export type WebsocketActions = Omit<typeof IWebsocketActions, "Type">;
