import classNames from "classnames";
import React from "react";
import { renderDefinedTrue } from "../../utils/react-conditional";
import { defined } from "../../utils/variable-evaluation";
import styles from "./ToolTip.module.scss";

export enum ETailType {
  Left = "TailTypeLeft",
  Right = "TailTypeRight",
  Center = "TailTypeCenter",
}

export namespace IToolTip {
  export interface Props {
    tailType?: ETailType;
    onWindowClick?: (e: any) => void;
    children?: React.ReactNode;
  }
}

export class ToolTip extends React.PureComponent<IToolTip.Props> {
  public componentDidMount() {
    window.addEventListener("click", this.handleWindowClick);
  }

  public componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  private handleWindowClick = (e: any) => {
    this.props.onWindowClick && this.props.onWindowClick(e);
  };

  public render() {
    return (
      <div
        className={classNames(styles.ToolTip, {
          [styles[this.props.tailType!]]: defined(this.props.tailType),
        })}
      >
        {renderDefinedTrue(this.props.tailType, () => (
          <div className={styles.tail} />
        ))}
        <div className={styles.content}>{this.props.children}</div>
      </div>
    );
  }
}
