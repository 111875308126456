export enum ESpinnerSize {
  ExtraSmall = "20px",
  Small = "25px",
  Medium = "50px",
  Large = "75px",
}

export enum ESpinnerType {
  Ios = "Ios",
  Circular = "Circular",
}

export const LOADING_SPINNER = (size?: string) => {
  return {
    Ios: (
      <svg
        className="LoadingSpinner"
        width={size || "100%"}
        height={size || "100%"}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.9375s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(22.5 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.875s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(45 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.8125s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(67.5 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.75s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.6875s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(112.5 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.625s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(135 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.5625s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(157.5 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.5s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.4375s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(202.5 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.375s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(225 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.3125s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(247.5 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.25s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.1875s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(292.5 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.125s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(315 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="-0.0625s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(337.5 50 50)">
          <rect
            x="47"
            y="18"
            rx="2"
            ry="2"
            width="6"
            height="14"
            fill="currentColor"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
      </svg>
    ),
    Circular: (
      <svg
        width={size || "100%"}
        height={size || "100%"}
        viewBox="-1 -1 22 22"
        fill="none"
        style={{ display: "inline-block" }}
      >
        <circle
          cx="10"
          cy="10"
          r="10"
          stroke="currentColor"
          strokeOpacity="0.25"
          strokeWidth="2"
        />
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="5.75877"
        >
          <animateTransform
            attributeName="transform"
            dur="2s"
            type="rotate"
            from="0 10 10"
            to="360 10 10"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    ),
  };
};
