import reUtf16UnpairedSurrogate from "@stdlib/regexp-utf16-unpaired-surrogate";
import emojiRegexCreator from "emoji-regex";
import { toHumanString } from "human-readable-numbers";
import { IProviderConfig } from "../account/account-models";
import { defined } from "./variable-evaluation";

export function toTitleCase(str: string) {
  return str
    .replace(/-/g, " ")
    .replace(/_/g, " ")
    .split(" ")
    .map((word) =>
      word.length > 0
        ? `${word[0].toLocaleUpperCase()}${word.substring(1)}`
        : word
    )
    .join(" ");
}

export function capitalizeFirstLetter(str: string) {
  if (typeof str === "string") {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
}

export function subIsIn(sub: string, str: string) {
  return str.indexOf(sub) > -1;
}

export function removeFirstChar(str: string) {
  return str.substr(1);
}

function formatString(input: string): string {
  const formattedString = ` ${input} ` // to handle whole word search, e.g. ' supe '
    .toLocaleLowerCase() // lower case only
    .replace(/[$&+,:;=?#|/\\'<>.^*()%!-]/g, " "); // remove special characters
  return formattedString;
}

export function isWordsInSentence(sentence: string, words: string[]): boolean {
  const formattedSentence = formatString(sentence);
  return words.some((word) => formattedSentence.includes(formatString(word)));
}

export function removeWordsInSentence(
  sentence: string,
  words: string[]
): string {
  return sentence.replace(
    new RegExp("\\b(" + words.join("|") + ")\\b", "gi"),
    ""
  );
}

export function getWordsInSentence(
  sentence: string,
  words: string[]
): string[] {
  const formattedSentence = formatString(sentence);
  return words.filter((word) => formattedSentence.includes(formatString(word)));
}

function getDirtyWords(providerConfig: IProviderConfig): string[] {
  let keywords: string[] = [];
  if (defined(providerConfig) && defined(providerConfig.greeting_keywords)) {
    keywords = providerConfig.greeting_keywords!.split(",");
  }
  return keywords;
}

function getCompetingAppWords(providerConfig: IProviderConfig): string[] {
  let keywords: string[] = [];
  if (defined(providerConfig)) {
    if (defined(providerConfig.payment_keywords)) {
      keywords.push(...providerConfig.payment_keywords!.split(","));
    }
    if (defined(providerConfig.app_keywords)) {
      keywords.push(...providerConfig.app_keywords!.split(","));
    }
    if (defined(providerConfig.global_keywords)) {
      keywords.push(...providerConfig.global_keywords!.split(","));
    }
  }
  return keywords;
}

export const urlMatchingExpression =
  /(https?:\/\/)?(w){0,3}\.?[-a-z0-9]{1,256}\.(com|net|org|eu|info|co\.uk)\b([-a-z0-9()@:%_+~#?&//=]*)?/gi;

export function extractSubstring(
  fullStr: string,
  prefix: string,
  suffix: string
) {
  const prefixIndex = fullStr.indexOf(prefix) + prefix.length;
  const suffixIndex = fullStr.indexOf(suffix);

  return fullStr.substring(prefixIndex, suffixIndex);
}

export const numberWithCommas = (x: number | string) => {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export function toFixedWithoutRounding(
  num: number,
  fractionDigits?: number
): string {
  if (typeof num !== "number") {
    return "NaN";
  }
  if ((num > 0 && num < 0.000001) || (num < 0 && num > -0.000001)) {
    // HACK: below this js starts to turn numbers into exponential form like 1e-7.
    // This gives wrong results so we are just changing the original number to 0 here
    // as we don't need such small numbers anyway.
    num = 0;
  }
  // prettier-ignore
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fractionDigits || -1) + '})?');
  return Number(num.toString().match(re)![0]).toFixed(fractionDigits);
}

export function creditsToDollars(credits: number) {
  const fixedWithoutRound = toFixedWithoutRounding(credits * 0.1, 2);
  if (fixedWithoutRound[0] === "-") {
    return `-$${fixedWithoutRound.slice(1)}`;
  }
  return `$${fixedWithoutRound}`;
}

export function censor(str: string): string {
  if (str.length > 4) {
    return str.replace(str.substring(2, str.length - 2), "***");
  }
  if (str.length > 2) {
    return str.replace(str.substring(1, str.length - 1), "***");
  }
  return "***";
}

export function censorEmail(email: string): string {
  const emailParts = email.split("@");
  const censorPart = emailParts[0];
  const censorPartLength = censorPart.length;
  if (censorPartLength >= 8) {
    return (
      censorPart.substring(0, 3) +
      "*".repeat(censorPartLength - 3) +
      "@" +
      emailParts[1]
    );
  }
  if (censorPartLength >= 4) {
    return (
      censorPart.substring(0, 2) +
      "*".repeat(censorPartLength - 2) +
      "@" +
      emailParts[1]
    );
  }
  if (censorPartLength >= 2) {
    return (
      censorPart.substring(0, 1) +
      "*".repeat(censorPartLength - 1) +
      "@" +
      emailParts[1]
    );
  }
  return `*@${emailParts[1]}`;
}

export function censorMobileNumber(mobileNumber: string): string {
  return "*".repeat(mobileNumber.length - 4) + mobileNumber.slice(-4);
}

export function censorCryptoWalletAddress(walletAddress: string): string {
  return "*".repeat(4) + walletAddress.slice(-6);
}

export function intToStrRank(num: number, zeroBased = false) {
  if (zeroBased) {
    if (num === 0) return "1st";
    if (num === 1) return "2nd";
    if (num === 2) return "3rd";
    return `${num + 1}th`;
  } else {
    if (num === 1) return "1st";
    if (num === 2) return "2nd";
    if (num === 3) return "3rd";
    return `${num}th`;
  }
}

export function ordinal(i: number): string {
  const suffixes: string[] = [
    "th",
    "st",
    "nd",
    "rd",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
  ];
  switch (i % 100) {
    case 11:
    case 12:
    case 13:
      return "th";
    default:
      return suffixes[i % 10];
  }
}

export function truncateWithEllipsis(str: string, maxLength: number) {
  if (typeof str === "string") {
    if (str.length <= maxLength) {
      return str;
    }
    return str.substr(0, maxLength) + "…";
  }
  return "";
}

export function numberToHumanReadable(num: number): string {
  return toHumanString(num);
}

export function addUrlParam(
  urlString: string,
  paramName: string,
  paramValue: string
): string {
  return `${urlString}${
    urlString.match(/[?]/g) ? "&" : "?"
  }${paramName}=${encodeURI(paramValue)}`;
}

export function replaceUtf16UnpairedSurrogates(str: string): string {
  const RE_UTF16_UNPAIRED_SURROGATE = reUtf16UnpairedSurrogate();
  return str.replace(RE_UTF16_UNPAIRED_SURROGATE, "�");
}

export function isEmoji(value: string): boolean {
  const emojiRegex = emojiRegexCreator();
  return emojiRegex.test(value);
}

export function isSpecialCharacters(value: string): boolean {
  return /[:/~!@#$%^&*()[\]{};|?._=<>\\]/.test(value);
}

export function isUserAskingExternalPayment(
  text: string,
  providerConfig: IProviderConfig
): boolean {
  let keywords: string[] = [];
  if (defined(providerConfig) && defined(providerConfig.payment_keywords)) {
    keywords = providerConfig.payment_keywords!.split(",");
  }
  return isWordsInSentence(text, keywords);
}

export function isUserUsingForbiddenKeyword(
  text: string,
  providerConfig: IProviderConfig
): boolean {
  let keywords: string[] = [];
  if (defined(providerConfig) && defined(providerConfig.global_keywords)) {
    keywords = providerConfig.global_keywords!.split(",");
  }
  return isWordsInSentence(text, keywords);
}

export function isUsingDirtyWords(
  text: string,
  providerConfig: IProviderConfig
): boolean {
  return isWordsInSentence(text, getDirtyWords(providerConfig));
}

export function isUsingCompetingAppWords(
  text: string,
  providerConfig: IProviderConfig
): boolean {
  return isWordsInSentence(text, getCompetingAppWords(providerConfig));
}

export function formatVideoDuration(videoLength?: number | string | null) {
  if (videoLength === 0) {
    return "0s";
  }
  if (
    !videoLength ||
    (typeof videoLength !== "string" && typeof videoLength !== "number") ||
    isNaN(Number(videoLength))
  ) {
    return "";
  }

  if (typeof videoLength !== "number") {
    videoLength = Number(videoLength);
  }
  videoLength = Math.round(videoLength);
  if (videoLength === 0) {
    videoLength = 1; // min length 1 seconds
  }

  const minutes = Math.floor(videoLength / 60);
  const seconds = videoLength % 60;
  return `${minutes > 0 ? `${minutes}m` : ""}${
    minutes >= 1 && seconds < 10 ? "0" + seconds : seconds
  }s`;
}

function levenshteinDistance(a: string, b: string): number {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  const matrix = [];

  // increment along the first column of each row
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      let cost = 1;
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        cost = 0;
      }
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // deletion
        matrix[i][j - 1] + 1, // insertion
        matrix[i - 1][j - 1] + cost // substitution
      );
    }
  }

  return matrix[b.length][a.length];
}

export function checkEmailSuggestions(email: string): string {
  // extract domain from email
  const emailParts = email.split("@");
  const domain = emailParts[1];

  // return empty string if email is invalid  or domain is missing
  if (!domain) {
    return "";
  }

  const commonDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
  ];

  // calculate Levenshtein distance between email and suggestions
  const distances = commonDomains.map((commonDomain) => {
    return {
      domain: commonDomain,
      distance: levenshteinDistance(domain, commonDomain),
    };
  });

  // sort by distance
  distances.sort((a, b) => a.distance - b.distance);

  const threshold = 3;

  // return empty string if email is already valid or distance is too big
  if (distances[0].distance === 0 || distances[0].distance > threshold) {
    return "";
  }

  // return suggestion with smallest distance
  return `${emailParts[0]}@${distances[0].domain}`;
}
