import { handleActions } from "redux-actions";
import { IUserInfo } from "../account/account-models";
import { IDictionary, IPopupConfig } from "../models";
import { removeDuplicates } from "../utils/array";
import { ERequestStatus, IGetRequest, requestInit } from "../utils/async";
import { defined } from "../utils/variable-evaluation";
import { IDiscoverActions } from "./discover-actions";
import {
  getDefaultDiscoverFiltersState,
  IDiscoverFilter,
  IGetProfilesConfig,
} from "./discover-helpers";
import {
  EDiscoverPunishmentAlertType,
  IDiscoverAlerts,
} from "./discover-models";

export interface IDiscoverModel {
  isInitialRequest: boolean;
  filtersSet: IDictionary<IDiscoverFilter>;
  getProfiles: IGetRequest<any>;
  profiles: (IUserInfo & { __isImpressionTracked?: boolean })[];
  nextProfilesUrl: string | null;
  rank: number | null;
  alerts: IDiscoverAlerts;
  discoverPopup: IPopupConfig | null;
}

export const discoverStoreInitialState: IDiscoverModel = {
  isInitialRequest: true,
  filtersSet: getDefaultDiscoverFiltersState(),
  getProfiles: requestInit(),
  profiles: [],
  nextProfilesUrl: null,
  rank: null,
  alerts: {
    [EDiscoverPunishmentAlertType.Punishment]: false,
    [EDiscoverPunishmentAlertType.FinalPunishment]: false,
  },
  discoverPopup: null,
};

/** Reducer */
export const discoverReducer = handleActions<IDiscoverModel, IDiscoverModel>(
  {
    [IDiscoverActions.Type.GET__PROFILES__INIT]: (state, action: any) => {
      const { isInitialRequest, isForceLoader } = action.payload
        .config as IGetProfilesConfig;
      return {
        ...state,
        getProfiles: {
          status: ERequestStatus.Fetching,
        },
        isInitialRequest,
        // listScrollY: isInitialRequest ? 0 : state.listScrollY,
        // profiles: isInitialRequest ? [] : state.profiles,
        profiles: isForceLoader ? [] : state.profiles,
      };
    },
    [IDiscoverActions.Type.GET__PROFILES__SUCCESS]: (state, action: any) => {
      const { config, res }: { config: IGetProfilesConfig; res: any } =
        action.payload;
      let profiles = [];
      if (config.isInitialRequest) {
        profiles = res.data;
      } else {
        profiles = [...state.profiles, ...res.data];
      }
      profiles = removeDuplicates(profiles, "username");
      return {
        ...state,
        nextProfilesUrl: defined(res.next) ? res.next : null,
        getProfiles: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        profiles,
        alerts: {
          [EDiscoverPunishmentAlertType.Punishment]: res.punishment_alert,
          [EDiscoverPunishmentAlertType.FinalPunishment]:
            res.final_punishment_alert,
        },
        rank: res.rank,
        filtersSet: config.filtersSet,
      };
    },
    [IDiscoverActions.Type.GET__PROFILES__FAILURE]: (state, action: any) => {
      return {
        ...state,
        getProfiles: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IDiscoverActions.Type.SET__DISCOVER_POPUP]: (state, action: any) => {
      return {
        ...state,
        discoverPopup: action.payload,
      };
    },
    [IDiscoverActions.Type.SET__DISCOVER_ALERT_CONFIRMED]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          [action.payload.type]: false,
        },
      };
    },
    [IDiscoverActions.Type.SET__DISCOVER_PROFILE_IMPRESSION_TRACKED]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        profiles: state.profiles.map((profile) => {
          if (profile.username === action.payload) {
            return {
              ...profile,
              __isImpressionTracked: true,
            };
          }
          return profile;
        }),
      };
    },
  },
  discoverStoreInitialState
);
