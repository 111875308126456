import { IDictionary } from "../models";

export function formattingOverrides(): IDictionary<string | boolean> {
  return {
    autoComplete: "off",
    autoCorrect: "off",
    autoCapitalize: "none",
    spellCheck: false,
  };
}

export function moveCursorToEndOfInput(el: any) {
  if (typeof el.selectionStart === "number") {
    el.selectionStart = el.selectionEnd = el.value.length;
  } else if (typeof el.createTextRange !== "undefined") {
    el.focus();
    var range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
}
