import classNames from "classnames";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { renderIfTrue, renderSwitch } from "../../utils/react-conditional";
import { defined, exists } from "../../utils/variable-evaluation";
import { ICONS_BUTTON } from "../icons/icons-common/IconsButton";
import {
  ESpinnerSize,
  ESpinnerType,
  LOADING_SPINNER,
} from "../icons/icons-common/IconsLoading";
import styles from "./Button.module.scss";

export enum EButtonType {
  NoStyle = "NoStyle",
  Gallery = "Gallery",
  ListItem = "ListItem",
  ListItemDesktop = "ListItemDesktop",
  BasicLink = "BasicLink",
  BlueLink = "BlueLink",
  RedLink = "RedLink",
  WhiteLink = "WhiteLink",
  ProfileListed = "ProfileListed",
  ProfileListedError = "ProfileListedError",
  GreenWide = "GreenWide",
  BlueWide = "BlueWide",
  RedWide = "RedWide",
  WideGhost = "WideGhost",
  BluePill = "BluePill",
  GreenPill = "GreenPill",
  GhostPill = "GhostPill",
  BlackGhost = "BlackGhost",
  MenuItem = "MenuItem",
  ChatItem = "ChatItem",
  HeaderMenuOption = "HeaderMenuOption",
  MobileFilter = "MobileFilter",
  DesktopFilter = "DesktopFilter",
  ImageGalleryDot = "ImageGalleryDot",
  CompleteProfile = "CompleteProfile",
  Icon = "Icon",
  IconWithBackground = "IconWithBackground",
  InstagramImage = "InstagramImage",
  SwitchButton = "SwitchButton",
  TabButton = "TabButton",
}

export enum EButtonActionType {
  InternalLink = "Internal Link",
  ExternalLink = "External Link",
  NormalButton = "Normal Button",
}

interface ILinkProps extends React.ComponentClass<LinkProps> {}
interface IAnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}
interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
type IPropsUnion = ILinkProps | IAnchorProps | IButtonProps;

type IProps = {
  dataTest?: string; // used for integration tests
  dataId?: string; // used for debugging
  to?: any; // string or object or function (https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/Link.md) TODO: use @types/react-router-dom
  href?: string;
  target?: string;
  buttonType?: EButtonType;
  isLoading?: boolean;
  hideLoader?: boolean;
  isError?: boolean;
  isLoaded?: boolean;
  isDisabled?: boolean; // BEWARE: link properties 'to' and 'href' are still working even if disabled
  isActive?: boolean;
  additionalClasses?: string[];
  inlineStyle?: React.CSSProperties;
  children?: React.ReactNode;
};

type IPropTypes = IProps & IPropsUnion;

export class Button extends React.PureComponent<IPropTypes> {
  private renderContent() {
    return (
      <>
        {renderIfTrue(!!this.props.isLoading && !this.props.hideLoader, () => (
          <div className={styles.loadingIcon}>
            {LOADING_SPINNER(ESpinnerSize.Small)[ESpinnerType.Ios]}
          </div>
        ))}
        {renderIfTrue(!!this.props.isError, () => (
          <div className={styles.errorIcon}>{ICONS_BUTTON.Error}</div>
        ))}
        {renderIfTrue(!!this.props.isLoaded, () => (
          <div className={styles.loadingIcon}>{ICONS_BUTTON.Check}</div>
        ))}
        {this.props.children}
      </>
    );
  }

  private action(): string {
    if (defined(this.props.to)) {
      return EButtonActionType.InternalLink;
    } else if (defined(this.props.href)) {
      return EButtonActionType.ExternalLink;
    } else {
      return EButtonActionType.NormalButton;
    }
  }

  private renderButton() {
    const {
      dataTest,
      dataId,
      isDisabled,
      isActive,
      isLoading,
      hideLoader,
      isError,
      isLoaded,
      buttonType,
      to,
      href,
      target,
      children,
      additionalClasses,
      inlineStyle,
      ...remainingProps
    } = this.props;

    const subClasses = {
      [styles.isDisabled]: isDisabled,
      [styles.isActive]: isActive,
      [styles.isError]: isError,
      [styles[buttonType!]]: defined(buttonType),
    };

    if (defined(additionalClasses)) {
      additionalClasses!.forEach((additionalClass) => {
        if (exists(additionalClass)) {
          subClasses[styles[additionalClass]] = true;
        }
      });
    }

    const buttonClass = classNames(styles.Button, subClasses);

    return renderSwitch(this.action(), {
      [EButtonActionType.InternalLink]: () => (
        <Link
          data-test={dataTest}
          data-id={dataId}
          to={to}
          className={buttonClass}
          role="button"
          style={inlineStyle}
          {...(remainingProps as any)}
        >
          {this.renderContent()}
        </Link>
      ),
      [EButtonActionType.ExternalLink]: () => (
        <a
          data-test={dataTest}
          data-id={dataId}
          key={`a-${href}`}
          href={href}
          className={buttonClass}
          role="button"
          target={`${target || "_blank"}`}
          style={inlineStyle}
          {...(remainingProps as IAnchorProps)}
        >
          {this.renderContent()}
        </a>
      ),
      [EButtonActionType.NormalButton]: () => (
        <button
          data-test={dataTest}
          data-id={dataId}
          key="button"
          className={buttonClass}
          disabled={isDisabled || isLoading}
          style={inlineStyle}
          {...(remainingProps as IButtonProps)}
        >
          {this.renderContent()}
        </button>
      ),
    });
  }

  public render() {
    return this.renderButton();
  }
}
