import { IEditContentPopup } from "../common/paid-content/edit-content-popup";
import { IUploadContentPopup } from "../common/paid-content/upload-content-popup";
import { API_ROOT } from "../globals";
import { getRequest, patchRequest } from "../utils/fetch";
import { FeedActions } from "./feed-actions";
import { feedUrl, IGetFeedConfig } from "./feed-helpers";

const REFETCH_DELAY = 10 * 60 * 1000; // 10 minutes
window["feedLastFetchedAt"] = 0;

export function initFeedRequests(
  config: IGetFeedConfig,
  actions: FeedActions,
  isOnlyExecuteIfNotCalledLast10Minutes?: boolean
) {
  if (isOnlyExecuteIfNotCalledLast10Minutes) {
    if (Date.now() < window["feedLastFetchedAt"]! + REFETCH_DELAY) {
      return;
    }
  }

  getFeed(config, actions);
}

export function getFeed(config: IGetFeedConfig, actions: FeedActions) {
  actions.initGetFeed({ config });
  getRequest(feedUrl(config)).then((res) => {
    actions.successGetFeed({ res, config });
    window["feedLastFetchedAt"] = Date.now();
  }, actions.failureGetFeed);
}

export function getNextFeed(
  nextUrl: string,
  config: IGetFeedConfig,
  actions: FeedActions
) {
  actions.initGetFeed({ config });
  getRequest(API_ROOT + nextUrl).then(
    (res) => actions.successGetFeed({ res, config }),
    actions.failureGetFeed
  );
}

export function toggleUploadContent(
  uploadContentConfig: IUploadContentPopup.Props | null,
  actions: FeedActions
) {
  actions.toggleUploadContent({ uploadContentConfig });
}

export function toggleEditContent(
  editContentConfig: IEditContentPopup.Props | null,
  actions: FeedActions
) {
  actions.toggleEditContent({ editContentConfig });
}

export function patchFeedItem(
  feedItemId: string,
  newValues: any,
  actions: FeedActions
) {
  actions.initPatchFeedItem();
  patchRequest(`${API_ROOT}/v1/feed/${feedItemId}`, newValues).then(
    (res) => actions.successPatchFeedItem({ res, newValues }),
    (error) => actions.failurePatchFeedItem({ error, newValues })
  );
}

export function resetPatchFeedItem(actions: FeedActions) {
  actions.resetPatchFeedItem();
}

// export function patchFeedItem(itemId: string, actions: FeedActions) {
//   actions.initPatchFmFeedItemById({ itemId });
//   deleteRequest(`${API_ROOT}/v1/feed/${itemId}`)
//     .then(actions.successPatchFmFeedItemById, actions.failurePatchFmFeedItemById);
// }

// export function deleteFmFeedItemById(itemId: string, actions: FeedActions) {
//   actions.initDeleteFmFeedItemById({ itemId });
//   deleteRequest(`${API_ROOT}/v1/feed/${itemId}`)
//     .then(actions.successDeleteFmFeedItemById, actions.failureDeleteFmFeedItemById);
// }

// export function getFollowing(id: string, actions: FeedActions) {
//   actions.initGetFollowing();
//   getRequest(`${API_ROOT}/v1/users/${id}/following`)
//     .then(actions.successGetFollowing, actions.failureGetFollowing);
// }
//
// export function getFeedById(id: string, actions: FeedActions) {
//   actions.initGetFeedById(id);
//   getRequest(`${API_ROOT}/v1/feed?model_id=${id}`)
//     .then(actions.successGetFeedById, actions.failureGetFeedById);
// }

export function setUnreadFeeds(unreadFeeds: number, actions: FeedActions) {
  actions.setUnreadFeeds(unreadFeeds);
}
