import { IChatInfo } from "../chats/chats-models";
import { ALUA_SUPPORT_USERNAME } from "../globals";
import { timeDiff } from "./time";
import { defined } from "./variable-evaluation";

export function createArray(length: number): null[] {
  if (length <= 0) {
    return [];
  }
  return Array.apply(null, new Array(length)) as null[];
}

export function createArrayOf<C>(content: C, length: number): C[] {
  return Array.apply(null, new Array(length)).map((_) => content);
}

export function setInArray(arr: any[], index: number, val: any) {
  return Object.assign([...arr], { [index]: val });
}

export function addToArrayIfNotThere(arr: any[], val: any) {
  if (arr.indexOf(val) > -1) {
    return arr;
  } else {
    return [...arr, val];
  }
}

export function addArrayToArrayIfNotThere(arr1: any[], arr2: any[]): any[] {
  let result = [...arr1];
  arr2.forEach((val) => {
    if (arr1.indexOf(val) === -1) {
      result = [...result, val];
    }
  });
  return result;
}

export function removeInArray(arr: any[], val: any) {
  return arr.filter((arrItem) => arrItem !== val);
}

export function extractProp<O>(arr: O[], prop: string) {
  return arr.map((item: O) => (item as any)[prop]);
}

export function removeInArrayBy(prop: string, arr: any[], val: any) {
  return arr.filter((item) => item[prop] !== val);
}

export function replaceInArrayBy(
  prop: string,
  arr: any[],
  val: any,
  newItem: any
) {
  return arr.map((item) => (item[prop] === val ? newItem : item));
}

export function sortByName(a: any, b: any) {
  if (typeof a.name === "number") {
    return a.name - (b.name as any);
  } else {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }
}

export function sortBy(prop: string, a: any, b: any) {
  if (typeof a[prop] === "number") {
    return sortNumberBy(prop, a, b);
  } else {
    return sortCharBy(prop, a, b);
  }
}

export function sortNumberBy(prop: string, a: any, b: any) {
  return a[prop] - (b[prop] as any);
}

export function sortCharBy(prop: string, a: any, b: any) {
  if (a[prop] < b[prop]) {
    return -1;
  }
  if (a[prop] > b[prop]) {
    return 1;
  }
  return 0;
}

export function sortByCreatedAt(a: any, b: any) {
  if (timeDiff(a.created_at, b.created_at) < 0) {
    return -1;
  }
  if (timeDiff(a.created_at, b.created_at) > 0) {
    return 1;
  }
  return 0;
}

function isUnreadSupportChat(chat: IChatInfo): boolean {
  return (
    chat.unread_messages > 0 &&
    chat.other_user.username === ALUA_SUPPORT_USERNAME
  );
}

export function sortByLastMessageTime(chatA: IChatInfo, chatB: IChatInfo) {
  if (isUnreadSupportChat(chatB)) {
    return 1;
  }
  if (isUnreadSupportChat(chatA)) {
    return -1;
  }
  if (timeDiff(chatA.last_message_time, chatB.last_message_time) < 0) {
    return 1;
  }
  if (timeDiff(chatA.last_message_time, chatB.last_message_time) > 0) {
    return -1;
  }
  return 0;
}

export function sortNumber(a: any, b: any) {
  return a - b;
}

export function sortChar(a: any, b: any) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function isXInArrayOf(x: string, arr: any[], prop: string): boolean {
  return arr.some((info) => info[prop] === x);
}

export function itemsInArrOf<T>(items: T[], arr: any[], prop: string): any[] {
  return arr.filter((x: T) => items.indexOf((x as any)[prop]) > -1);
}
export function arrOfProp<T>(arr: T[], prop: string): string[] {
  return arr.reduce((acc: string[], curr: T) => {
    if (acc.indexOf((curr as any)[prop]) === -1) {
      acc.push((curr as any)[prop]);
    }
    return acc;
  }, []);
}

export function flatten(arr: any[]): any[] {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
}

export function findArrayIndex(
  array: any[],
  predicateFunction: (arr: any[]) => boolean
) {
  const length = array == null ? 0 : array.length;
  if (!length) {
    return -1;
  }
  let index = -1;
  for (let i = 0; i < array.length; ++i) {
    if (predicateFunction(array[i])) {
      index = i;
      break;
    }
  }
  return index;
}

export function removeDuplicates(
  arr: any[],
  idProp: string,
  removeFromStart?: boolean
) {
  const mappedArr = arr.map((mapObj) => mapObj[idProp]);
  return arr.filter((obj, i) => {
    if (defined(obj[idProp])) {
      if (removeFromStart) {
        return mappedArr.lastIndexOf(obj[idProp]) === i;
      }
      return mappedArr.indexOf(obj[idProp]) === i;
    }
    return true;
  });
}

export function lastIndexInArrayWithValue(
  arr: any[],
  prop: string,
  value: any
): number | null {
  let index = arr.length - 1;
  for (; index >= 0; index--) {
    if (arr[index][prop] === value) {
      return index;
    }
  }
  return null;
}

export function minMax(items: any[]) {
  return items.reduce((acc, val) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, []);
}

export function shuffleArray(arr: any[]): any[] {
  const newArr = [...arr];
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
}
