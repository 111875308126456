import { History } from "history";
import debounce from "lodash.debounce";
import { IUserInfo } from "../../account/account-models";
import { EPopupMessengerType } from "../../models";
import { publishTypingState } from "../../pubnub/pubnub-helpers";
import { ITypingPublishConfig } from "../../pubnub/pubnub-models";
import { findArrayIndex, setInArray } from "../../utils/array";
import { isChanged } from "../../utils/react-lifecycle";
import { defined, exists } from "../../utils/variable-evaluation";
import { setMessengerPopup } from "./messenger-action-creators";
import { MessengerActions } from "./messenger-actions";
import { IMessageInfo } from "./messenger-models";

/* selectedChat */
export function isNewSelectedChat(props: any, nextProps: any) {
  return (
    isChanged(
      exists(props.selectedChat) && props.selectedChat._id,
      nextProps.selectedChat._id
    ) && defined(nextProps.selectedChat)
  );
}
export function isRemoveSelectedChat(props: any, nextProps: any) {
  return (
    isChanged(
      exists(props.selectedChat) && props.selectedChat._id,
      nextProps.selectedChat._id
    ) && !defined(nextProps.selectedChat)
  );
}

/* Reducer messages */

export function nextMessagesWithErrorMessage(
  messages: IMessageInfo[],
  payload: any,
  idKey: any
) {
  const errorMessageIndex = findArrayIndex(messages, (message) => {
    return defined(message[idKey]) && message[idKey] === payload[idKey];
  });
  if (errorMessageIndex > -1) {
    let errorMessage = messages[errorMessageIndex];
    errorMessage.error = payload.error;
    return setInArray(messages, errorMessageIndex, errorMessage);
  } else if (defined(messages)) {
    return [...messages, { [idKey]: payload[idKey], error: payload.error }];
  } else {
    return [];
  }
}

// export function nextMessagesFromPaidMessageWithFilters(messages, paidMessage) {
//   let nextMessages = replaceInArrayBy('_id', messages, paidMessage._id, paidMessage);
//   return removeDuplicates(nextMessages, '_id');
// }

// export function isNewMessages(state: IMessengerModel, newMessages: IMessage[]) {
//   return exists(state.chats[state.currentId]) && defined(newMessages) && newMessages.length > 0;
// }

// export function isNewMessagesCurrent(state, newMessages: IMessage[]) {
//   return newMessages.every(
//     (message) => message.sender_id === state.currentId || message.recipient_id === state.currentId
//   );
// }

export function formatCurrentMessage(message: string) {
  message = message.trim();
  if (message[message.length - 1] === "↵") {
    message.slice(0, -1);
  }
  return message;
}

export function popupUploadInProgress(
  numberOfUploads: number,
  history: History,
  actions: MessengerActions
) {
  setMessengerPopup(
    {
      type: EPopupMessengerType.PopupUploadInProgress,
      props: {
        numberOfUploads,
        onCancelUploads: () => {
          setMessengerPopup(null, actions);
          history.push("/chats");
        },
        onClose: () => {
          setMessengerPopup(null, actions);
        },
      },
    },
    actions
  );
}

export function popupMessagingDisabled(actions: MessengerActions) {
  setMessengerPopup(
    {
      type: EPopupMessengerType.PopupMessagingDisabled,
      props: { onClose: () => setMessengerPopup(null, actions) },
    },
    actions
  );
}

// export function popupError(errorMessage: string, actions: MessengerActions) {
//   setMessengerPopup(
//     {
//       type: EPopupMessengerType.PopupError,
//       props: {
//         title: errorMessage,
//         onClose: () => setMessengerPopup(null, actions)
//       }
//     },
//     actions
//   );
// }

// export function errorMessage(error) {
//   if (exists(error.message)) {
//     switch (error.message) {
//       case 'Failed to fetch':
//         return 'Message failed. Please check your connection.';
//       case 'Type error':
//         return 'Something went wrong, please try and resend.';
//       default:
//         return error.message;
//     }
//   } else {
//     return 'Something went wrong';
//   }
// }

// export function isFirstSentMessage(messages, userId) {
//   return (
//     messages.filter((message: IMessage) => {
//       return message.sender_id === userId && !message.is_system;
//     }).length === 0
//   );
// }

// export function isFirstSentMessageFromUserToFm(
//   user: IUserInfo,
//   otherUser: IUserInfo,
//   messages: IMessage[]
// ) {
//   return isUserToFeatured(user.type, otherUser.type) && isFirstSentMessage(messages, user._id);
// }

export function popupWarningPaymentUser(actions: MessengerActions) {
  setMessengerPopup(
    {
      type: EPopupMessengerType.PopupWarningPaymentUser,
      props: { onUnderstand: () => setMessengerPopup(null, actions) },
    },
    actions
  );
}

export function popupWarningGlobalFM(actions: MessengerActions) {
  setMessengerPopup(
    {
      type: EPopupMessengerType.PopupWarningGlobalFM,
      props: { onUnderstand: () => setMessengerPopup(null, actions) },
    },
    actions
  );
}

const TYPING_STATE_TIMEOUT = 2000; // 2 seconds

export const debouncedTypingStart: (
  user: IUserInfo,
  typingSetStateConfig: ITypingPublishConfig
) => void = debounce(
  (user: IUserInfo, typingSetStateConfig: ITypingPublishConfig) => {
    publishTypingState(user, typingSetStateConfig, true);
  },
  TYPING_STATE_TIMEOUT,
  {
    leading: true,
    trailing: false,
  }
);

export const debouncedTypingStop: (
  user: IUserInfo,
  typingSetStateConfig: ITypingPublishConfig
) => void = debounce(
  (user: IUserInfo, typingSetStateConfig: ITypingPublishConfig) => {
    publishTypingState(user, typingSetStateConfig, false);
  },
  TYPING_STATE_TIMEOUT,
  {
    leading: false,
    trailing: true,
  }
);
