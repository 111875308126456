export class MyEvent {
  cbs: any[];

  constructor() {
    this.cbs = [];
  }

  public addListener = (cb: any) => {
    this.cbs.push(cb);
  };

  public removeListener = (cb: any) => {
    let index = this.cbs.indexOf(cb);
    if (index === -1 && !!cb._id) {
      // not found by reference
      // try to find by _id
      index = this.cbs.findIndex((cbx) => cbx._id === cb._id);
    }
    if (index > -1) {
      this.cbs.splice(index, 1);
    }
  };

  public trigger = (data: any) => {
    this.cbs.forEach((cb) => cb(data));
  };
}
