import { IUserInfo } from "../account/account-models";
import { IDictionary } from "../models";
import { defined } from "../utils/variable-evaluation";
import { myWebsocketEvent } from "../websocket";
import { ITypingPublishConfig } from "./pubnub-models";

// Typing Start and Stop //

export function publishTypingState(
  user: IUserInfo,
  config: ITypingPublishConfig,
  isTyping: boolean
) {
  const { sender_id, chat_id } = config;

  // if (isNewWebsocketTester(user)) {
  myWebsocketEvent.trigger({
    action: isTyping ? "typing_start" : "typing_stop",
    sender_id,
    chat_id,
  });
  // } else {
  //   const pubnub = new PubNubReact({ ...PUBNUB_CONFIG, uuid: user._id });
  //   const nextTypingState: ITypingPublish = {
  //     data: {
  //       type: isTyping ? 'typing_start' : 'typing_stop',
  //       sender_id,
  //       chat_id
  //     }
  //   };
  //   const message = {
  //     message: nextTypingState,
  //     channel: recipient_id
  //   };
  //   pubnub.publish(message);
  //   if (!isProd) {
  //     console.info('pubnub sent', JSON.stringify(message));
  //   }
  // }
}

// // New Message //
//
// export function publishNewMessage(config: IMessagesReadConfig, message: string, last_message_id: string) {
//   const { sender_id, chat_id, recipient_id } = config;
//   const nextNewMessage: INewMessagePublish = {
//     data: {
//       type: 'new_message',
//       info: message,
//       sender_id,
//       recipient_id,
//       chat_id,
//       last_message_id: last_message_id
//     },
//     pn_apns: {
//       aps: {
//         type: 'new_message',
//         alert: 'Clayton Kane: Hug',
//         badge: 1,
//         sender_id,
//         recipient_id,
//         chat_id,
//         sound: 'default'
//       }
//     }
//   };
//   pubnub.publish({
//     message: nextNewMessage,
//     channel: recipient_id
//   });
// }

// // Messages Read
//
// export function publishMessagesRead(config, message_ids: string[]) {
//   const { chat_id, recipient_id } = config;
//   const nextMessagesRead: IMessagesRead = {
//     data: {
//       type: 'messages_read',
//       chat_id,
//       message_ids
//     }
//   };
//   pubnub.publish({
//     message: nextMessagesRead,
//     channel: recipient_id
//   });
// }
//
// // User Status //
//
// export function publishUserStatus(
//   config: IUserStatusConfig,
//   isOnline: boolean,
//   last_online?: string
// ) {
//   const { sender_id } = config;
//   const nextUserStatus: IUserStatus = {
//     data: {
//       type: isOnline ? 'user_online' : 'user_offline',
//       sender_id,
//       last_online
//     }
//   };
//   pubnub.publish({
//     message: nextUserStatus,
//     channel: GLOBAL_PRESENCE_CHANNEL
//   });
// }
//
// // Chat Ended and Started //
//
// export function publishChatStatus(config: IUserStatusConfig, isChat: boolean) {
//   const { chat_id, recipient_id } = config;
//   const nextUserStatus: IChatStatus = {
//     data: {
//       type: isChat ? 'chat_ended' : 'chat_started',
//       chat_id
//     }
//   };
//   pubnub.publish({
//     message: nextUserStatus,
//     channel: recipient_id
//   });
// }
//
// // Credits Top Up //
//
// export function publishCreditsTopUp(config: IMessagesReadConfig, total_credits: string) {
//   const { recipient_id } = config;
//   const nextCreditsTopUp: ICreditsTopUpPublish = {
//     data: {
//       type: 'credits_top_up',
//       total_credits
//     },
//     pn_apns: {
//       aps: {
//         type: 'credits_top_up',
//         total_credits
//       }
//     }
//   };
//   pubnub.publish({
//     message: nextCreditsTopUp,
//     channel: recipient_id
//   });
// }
//
// // Model Approved //
//
// export function publishModelApproved(config: IMessagesReadConfig) {
//   const { recipient_id } = config;
//   const nextModelApproved: IModelApprovedPublish = {
//     data: {
//       type: 'model_approved'
//     },
//     pn_apns: {
//       aps: {
//         type: 'model_approved'
//       }
//     }
//   };
//   pubnub.publish({
//     message: nextModelApproved,
//     channel: recipient_id
//   });
// }
//
// // User Flagged //
//
// export function publishUserFlagged(config: IMessagesReadConfig) {
//   const { recipient_id } = config;
//   const nextUserFlagged: IUserFlaggedPublish = {
//     data: {
//       type: 'user_flagged'
//     },
//     pn_apns: {
//       aps: {
//         type: 'user_flagged'
//       }
//     }
//   };
//   pubnub.publish({
//     message: nextUserFlagged,
//     channel: recipient_id
//   });
// }

export function getUserOnline(user: IUserInfo, usersOnline: string[]): boolean {
  return usersOnline.indexOf(user._id) !== -1 || user.is_online;
}

export function getUserLastOnline(
  user: IUserInfo,
  usersLastOnline: IDictionary<string>
): string {
  if (
    defined(usersLastOnline[user._id]) &&
    new Date(usersLastOnline[user._id]).valueOf() >
      new Date(user.last_online).valueOf()
  ) {
    return usersLastOnline[user._id];
  }
  return user.last_online;
}
