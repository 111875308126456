import { handleActions } from "redux-actions";
import { IAccountActions } from "../account/account-actions";
import { IHeaderModalActions } from "../common/header-modal/header-modal-actions";
import { IEditContentPopup } from "../common/paid-content/edit-content-popup";
import { IUploadContentPopup } from "../common/paid-content/upload-content-popup";
import {
  ERequestStatus,
  IGetRequest,
  IPostRequest,
  requestInit,
} from "../utils/async";
import { IFeedActions } from "./feed-actions";
import { IGetFeedConfig } from "./feed-helpers";
import { IFeedInfo } from "./feed-models";

export interface IFeedModel {
  feeds: null | IFeedInfo[];
  isSubscribed: boolean;
  getFeed: IGetRequest<any>;
  uploadContentConfig: IUploadContentPopup.Props | null;
  editContentConfig: IEditContentPopup.Props | null;
  nextFeedUrl: string | null;
  unreadFeeds: number;
  // deleteFeedItemById: IRequest<any>;
  // patchFeedItemById: IRequest<any>;
  // getFeedById: IGetRequest<any>;
  nextFeedUrlById: string | null;
  // deleteFmFeedItemById: IRequest<any>;
  // getFollowing: IGetRequest<any>;
  patchFeedItem: IPostRequest<any, any>;
}

const INITIAL_STATE: IFeedModel = {
  feeds: null,
  isSubscribed: false,
  getFeed: requestInit(),
  uploadContentConfig: null,
  editContentConfig: null,
  nextFeedUrl: null,
  unreadFeeds: 0,
  // deleteFeedItemById: requestInit(),
  // patchFeedItemById: requestInit(),
  // getFeedById: requestInit(),
  nextFeedUrlById: null,
  // deleteFmFeedItemById: requestInit(),
  // getFollowing: requestInit(),
  patchFeedItem: requestInit(),
};

/** Reducer */
export const feedReducer = handleActions<IFeedModel, IFeedModel>(
  {
    [IFeedActions.Type.GET__FEED__INIT]: (state, action: any): IFeedModel => {
      const { isForceLoader } = action.payload.config as IGetFeedConfig;
      const nextState = {
        getFeed: {
          status: ERequestStatus.Fetching,
        },
        // feeds: isInitialRequest ? null : state.feeds
        feeds: isForceLoader ? null : state.feeds,
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IFeedActions.Type.GET__FEED__SUCCESS]: (
      state,
      action: any
    ): IFeedModel => {
      const config: IGetFeedConfig = action.payload.config;
      const res = action.payload.res;

      let feeds: IFeedInfo[] = res.data;
      const getFeedNextUrl: string = res.next;
      const isSubscribed: boolean = res.is_subscribed;

      if (!config.isInitialRequest) {
        feeds = [...(state.feeds || []), ...(res.data || [])];
      } else {
        if (state.feeds && state.feeds.length > 0) {
          // e.g. pull down to refresh case
          // we need to persist client side feeds here

          // get current client side feeds if any
          const clientSideFeeds = state.feeds.filter(
            ({ media }) => media.isClientSide
          );

          // prepend the existing client side feeds to the new feeds
          if (clientSideFeeds.length > 0) {
            feeds = [...clientSideFeeds, ...(res.data || [])];
          }
        }
      }

      return {
        ...state,
        nextFeedUrl: getFeedNextUrl,
        isSubscribed,
        // unreadFeeds: res.unread_feeds,
        unreadFeeds: 0, // reset
        feeds,
        getFeed: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IFeedActions.Type.GET__FEED__FAILURE]: (
      state,
      action: any
    ): IFeedModel => {
      return {
        ...state,
        getFeed: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IFeedActions.Type.SET__UPLOAD_CONTENT_CONFIG]: (
      state,
      action: any
    ): IFeedModel => {
      return {
        ...state,
        uploadContentConfig: action.payload.uploadContentConfig,
      };
    },
    [IFeedActions.Type.SET__EDIT_CONTENT_CONFIG]: (
      state,
      action: any
    ): IFeedModel => {
      return {
        ...state,
        editContentConfig: action.payload.editContentConfig,
      };
    },
    [IFeedActions.Type.POST__FEED__INIT]: (state, action: any) => {
      return {
        ...state,
        feeds: [action.payload.clientSideFeedItem, ...(state.feeds || [])],
        // postFmFeed: {
        //   status: ERequestStatus.Fetching
        // }
      };
    },
    [IFeedActions.Type.POST__FEED__PROGRESS]: (
      state,
      action: any
    ): IFeedModel => {
      return {
        ...state,
        feeds: (state.feeds || []).map((feedItem) => {
          if (feedItem._id === action.payload.clientSideFeedItem._id) {
            return action.payload.clientSideFeedItem;
          }
          return feedItem;
        }),
      };
    },
    [IFeedActions.Type.POST__FEED__SUCCESS]: (
      state,
      action: any
    ): IFeedModel => {
      const { res, clientSideId } = action.payload;
      return {
        ...state,
        feeds: (state.feeds || []).map((feedItem) => {
          if (feedItem._id === clientSideId) {
            return res;
          }
          return feedItem;
        }),
      };
    },
    [IFeedActions.Type.POST__FEED__FAILURE]: (
      state,
      action: any
    ): IFeedModel => {
      return {
        ...state,
        feeds: (state.feeds || []).filter(
          (feedItem) => feedItem._id !== action.payload.clientSideFeedItem._id
        ),
      };
    },
    [IFeedActions.Type.PATCH__FEED_ITEM__INIT]: (
      state,
      action: any
    ): IFeedModel => {
      return {
        ...state,
        patchFeedItem: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IFeedActions.Type.PATCH__FEED_ITEM__SUCCESS]: (
      state,
      action: any
    ): IFeedModel => {
      const { res } = action.payload;
      return {
        ...state,
        patchFeedItem: {
          responseData: res.data,
          status: ERequestStatus.Fetched,
        },
        feeds: (state.feeds || []).map((feedItem) =>
          feedItem._id === res.data._id ? res.data : feedItem
        ),
      };
    },
    [IFeedActions.Type.PATCH__FEED_ITEM__FAILURE]: (
      state,
      action: any
    ): IFeedModel => {
      const { error } = action.payload;
      return {
        ...state,
        patchFeedItem: {
          error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IFeedActions.Type.RESET__PATCH_FEED_ITEM]: (
      state,
      action: any
    ): IFeedModel => {
      return {
        ...state,
        patchFeedItem: requestInit(),
      };
    },
    // [IFeedActions.Type.DELETE__FEED_ITEM_BY_ID__INIT]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     deleteFeedItemById: {
    //       status: ERequestStatus.Fetching
    //     }
    //   };
    // },
    // [IFeedActions.Type.DELETE__FEED_ITEM_BY_ID__SUCCESS]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     deleteFeedItemById: {
    //       responseData: action.payload,
    //       status: ERequestStatus.Fetched
    //     }
    //   };
    // },
    // [IFeedActions.Type.DELETE__FEED_ITEM_BY_ID__FAILURE]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     deleteFeedItemById: {
    //       error: action.error,
    //       status: ERequestStatus.Error
    //     }
    //   };
    // },
    [IHeaderModalActions.Type.DELETE__FEED_ITEM__SUCCESS]: (
      state,
      action: any
    ): IFeedModel => {
      const { id } = action.payload;
      return {
        ...state,
        feeds: (state.feeds || []).filter(
          (feedItem: IFeedInfo) => feedItem._id !== id
        ),
      };
    },
    // [IFeedActions.Type.PATCH__FEED_ITEM_BY_ID__INIT]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     patchFeedItemById: {
    //       status: ERequestStatus.Fetching
    //     }
    //   };
    // },
    // [IFeedActions.Type.PATCH__FEED_ITEM_BY_ID__SUCCESS]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     patchFeedItemById: {
    //       responseData: action.payload,
    //       status: ERequestStatus.Fetched
    //     }
    //   };
    // },
    // [IFeedActions.Type.PATCH__FEED_ITEM_BY_ID__FAILURE]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     patchFeedItemById: {
    //       error: action.error,
    //       status: ERequestStatus.Error
    //     }
    //   };
    // },
    // [IFeedActions.Type.GET__FEED_BY_ID__INIT]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     getFeedById: {
    //       status: ERequestStatus.Fetching
    //     }
    //   };
    // },
    // [IFeedActions.Type.GET__FEED_BY_ID__SUCCESS]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     getFeedById: {
    //       responseData: action.payload,
    //       status: ERequestStatus.Fetched
    //     }
    //   };
    // },
    // [IFeedActions.Type.GET__FEED_BY_ID__FAILURE]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     getFeedById: {
    //       error: action.error,
    //       status: ERequestStatus.Error
    //     }
    //   };
    // },
    // [IFeedActions.Type.DELETE__FM_FEED_ITEM_BY_ID__INIT]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     deleteFmFeedItemById: {
    //       status: ERequestStatus.Fetching
    //     }
    //   };
    // },
    // [IFeedActions.Type.DELETE__FM_FEED_ITEM_BY_ID__SUCCESS]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     deleteFmFeedItemById: {
    //       responseData: action.payload,
    //       status: ERequestStatus.Fetched
    //     }
    //   };
    // },
    // [IFeedActions.Type.DELETE__FM_FEED_ITEM_BY_ID__FAILURE]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     deleteFmFeedItemById: {
    //       error: action.error,
    //       status: ERequestStatus.Error
    //     }
    //   };
    // },
    // [IFeedActions.Type.GET__FOLLOWING__INIT]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     getFollowing: {
    //       status: ERequestStatus.Fetching
    //     }
    //   };
    // },
    // [IFeedActions.Type.GET__FOLLOWING__SUCCESS]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     getFollowing: {
    //       responseData: action.payload,
    //       status: ERequestStatus.Fetched
    //     }
    //   };
    // },
    // [IFeedActions.Type.GET__FOLLOWING__FAILURE]: (state, action: any): IFeedModel => {
    //   return {
    //     ...state,
    //     getFollowing: {
    //       error: action.error,
    //       status: ERequestStatus.Error
    //     }
    //   };
    // },
    [IFeedActions.Type.SET__UNREAD_FEEDS]: (state, action: any): IFeedModel => {
      return {
        ...state,
        unreadFeeds: action.payload,
      };
    },
    [IAccountActions.Type.GET__USER__SUCCESS]: (
      state,
      action: any
    ): IFeedModel => {
      return {
        ...state,
        unreadFeeds: action.payload.unread_feeds || 0,
      };
    },
  },
  INITIAL_STATE
);
