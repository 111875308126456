import { EButtonActionType } from "./common/button";
import { IFileWrapper } from "./common/uploader/uploader-helpers";

export interface IDictionary<T> {
  [key: string]: T;
}

export enum MediaSize {
  "TH" = 320,
  "ST" = 600,
  "RT" = 1080,
  "SR" = 1440,
}

export interface IMediaLockConfigType {
  unlockText?: string;
  unlockButtonText?: string;
  unlockData?: IDictionary<any>;
}

export interface IMediaType {
  public_id?: string; // cloudinary public id
  media_id?: string; // bendinary media id
  th: string; // width 320px
  st?: string; // width 600px
  rt?: string; // width 1080px
  sr?: string; // width 1440px
  op?: string; // height 320px
  video_url?: string;
  video_length?: number;
  width?: number;
  height?: number;
  approved?: boolean;
  review_status?: string;
  score?: number;
  isLocked?: boolean; // custom
  isClientSideBlur?: boolean; // custom
  caption?: string; // from feed
  lockConfig?: IMediaLockConfigType;
  isClientSide?: boolean; // client side item
  uploadProgress?: number; // client side item
  loaded?: number; // client side item
  total?: number; // client side item
  abortUpload?: () => void; // client side item
}

export const EMPTY_IMAGE = {
  public_id: "",
  op: "",
  th: "",
  st: "",
  rt: "",
};

export enum EXHRReadyState {
  "UNSENT" = 0, // Client has been created. open() not called yet.
  "OPENED" = 1, // open() has been called.
  "HEADERS_RECEIVED" = 2, // send() has been called, and headers and status are available.
  "LOADING" = 3, // Downloading; responseText holds partial data.
  "DONE" = 4, // The operation is complete.
}

export interface IPage {
  name: string;
  path: string;
  routeComponent: React.ComponentType<any>;
  pageComponent?: any;
  linkType?: EButtonActionType;
}

export enum EPaymentType {
  Subscription = "Subscription",
  PackageSelect = "PackageSelect",
  ChatTip = "ChatTip",
  ChatPurchase = "ChatPurchase",
  FeedTip = "FeedTip",
}

export enum EMediaLoadStatus {
  Loading = "loading",
  Loaded = "loaded",
  Error = "error",
}

// TODO: get rid of this and make use only of general Popup in the future
export enum EPopupSection {
  OnboardingPopup = "OnboardingPopup",
  PubnubPopup = "PubnubPopup",
  LandingPopup = "LandingPopup",
  ModelProfilePopup = "ModelProfilePopup",
  AccountPopup = "AccountPopup",
  MessengerPopup = "MessengerPopup",
  ChatPopup = "ChatPopup",
  DiscoverPopup = "DiscoverPopup",
  Popup = "Popup",
}

export interface IPopupWrapper {
  popup: IPopupConfig;
  popupSection: EPopupSection;
}

export enum EPopupAccountType {
  PopupDeleteImage = "PopupDeleteImage",
  PopupDeleteCreditCard = "PopupDeleteCreditCard",
  PopupDeleteCreditCardError = "PopupDeleteCreditCardError",
}

export enum EPopupChatType {
  PopupConfirmDeleteChats = "PopupConfirmDeleteChats",
  PopupConfirmHideChats = "PopupConfirmHideChats",
}

export enum EPopupDiscoverType {
  PopupBoost = "PopupBoost",
  PopupReferralProgram = "PopupReferralProgram",
}

export enum EPopupMessengerType {
  PopupError = "PopupError",
  PopupNotEnoughCredits = "PopupNotEnoughCredits",
  PopupMessagingDisabled = "PopupMessagingDisabled",
  PopupUploadInProgress = "PopupUploadInProgress",
  PopupWarningPaymentUser = "PopupWarningPaymentUser",
  PopupWarningGlobalFM = "PopupWarningGlobalFM",
  PopupErrorMessageOptions = "PopupErrorMessageOptions",
}

export enum EPopupOnboardingType {
  PopupEmailVerify = "PopupEmailVerify",
  PopupEmailVerifyUsingCode = "PopupEmailVerifyUsingCode",
  PopupEmailResend = "PopupEmailResend",
  PopupEmailVerified = "PopupEmailVerified",
  PopupEssentialInfo = "PopupEssentialInfo",
}

export enum EPopupPubnubType {
  PayoutCompleted = "PayoutCompleted",
  AccountTerminated = "AccountTerminated",
  AccountTermination = "AccountTermination",
}

export enum EPopupType {
  BasicClosePopup = "BasicClosePopup",
  BasicConfirmPopup = "BasicConfirmPopup",
  BasicEmptyPopup = "BasicEmptyPopup",
}

export interface IPopupConfig {
  id?: string; // optional unique id for the popup
  type:
    | EPopupDiscoverType
    | EPopupChatType
    | EPopupPubnubType
    | EPopupAccountType
    | EPopupMessengerType
    | EPopupOnboardingType
    | EPopupType;
  props?: any;
}

export enum EMessageBubbleType {
  User = "User",
  OtherUser = "OtherUser",
  System = "System",
  SystemWarning = "SystemWarning",
  Tip = "Tip",
}

export enum EPaidContentPopupType {
  Messenger = "Messenger",
  AutoMessages = "AutoMessages",
  Broadcast = "Broadcast",
}

export interface IUploadQueue {
  id: string;
  filesToUpload: IFileWrapper[];
  uploadHandler: (mediaFileWrapper: IFileWrapper, callback: () => void) => void;
  onFileComplete?: (mediaFileWrapper: IFileWrapper) => void;
  onQueueComplete?: (id: string) => void;
  description: string;
}
