import React from "react";
import { renderDefinedTrue } from "../../../../utils/react-conditional";
import styles from "./PopupContent.module.scss";

export namespace IPopupContent {
  export interface Props {
    emoj?: string;
    image?: string;
    sentence?: string;
    children?: React.ReactNode;
  }
}

export const PopupContent = React.memo(function PopupContent(
  props: IPopupContent.Props
) {
  return (
    <div className={styles.PopupContent}>
      {renderDefinedTrue(props.image, () => (
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            src={require(`./assets/${props.image}`)}
            alt=""
          />
        </div>
      ))}
      {renderDefinedTrue(props.emoj, () => (
        <div className={styles.imageWrapper}>
          <span className={styles.emoj}>{props.emoj}</span>
        </div>
      ))}
      {renderDefinedTrue(props.sentence, () => (
        <p>{props.sentence}</p>
      ))}
      <div className={styles.children}>{props.children}</div>
    </div>
  );
});
