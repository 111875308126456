import { API_ROOT } from "../../globals";
import { getRequest, patchRequest, postRequest } from "../../utils/fetch";
import { defined } from "../../utils/variable-evaluation";
import { getUser } from "../account-action-creators";
import { AccountActions } from "../account-actions";
import { EarningsActions } from "./earnings-actions";
import { IPostPayoutRequestBody, IUpdatePayoutConfig } from "./earnings-models";

export function getPayoutTotals(actions: EarningsActions) {
  actions.initGetPayoutTotals();
  getRequest(`${API_ROOT}/v1/account/totals?totals=false`).then(
    (res) => actions.successGetPayoutTotals(res),
    (err) => actions.failureGetPayoutTotals(err)
  );
}

export function getPayoutStats(actions: EarningsActions) {
  actions.initGetPayoutStats();
  getRequest(`${API_ROOT}/v1/account/stats`).then(
    (res) => actions.successGetPayoutStats(res),
    (err) => actions.failureGetPayoutStats(err)
  );
}

export interface IGetPayoutsConfig {
  isInitialRequest: boolean;
}

export function getPayouts(
  config: IGetPayoutsConfig,
  actions: EarningsActions
) {
  actions.initGetPayouts({ config });
  getRequest(`${API_ROOT}/v1/account/payouts?limit=20&offset=0`).then(
    (res) => actions.successGetPayouts({ res, config }),
    actions.failureGetPayouts
  );
}

export function getNextPayouts(
  nextUrl: string,
  config: IGetPayoutsConfig,
  actions: EarningsActions
) {
  actions.initGetPayouts({ config });
  getRequest(API_ROOT + nextUrl).then(
    (res) => actions.successGetPayouts({ res, config }),
    actions.failureGetPayouts
  );
}

export function getAccountInfo(actions: EarningsActions) {
  actions.initGetAccountInfo();
  getRequest(`${API_ROOT}/v1/account/info`).then(
    (res) => actions.successGetAccountInfo(res),
    (err) => actions.failureGetAccountInfo(err)
  );
}

export function postPayoutRequest(
  body: IPostPayoutRequestBody,
  actions: EarningsActions,
  successCallback?: (response: any) => void,
  errorCallback?: (errorResponse: any) => void
) {
  actions.initPostPayoutRequest();
  postRequest(`${API_ROOT}/v2/account/payout`, body).then(
    (res) => {
      getPayouts({ isInitialRequest: true }, actions); // reload

      successCallback && successCallback(res);
      actions.successPostPayoutRequest(res);
    },
    (err) => {
      errorCallback && errorCallback(err);
      actions.failurePostPayoutRequest(err);
    }
  );
}

export function postAntiMoneyLaundering(
  body: any,
  actions: EarningsActions,
  successCallback?: (response: any) => void,
  errorCallback?: (errorResponse: any) => void
) {
  actions.initPostAntiMoneyLaundering();
  postRequest(`${API_ROOT}/v1/account/anti-money-laundering`, body).then(
    (res) => {
      successCallback && successCallback(res);
      actions.successPostAntiMoneyLaundering(res);
    },
    (err) => {
      errorCallback && errorCallback(err);
      actions.failurePostAntiMoneyLaundering(err);
    }
  );
}

export function patchPayoutMethod(
  updatePayoutConfig: IUpdatePayoutConfig,
  verificationCode: string | null,
  actions: EarningsActions,
  accountActions: AccountActions,
  successCallback?: (res: any) => any,
  errorCallback?: (errorRes: any) => any
): Promise<any> {
  actions.initPatchPayoutMethod();
  let url = `${API_ROOT}/v2/account/defaults`;
  if (defined(verificationCode)) {
    url = `${url}?verification_code=${verificationCode}`;
  }
  const originalPromise = patchRequest(url, updatePayoutConfig);

  // we have to return the originalResponse so we can chain on both resolve and reject
  // if we would return the promise from .then it would return:
  // "A Promise for the completion of which ever callback is executed." only
  originalPromise.then(
    (res: any) => {
      successCallback && successCallback(res);
      getUser(accountActions); // reload user
      getAccountInfo(actions); // reload account info
      actions.successPatchPayoutMethod(res);
    },
    (err) => {
      errorCallback && errorCallback(err);
      actions.failurePatchPayoutMethod(err);
    }
  );

  return originalPromise;
}

export interface IGetSubscribersConfig {
  isInitialRequest: boolean;
}

export function getSubscribers(
  config: IGetSubscribersConfig,
  actions: EarningsActions
) {
  actions.initGetSubscribers({ config });
  getRequest(`${API_ROOT}/v1/subscriptions?limit=20&offset=0`).then(
    (res) => actions.successGetSubscribers({ res, config }),
    actions.failureGetSubscribers
  );
}

export function getNextSubscribers(
  nextUrl: string,
  config: IGetSubscribersConfig,
  actions: EarningsActions
) {
  actions.initGetSubscribers({ config });
  getRequest(API_ROOT + nextUrl).then(
    (res) => actions.successGetSubscribers({ res, config }),
    actions.failureGetSubscribers
  );
}

export interface IGetReferrersConfig {
  isInitialRequest: boolean;
}

export function getReferrers(
  config: IGetReferrersConfig,
  actions: EarningsActions
) {
  actions.initGetReferrers({ config });
  getRequest(`${API_ROOT}/v1/users/referrals?limit=20&offset=0`).then(
    (res) => actions.successGetReferrers({ res, config }),
    actions.failureGetReferrers
  );
}

export function getNextReferrers(
  nextUrl: string,
  config: IGetReferrersConfig,
  actions: EarningsActions
) {
  actions.initGetReferrers({ config });
  getRequest(API_ROOT + nextUrl).then(
    (res) => actions.successGetReferrers({ res, config }),
    actions.failureGetReferrers
  );
}

export interface IGetBroadcastStatsConfig {
  isInitialRequest: boolean;
}

export function getBroadcastStats(
  config: IGetBroadcastStatsConfig,
  actions: EarningsActions
) {
  actions.initGetBroadcastStats({ config });
  getRequest(`${API_ROOT}/v1/broadcast?limit=20&offset=0`).then(
    (res) => actions.successGetBroadcastStats({ res, config }),
    actions.failureGetBroadcastStats
  );
}

export function getNextBroadcastStats(
  nextUrl: string,
  config: IGetBroadcastStatsConfig,
  actions: EarningsActions
) {
  actions.initGetBroadcastStats({ config });
  getRequest(API_ROOT + nextUrl).then(
    (res) => actions.successGetBroadcastStats({ res, config }),
    actions.failureGetBroadcastStats
  );
}
