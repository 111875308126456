import { isLocalStorageSupported } from "./detectors";

export enum ELocalStorageKey {
  DeviceId = "deviceHash",
}

export function setLocalStoragePair(name: string, value: string): void {
  if (isLocalStorageSupported) {
    localStorage.setItem(name, value);
  }
}

export function getLocalStorageValue(name: string): string | null {
  if (isLocalStorageSupported) {
    return localStorage.getItem(name);
  }
  return null;
}

export function deleteLocalStoragePaid(name: string): void {
  if (isLocalStorageSupported) {
    localStorage.removeItem(name);
  }
}
