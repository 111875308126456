import { createAction } from "redux-actions";

export namespace ILandingActions {
  export enum Type {
    SET_LANDING_POPUP = "SET_LANDING_POPUP",
    SET_VIEWPORT_DIMENSIONS = "SET_VIEWPORT_DIMENSIONS",

    GET__USER_BY_ID__INIT = "GET__USER_BY_ID__INIT",
    GET__USER_BY_ID__SUCCESS = "GET__USER_BY_ID__SUCCESS",
    GET__USER_BY_ID__FAILURE = "GET__USER_BY_ID__FAILURE",

    // GET__IMAGE_BY_HASH__INIT = 'GET__IMAGE_BY_HASH__INIT',
    // GET__IMAGE_BY_HASH__SUCCESS = 'GET__IMAGE_BY_HASH__SUCCESS',
    // GET__IMAGE_BY_HASH__FAILURE = 'GET__IMAGE_BY_HASH__FAILURE',

    DELETE__IMAGE__INIT = "DELETE__IMAGE__INIT",
    DELETE__IMAGE__SUCCESS = "DELETE__IMAGE__SUCCESS",
    DELETE__IMAGE__FAILURE = "DELETE__IMAGE__FAILURE",

    POST__IMAGE_THROUGH__INIT = "POST__IMAGE_THROUGH__INIT",
    POST__IMAGE_THROUGH__SUCCESS = "POST__IMAGE_THROUGH__SUCCESS",
    POST__IMAGE_THROUGH__FAILURE = "POST__IMAGE_THROUGH__FAILURE",
    POST__IMAGE_THROUGH__RESET = "POST__IMAGE_THROUGH__RESET",

    PATCH__IMAGE_TYPE__INIT = "PATCH__IMAGE_TYPE__INIT",
    PATCH__IMAGE_TYPE__SUCCESS = "PATCH__IMAGE_TYPE__SUCCESS",
    PATCH__IMAGE_TYPE__FAILURE = "PATCH__IMAGE_TYPE__FAILURE",

    SET__UPLOADER_PROCESSING = "SET__UPLOADER_PROCESSING",
    REMOVE__UPLOADER_PROCESSING = "REMOVE__UPLOADER_PROCESSING",
  }

  export const initGetUserById = createAction(Type.GET__USER_BY_ID__INIT);
  export const successGetUserById = createAction<any>(
    Type.GET__USER_BY_ID__SUCCESS
  );
  export const failureGetUserById = createAction<any>(
    Type.GET__USER_BY_ID__FAILURE
  );

  // export const initGetImageByHash = createAction<any>(Type.GET__IMAGE_BY_HASH__INIT);
  // export const successGetImageByHash = createAction<any>(Type.GET__IMAGE_BY_HASH__SUCCESS);
  // export const failureGetImageByHash = createAction<any>(Type.GET__IMAGE_BY_HASH__FAILURE);

  // export const initPostImageThrough = createAction<any>(Type.POST__IMAGE_THROUGH__INIT);
  // export const successPostImageThrough = createAction<any>(Type.POST__IMAGE_THROUGH__SUCCESS);
  // export const failurePostImageThrough = createAction<any>(Type.POST__IMAGE_THROUGH__FAILURE);
  // export const resetPostImageThrough = createAction<any>(Type.POST__IMAGE_THROUGH__RESET);

  export const initPatchImageType = createAction<any>(
    Type.PATCH__IMAGE_TYPE__INIT
  );
  export const successPatchImageType = createAction<any>(
    Type.PATCH__IMAGE_TYPE__SUCCESS
  );
  export const failurePatchImageType = createAction<any>(
    Type.PATCH__IMAGE_TYPE__FAILURE
  );

  export const initDeleteImage = createAction<any>(Type.DELETE__IMAGE__INIT);
  export const successDeleteImage = createAction<any>(
    Type.DELETE__IMAGE__SUCCESS
  );
  export const failureDeleteImage = createAction<any>(
    Type.DELETE__IMAGE__FAILURE
  );

  export const setLandingPopup = createAction<any>(Type.SET_LANDING_POPUP);

  export const setViewportDimensions = createAction<any>(
    Type.SET_VIEWPORT_DIMENSIONS
  );

  export const setUploaderProcessing = createAction<any>(
    Type.SET__UPLOADER_PROCESSING
  );
  export const removeUploaderProcessing = createAction<any>(
    Type.REMOVE__UPLOADER_PROCESSING
  );
}

export type LandingActions = Omit<typeof ILandingActions, "Type">;
