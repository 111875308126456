import { createAction } from "redux-actions";

export namespace IMessengerActions {
  export enum Type {
    GET__FETCH_CHAT__INIT = "GET__FETCH_CHAT__INIT",
    GET__FETCH_CHAT__SUCCESS = "GET__FETCH_CHAT__SUCCESS",
    GET__FETCH_CHAT__FAILURE = "GET__FETCH_CHAT__FAILURE",
    GET__FETCH_CHAT__RESET = "GET__FETCH_CHAT__RESET",

    GET__FETCH_CHAT_RELOAD__INIT = "GET__FETCH_CHAT_RELOAD__INIT",
    GET__FETCH_CHAT_RELOAD__SUCCESS = "GET__FETCH_CHAT_RELOAD__SUCCESS",
    GET__FETCH_CHAT_RELOAD__FAILURE = "GET__FETCH_CHAT_RELOAD__FAILURE",

    POST__CREATE_CHAT__INIT = "POST__CREATE_CHAT__INIT",
    POST__CREATE_CHAT__SUCCESS = "POST__CREATE_CHAT__SUCCESS",
    POST__CREATE_CHAT__FAILURE = "POST__CREATE_CHAT__FAILURE",

    UPDATE__CHAT_BY_ID = "UPDATE__CHAT_BY_ID",

    GET__FETCH_MESSAGES__INIT = "GET__FETCH_MESSAGES__INIT",
    GET__FETCH_MESSAGES__SUCCESS = "GET__FETCH_MESSAGES__SUCCESS",
    GET__FETCH_MESSAGES__FAILURE = "GET__FETCH_MESSAGES__FAILURE",
    GET__FETCH_MESSAGES__RESET = "GET__FETCH_MESSAGES__RESET",
    GET__FETCH_NEXT_MESSAGES__INIT = "GET__FETCH_NEXT_MESSAGES__INIT",
    GET__FETCH_NEXT_MESSAGES__SUCCESS = "GET__FETCH_NEXT_MESSAGES__SUCCESS",
    GET__FETCH_NEXT_MESSAGES__FAILURE = "GET__FETCH_NEXT_MESSAGES__FAILURE",

    GET__MESSAGE_BY_ID__INIT = "GET__MESSAGE_BY_ID__INIT",
    GET__MESSAGE_BY_ID__SUCCESS = "GET__MESSAGE_BY_ID__SUCCESS",
    GET__MESSAGE_BY_ID__FAILURE = "GET__MESSAGE_BY_ID__FAILURE",

    GET__FROM_LAST_ID_MESSAGES__INIT = "GET__FROM_LAST_ID_MESSAGES__INIT",
    GET__FROM_LAST_ID_MESSAGES__SUCCESS = "GET__FROM_LAST_ID_MESSAGES__SUCCESS",
    GET__FROM_LAST_ID_MESSAGES__FAILURE = "GET__FROM_LAST_ID_MESSAGES__FAILURE",

    POST__SEND_MESSAGE__INIT = "POST__SEND_MESSAGE__INIT",
    POST__SEND_MESSAGE__SUCCESS = "POST__SEND_MESSAGE__SUCCESS",
    POST__SEND_MESSAGE__FAILURE = "POST__SEND_MESSAGE__FAILURE",

    POST__MESSAGE_WARNED__INIT = "POST__MESSAGE_WARNED__INIT",
    POST__MESSAGE_WARNED__SUCCESS = "POST__MESSAGE_WARNED__SUCCESS",
    POST__MESSAGE_WARNED__FAILURE = "POST__MESSAGE_WARNED__FAILURE",

    PATCH__BUY_PAID_CONTENT__INIT = "PATCH__BUY_PAID_CONTENT__INIT",
    PATCH__BUY_PAID_CONTENT__SUCCESS = "PATCH__BUY_PAID_CONTENT__SUCCESS",
    PATCH__BUY_PAID_CONTENT__FAILURE = "PATCH__BUY_PAID_CONTENT__FAILURE",
    PATCH__BUY_PAID_CONTENT__RESET = "PATCH__BUY_PAID_CONTENT__RESET",

    REMOVE__MESSAGE_BY_ID = "REMOVE__MESSAGE_BY_ID",
    REMOVE__CLIENT_SIDE_MESSAGE = "REMOVE__CLIENT_SIDE_MESSAGE",

    TOGGLE__HEADER = "TOGGLE__HEADER",

    ADD_CLIENT_SIDE_MESSAGE_RECEIVED = "ADD_CLIENT_SIDE_MESSAGE_RECEIVED",

    SET_CURRENT_ID = "SET_CURRENT_ID",

    // SET_FIRST_LOAD = 'SET_FIRST_LOAD',

    SET__PAID_CONTENT_CONFIG = "SET__PAID_CONTENT_CONFIG",

    SET_MESSENGER_POPUP = "SET_MESSENGER_POPUP",

    RESET__POST_SEND_MESSAGE = "RESET__POST_SEND_MESSAGE",

    UPDATE__MESSAGE_REVEAL_MEDIA = "UPDATE__MESSAGE_REVEAL_MEDIA",

    UPDATE__MESSAGE_AS_PAID = "UPDATE__MESSAGE_AS_PAID",

    UPDATE__MESSAGES_READ = "UPDATE__MESSAGES_READ",

    UPDATE__MESSAGES_DESTRUCTIBLE = "UPDATE__MESSAGES_DESTRUCTIBLE",

    UPDATE__SEND_MESSAGE_PROGRESS = "UPDATE__SEND_MESSAGE_PROGRESS",

    UPDATE__UNLOCK_BY_PAYING_USD = "UPDATE__UNLOCK_BY_PAYING_USD",
  }
  export const initGetChat = createAction<any>(Type.GET__FETCH_CHAT__INIT);
  export const successGetChat = createAction<any>(
    Type.GET__FETCH_CHAT__SUCCESS
  );
  export const failureGetChat = createAction<any>(
    Type.GET__FETCH_CHAT__FAILURE
  );
  export const resetGetChat = createAction(Type.GET__FETCH_CHAT__RESET);

  export const initGetChatReload = createAction<any>(
    Type.GET__FETCH_CHAT_RELOAD__INIT
  );
  export const successGetChatReload = createAction<any>(
    Type.GET__FETCH_CHAT_RELOAD__SUCCESS
  );
  export const failureGetChatReload = createAction<any>(
    Type.GET__FETCH_CHAT_RELOAD__FAILURE
  );

  export const initPostCreateChat = createAction<any>(
    Type.POST__CREATE_CHAT__INIT
  );
  export const successPostCreateChat = createAction<any>(
    Type.POST__CREATE_CHAT__SUCCESS
  );
  export const failurePostCreateChat = createAction<any>(
    Type.POST__CREATE_CHAT__FAILURE
  );

  export const updateChatById = createAction<any>(Type.UPDATE__CHAT_BY_ID);

  export const initGetMessages = createAction<any>(
    Type.GET__FETCH_MESSAGES__INIT
  );
  export const successGetMessages = createAction<any>(
    Type.GET__FETCH_MESSAGES__SUCCESS
  );
  export const failureGetMessages = createAction<any>(
    Type.GET__FETCH_MESSAGES__FAILURE
  );
  export const resetGetMessages = createAction(Type.GET__FETCH_MESSAGES__RESET);
  export const initGetNextMessages = createAction<any>(
    Type.GET__FETCH_NEXT_MESSAGES__INIT
  );
  export const successGetNextMessages = createAction<any>(
    Type.GET__FETCH_NEXT_MESSAGES__SUCCESS
  );
  export const failureGetNextMessages = createAction<any>(
    Type.GET__FETCH_NEXT_MESSAGES__FAILURE
  );

  export const initGetMessageById = createAction<any>(
    Type.GET__MESSAGE_BY_ID__INIT
  );
  export const successGetMessageById = createAction<any>(
    Type.GET__MESSAGE_BY_ID__SUCCESS
  );
  export const failureGetMessageById = createAction<any>(
    Type.GET__MESSAGE_BY_ID__FAILURE
  );

  export const initGetFromLastIdMessages = createAction(
    Type.GET__FROM_LAST_ID_MESSAGES__INIT
  );
  export const successGetFromLastIdMessages = createAction<any>(
    Type.GET__FROM_LAST_ID_MESSAGES__SUCCESS
  );
  export const failureGetFromLastIdMessages = createAction<any>(
    Type.GET__FROM_LAST_ID_MESSAGES__FAILURE
  );

  export const initPostSendMessage = createAction<any>(
    Type.POST__SEND_MESSAGE__INIT
  );
  export const successPostSendMessage = createAction<any>(
    Type.POST__SEND_MESSAGE__SUCCESS
  );
  export const failurePostSendMessage = createAction<any>(
    Type.POST__SEND_MESSAGE__FAILURE
  );

  export const initPostMessageWarned = createAction<any>(
    Type.POST__MESSAGE_WARNED__INIT
  );
  export const successPostMessageWarned = createAction<any>(
    Type.POST__MESSAGE_WARNED__SUCCESS
  );
  export const failurePostMessageWarned = createAction<any>(
    Type.POST__MESSAGE_WARNED__FAILURE
  );

  export const initPatchBuyPaidContent = createAction<any>(
    Type.PATCH__BUY_PAID_CONTENT__INIT
  );
  export const successPatchBuyPaidContent = createAction<any>(
    Type.PATCH__BUY_PAID_CONTENT__SUCCESS
  );
  export const failurePatchBuyPaidContent = createAction<any>(
    Type.PATCH__BUY_PAID_CONTENT__FAILURE
  );
  export const resetPatchBuyPaidContent = createAction(
    Type.PATCH__BUY_PAID_CONTENT__RESET
  );

  export const removeMessageFromId = createAction(Type.REMOVE__MESSAGE_BY_ID);
  export const removeClientSideMessage = createAction(
    Type.REMOVE__CLIENT_SIDE_MESSAGE
  );

  export const addClientSideMessageReceived = createAction<any>(
    Type.ADD_CLIENT_SIDE_MESSAGE_RECEIVED
  );

  export const toggleHeader = createAction(Type.TOGGLE__HEADER);

  // export const setCurrentId = createAction<any>(Type.SET_CURRENT_ID);

  // export const setFirstLoad = createAction<any>(Type.SET_FIRST_LOAD);

  export const setMessengerPopup = createAction<any>(Type.SET_MESSENGER_POPUP);

  export const togglePaidContent = createAction<any>(
    Type.SET__PAID_CONTENT_CONFIG
  );

  export const resetPostSendMessage = createAction(
    Type.RESET__POST_SEND_MESSAGE
  );

  export const updateMessageRevealMedia = createAction<any>(
    Type.UPDATE__MESSAGE_REVEAL_MEDIA
  );

  export const updateMessageAsPaid = createAction<any>(
    Type.UPDATE__MESSAGE_AS_PAID
  );

  export const updateMessagesRead = createAction<any>(
    Type.UPDATE__MESSAGES_READ
  );

  export const updateSendMessageProgress = createAction<any>(
    Type.UPDATE__SEND_MESSAGE_PROGRESS
  );

  export const updateMessageUnlockByPayingUsd = createAction<any>(
    Type.UPDATE__UNLOCK_BY_PAYING_USD
  );
}

export type MessengerActions = Omit<typeof IMessengerActions, "Type">;
