import { handleActions } from "redux-actions";
import { ERequestStatus, IGetRequest, requestInit } from "../utils/async";
import { IPromoActions } from "./promo-actions";

export interface IPromoModel {
  getSimpleDiscover: IGetRequest<any>;
  warnings: Record<string, boolean>;
}

const INITIAL_STATE: IPromoModel = {
  getSimpleDiscover: requestInit(),
  warnings: {},
};

/** Reducer */
export const promoReducer = handleActions<IPromoModel, IPromoModel>(
  {
    [IPromoActions.Type.GET__SIMPLE_DISCOVER__INIT]: (
      state,
      action: any
    ): IPromoModel => {
      return {
        ...state,
        getSimpleDiscover: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IPromoActions.Type.GET__SIMPLE_DISCOVER__SUCCESS]: (
      state,
      action: any
    ): IPromoModel => {
      return {
        ...state,
        getSimpleDiscover: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        warnings: action.payload,
      };
    },
    [IPromoActions.Type.GET__SIMPLE_DISCOVER__FAILURE]: (
      state,
      action: any
    ): IPromoModel => {
      return {
        ...state,
        getSimpleDiscover: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
  },
  INITIAL_STATE
);
