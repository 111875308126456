import { Location } from "history";
import React, { ComponentType } from "react";
import { Route } from "react-router";
import { EButtonActionType } from "../common/button";
import { history } from "../history";
import { IDictionary, IPage } from "../models";
import { IAccountMenuPage, IEarningsPage } from "../pages";
import { defined } from "./variable-evaluation";

export const asyncComponent = (getComponent: any) => {
  return class AsyncComponent extends React.PureComponent {
    static Component: any = null;
    state = { Component: AsyncComponent.Component };

    componentDidMount() {
      if (!this.state.Component) {
        getComponent().then(({ default: Component }: any) => {
          AsyncComponent.Component = Component;
          this.setState({ Component });
        });
      }
    }

    render() {
      const { Component } = this.state;
      if (Component) {
        return <Component {...this.props} />;
      }
      return null;
    }
  };
};

// export function pagesToAsyncRoutes(page: IPage) {
//   if (defined(page.routeComponent)) {
//     return <Route key="route" path={page.path} render={page.routeComponent} />;
//   } else {
//     return null;
//   }
// }

export function pagesToRoutes(page: IPage) {
  if (defined(page.routeComponent)) {
    return (
      <Route key="route" path={page.path} component={page.routeComponent} />
    );
  } else {
    return null;
  }
}

export function pagesToExactRoutes(page: IPage) {
  return (
    <Route exact key="route" path={page.path} component={page.routeComponent} />
  );
}

// export function redirectTrailingForwardSlash(page: IPage) {
//   return (
//     <Redirect
//       key="route"
//       exact
//       strict
//       from={`${page.path}/`}
//       to={`${removeParamPropFromPath(page.path)}`}
//     />
//   );
// }

export function toPath(name: string) {
  return name
    .replace(/-/g, "")
    .replace(/\s/g, "-")
    .replace(/[.,]/g, "")
    .toLowerCase();
}

export function Page(
  this: IPage,
  name: string,
  routeComponent: React.ComponentType<any>,
  path?: string,
  pageComponent?: React.ComponentType<any>,
  header?: string,
  subHeader?: string
) {
  this.name = name;
  this.routeComponent = routeComponent;
  this.path = path ? path : `/${toPath(name)}`;
  // this.isPath = (currentPath: string, x = this.path) => currentPath === x;
  this.pageComponent = pageComponent;
  // this.header = header;
  // this.subHeader = subHeader;
}

export function EarningsPage(
  this: IEarningsPage,
  name: string,
  component: React.ReactNode
) {
  this.name = name;
  this.path = `/account/earnings/${name
    .replace(/Earnings /, "")
    .replace(/\s/g, "-")
    .toLowerCase()}`;
  this.component = component;
}

export function AccountMenuPage(
  this: IAccountMenuPage,
  name: string,
  routeComponent: ComponentType<any>,
  path?: string,
  linkType?: EButtonActionType,
  isHighlighted?: boolean,
  isWarning?: boolean,
  onClick?: (e: Event) => void,
  isSuperHighlighted?: boolean,
  isActionRequired?: boolean
) {
  this.name = name;
  this.routeComponent = routeComponent;
  this.path = path ? path : `/${toPath(name)}`;
  this.isHighlighted = defined(isHighlighted) ? isHighlighted : false;
  this.isSuperHighlighted = defined(isSuperHighlighted)
    ? isSuperHighlighted
    : false;
  this.isActionRequired = defined(isActionRequired) ? isActionRequired : false;
  this.isWarning = defined(isWarning) ? isWarning : false;
  this.linkType = defined(linkType) ? linkType : EButtonActionType.InternalLink;
  this.onClick = onClick;
}

export function removeParamPropFromPath(path: string): string {
  if (path.indexOf("/:") > -1) {
    return path.split("/:")[0];
  } else {
    return path;
  }
}

export function searchFromPath(path: string): string {
  if (path.indexOf("?") > -1) {
    return `?${path.split("?")[1]}`;
  } else {
    return path;
  }
}

export function pathNameFromPath(path: string) {
  if (path.indexOf("?") > -1) {
    return path.split("?")[0];
  } else {
    return path;
  }
}

export function isRoute(path: string, location: Location) {
  return location.pathname.indexOf(path) > -1;
}

export function isExactRoute(path: string, location: Location) {
  return location.pathname === path || location.pathname === path + "/";
}

export function isSearch(path: string, location: Location) {
  return location.search.indexOf(path) > -1;
}

export function queryStrToDictionary(queryStr: string): IDictionary<any> {
  if (queryStr.length === 0) {
    return {};
  }

  const queryDict = {};
  const decodedQueryStr = decodeURIComponent(queryStr);
  const searchParams = new URLSearchParams(decodedQueryStr);

  searchParams.forEach((value, key) => {
    (queryDict as any)[key] = value;
  });

  return queryDict;
}

export function cleanQueryParams() {
  history.replace(window.location.pathname);
}

export function navigateBack() {
  if (defined(window) && defined(window.history.state)) {
    window.history.back();
  }
}

export function isInternalRoute(page: IPage, location: Location) {
  return (
    page.linkType === EButtonActionType.InternalLink &&
    // isRoute(page.path, location) &&
    defined(page.routeComponent)
  );
}

export function normalizeMatchUrl(matchUrl: string): string {
  if (matchUrl[matchUrl.length - 1] === "/") {
    return matchUrl.substr(0, matchUrl.length - 1);
  }
  return matchUrl;
}
