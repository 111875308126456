import { handleActions } from "redux-actions";
import { IUploadQueue } from "../models";
import { defined } from "../utils/variable-evaluation";
import { IBackgroundUploaderActions } from "./background-uploader-actions";

export interface IBackgroundUploaderModel {
  uploadQueues: Record<string, IUploadQueue>;
}

const INITIAL_STATE: IBackgroundUploaderModel = {
  uploadQueues: {},
};

/** Reducer */
export const backgroundUploaderReducer = handleActions<
  IBackgroundUploaderModel,
  IBackgroundUploaderModel
>(
  {
    [IBackgroundUploaderActions.Type.ADD__UPLOAD_QUEUE]: (
      state,
      action: any
    ): IBackgroundUploaderModel => {
      const uploadQueue: IUploadQueue = action.payload;
      if (!defined(state.uploadQueues[uploadQueue.id])) {
        return {
          ...state,
          uploadQueues: {
            ...state.uploadQueues,
            [uploadQueue.id]: uploadQueue,
          },
        };
      }
      console.warn(
        "backgroundUploaderReducer ADD__UPLOAD_QUEUE skipped because queue with same id already exists",
        uploadQueue
      );
      return state;
    },
    [IBackgroundUploaderActions.Type.UPDATE__UPLOAD_QUEUE]: (
      state,
      action: any
    ): IBackgroundUploaderModel => {
      const uploadQueue: IUploadQueue = action.payload;
      return {
        ...state,
        uploadQueues: {
          ...state.uploadQueues,
          [uploadQueue.id]: uploadQueue,
        },
      };
    },
    [IBackgroundUploaderActions.Type.REMOVE__UPLOAD_QUEUE]: (
      state,
      action: any
    ): IBackgroundUploaderModel => {
      const id: string = action.payload;
      if (defined(state.uploadQueues[id])) {
        let newUploadQueues = { ...state.uploadQueues };
        delete newUploadQueues[id];
        return {
          ...state,
          uploadQueues: newUploadQueues,
        };
      }
      return state;
    },
  },
  INITIAL_STATE
);
