import { IPopupConfig } from "../models";
import { updateBadge } from "../pwa";
import { PubnubActions } from "./pubnub-actions";

export function addTypingUser(sender_id: string, actions: PubnubActions) {
  actions.addTypingUser(sender_id);
}

export function removeTypingUser(sender_id: string, actions: PubnubActions) {
  actions.removeTypingUser(sender_id);
}

export function setUnreadMessages(
  unread_messages: number,
  actions: PubnubActions
) {
  actions.setUnreadMessages({ unread_messages });

  updateBadge(unread_messages);
}

export function setUnreadMessagesFavorites(
  unread_messages: number,
  actions: PubnubActions
) {
  actions.setUnreadMessagesFavorites({ unread_messages });
}

export function addOnlineUser(userId: string, actions: PubnubActions) {
  actions.addOnlineUser(userId);
}

export function addOnlineUsers(userIds: string[], actions: PubnubActions) {
  actions.addOnlineUsers(userIds);
}

export function removeOnlineUser(
  userId: string,
  lastOnline: string,
  actions: PubnubActions
) {
  actions.removeOnlineUser({ userId, lastOnline });
}

export function setPubnubPopup(
  config: IPopupConfig | null,
  actions: PubnubActions
) {
  actions.setPubnubPopup(config);
}

export interface IMessageAlertConfig {
  sender_id: string;
  unread_messages: number;
}

export function setMessageAlert(
  messageAlertConfig: IMessageAlertConfig | null,
  actions: PubnubActions
) {
  actions.setMessageAlert({ messageAlertConfig });
}
