import React from "react";
import styles from "./PopupHeader.module.scss";

export namespace IPopupHeader {
  export interface Props {
    children?: React.ReactNode;
  }
}

export const PopupHeader = React.memo(function PopupHeader(
  props: IPopupHeader.Props
) {
  return <h3 className={styles.PopupHeader}>{props.children}</h3>;
});
