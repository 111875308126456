import classNames from "classnames";
import React from "react";
import { renderIf } from "../../utils/react-conditional";
import { EValidationMessageType } from "../../utils/validation-configs";
import { defined } from "../../utils/variable-evaluation";
import { ETailType, ToolTip } from "../tool-tip";
import styles from "./ValidationMessage.module.scss";

export namespace IValidationMessage {
  export interface Props {
    message?: string;
    type?: EValidationMessageType;
  }
}

export const ValidationMessage = React.memo(function ValidationMessage(
  props: IValidationMessage.Props
) {
  const validationClass = classNames(styles.validationMessage, {
    [styles[props.type!]]: defined(props.type),
  });
  return (
    <div className={validationClass}>
      {renderIf(
        props.type === EValidationMessageType.Popup && defined(props.message),
        {
          ifTrue: () => (
            <div className={styles.tooltip}>
              <ToolTip tailType={ETailType.Left}>
                <span>{props.message}</span>
              </ToolTip>
            </div>
          ),
          ifFalse: () => <span className={styles.text}>{props.message}</span>,
        }
      )}
    </div>
  );
});
