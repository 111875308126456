import { EUserType, IUserInfo } from "../account/account-models";
import { API_ROOT } from "../globals";
import { defined } from "../utils/variable-evaluation";

export interface IGetFeedConfig {
  isInitialRequest: boolean;
  modelId?: string;
  isForceLoader?: boolean;
}

export function isFeedFeatureAccess(user: IUserInfo): boolean {
  if (!defined(user)) {
    return false;
  }
  return !!user.private_feed;
}

export function getFeedConfig(user: IUserInfo): IGetFeedConfig {
  let config: IGetFeedConfig = { isInitialRequest: true };
  if (user.type === EUserType.Featured) {
    config = { ...config, modelId: user._id };
  }
  return config;
}

export function feedUrl(config: IGetFeedConfig) {
  let url = `${API_ROOT}/v1/feed?limit=20&offset=0`;
  if (defined(config.modelId)) {
    url = `${url}&model_id=${config.modelId}`;
  }
  return url;
}
