import { createAction } from "redux-actions";

export namespace IHeaderModalActions {
  export enum Type {
    RESET_OPTIONS = "RESET_OPTIONS",

    PATCH__BLOCK_USER__INIT = "PATCH__BLOCK_USER__INIT",
    PATCH__BLOCK_USER__SUCCESS = "PATCH__BLOCK_USER__SUCCESS",
    PATCH__BLOCK_USER__FAILURE = "PATCH__BLOCK_USER__FAILURE",

    DELETE__BLOCK_USER__INIT = "DELETE__BLOCK_USER__INIT",
    DELETE__BLOCK_USER__SUCCESS = "DELETE__BLOCK_USER__SUCCESS",
    DELETE__BLOCK_USER__FAILURE = "DELETE__BLOCK_USER__FAILURE",

    PATCH__BLOCK_MEDIA__INIT = "PATCH__BLOCK_MEDIA__INIT",
    PATCH__BLOCK_MEDIA__SUCCESS = "PATCH__BLOCK_MEDIA__SUCCESS",
    PATCH__BLOCK_MEDIA__FAILURE = "PATCH__BLOCK_MEDIA__FAILURE",

    DELETE__BLOCK_MEDIA__INIT = "DELETE__BLOCK_MEDIA__INIT",
    DELETE__BLOCK_MEDIA__SUCCESS = "DELETE__BLOCK_MEDIA__SUCCESS",
    DELETE__BLOCK_MEDIA__FAILURE = "DELETE__BLOCK_MEDIA__FAILURE",

    POST__CHANGE_RATE__INIT = "POST__CHANGE_RATE__INIT",
    POST__CHANGE_RATE__SUCCESS = "POST__CHANGE_RATE__SUCCESS",
    POST__CHANGE_RATE__FAILURE = "POST__CHANGE_RATE__FAILURE",

    PATCH__REPORT_USER__INIT = "PATCH__REPORT_USER__INIT",
    PATCH__REPORT_USER__SUCCESS = "PATCH__REPORT_USER__SUCCESS",
    PATCH__REPORT_USER__FAILURE = "PATCH__REPORT_USER__FAILURE",

    PATCH__UPDATE_CHAT_FAVORITE__INIT = "PATCH__UPDATE_CHAT_FAVORITE__INIT",
    PATCH__UPDATE_CHAT_FAVORITE__SUCCESS = "PATCH__UPDATE_CHAT_FAVORITE__SUCCESS",
    PATCH__UPDATE_CHAT_FAVORITE__FAILURE = "PATCH__UPDATE_CHAT_FAVORITE__FAILURE",

    PATCH__UPDATE_CHAT_REMOVE_FAVORITE__INIT = "PATCH__UPDATE_CHAT_REMOVE_FAVORITE__INIT",
    PATCH__UPDATE_CHAT_REMOVE_FAVORITE__SUCCESS = "PATCH__UPDATE_CHAT_REMOVE_FAVORITE__SUCCESS",
    PATCH__UPDATE_CHAT_REMOVE_FAVORITE__FAILURE = "PATCH__UPDATE_CHAT_REMOVE_FAVORITE__FAILURE",

    PATCH__UPDATE_CHAT_HIDE__INIT = "PATCH__UPDATE_CHAT_HIDE__INIT",
    PATCH__UPDATE_CHAT_HIDE__SUCCESS = "PATCH__UPDATE_CHAT_HIDE__SUCCESS",
    PATCH__UPDATE_CHAT_HIDE__FAILURE = "PATCH__UPDATE_CHAT_HIDE__FAILURE",

    PATCH__UPDATE_CHAT_DELETE__INIT = "PATCH__UPDATE_CHAT_DELETE__INIT",
    PATCH__UPDATE_CHAT_DELETE__SUCCESS = "PATCH__UPDATE_CHAT_DELETE__SUCCESS",
    PATCH__UPDATE_CHAT_DELETE__FAILURE = "PATCH__UPDATE_CHAT_DELETE__FAILURE",

    DELETE__FEED_ITEM__INIT = "DELETE__FEED_ITEM__INIT",
    DELETE__FEED_ITEM__SUCCESS = "DELETE__FEED_ITEM__SUCCESS",
    DELETE__FEED_ITEM__FAILURE = "DELETE__FEED_ITEM__FAILURE",

    SET_OPEN_HEADER_MENU = "SET_OPEN_HEADER_MENU",
  }

  export const resetOptions = createAction(Type.RESET_OPTIONS);

  export const initPatchBlockUser = createAction(Type.PATCH__BLOCK_USER__INIT);
  export const successPatchBlockUser = createAction<any>(
    Type.PATCH__BLOCK_USER__SUCCESS
  );
  export const failurePatchBlockUser = createAction<any>(
    Type.PATCH__BLOCK_USER__FAILURE
  );

  export const initDeleteBlockUser = createAction(
    Type.DELETE__BLOCK_USER__INIT
  );
  export const successDeleteBlockUser = createAction<any>(
    Type.DELETE__BLOCK_USER__SUCCESS
  );
  export const failureDeleteBlockUser = createAction<any>(
    Type.DELETE__BLOCK_USER__FAILURE
  );

  export const initPatchBlockMedia = createAction(
    Type.PATCH__BLOCK_MEDIA__INIT
  );
  export const successPatchBlockMedia = createAction<any>(
    Type.PATCH__BLOCK_MEDIA__SUCCESS
  );
  export const failurePatchBlockMedia = createAction<any>(
    Type.PATCH__BLOCK_MEDIA__FAILURE
  );

  export const initDeleteBlockMedia = createAction(
    Type.DELETE__BLOCK_MEDIA__INIT
  );
  export const successDeleteBlockMedia = createAction<any>(
    Type.DELETE__BLOCK_MEDIA__SUCCESS
  );
  export const failureDeleteBlockMedia = createAction<any>(
    Type.DELETE__BLOCK_MEDIA__FAILURE
  );

  export const initPostChangeRate = createAction(Type.POST__CHANGE_RATE__INIT);
  export const successPostChangeRate = createAction<any>(
    Type.POST__CHANGE_RATE__SUCCESS
  );
  export const failurePostChangeRate = createAction<any>(
    Type.POST__CHANGE_RATE__FAILURE
  );

  export const initPatchReportUser = createAction(
    Type.PATCH__REPORT_USER__INIT
  );
  export const successPatchReportUser = createAction<any>(
    Type.PATCH__REPORT_USER__SUCCESS
  );
  export const failurePatchReportUser = createAction<any>(
    Type.PATCH__REPORT_USER__FAILURE
  );

  export const initPatchUpdateChatFavorite = createAction(
    Type.PATCH__UPDATE_CHAT_FAVORITE__INIT
  );
  export const successPatchUpdateChatFavorite = createAction<any>(
    Type.PATCH__UPDATE_CHAT_FAVORITE__SUCCESS
  );
  export const failurePatchUpdateChatFavorite = createAction<any>(
    Type.PATCH__UPDATE_CHAT_FAVORITE__FAILURE
  );

  export const initPatchUpdateChatRemoveFavorite = createAction(
    Type.PATCH__UPDATE_CHAT_REMOVE_FAVORITE__INIT
  );
  export const successPatchUpdateChatRemoveFavorite = createAction<any>(
    Type.PATCH__UPDATE_CHAT_REMOVE_FAVORITE__SUCCESS
  );
  export const failurePatchUpdateChatRemoveFavorite = createAction<any>(
    Type.PATCH__UPDATE_CHAT_REMOVE_FAVORITE__FAILURE
  );

  export const initPatchUpdateChatHide = createAction(
    Type.PATCH__UPDATE_CHAT_HIDE__INIT
  );
  export const successPatchUpdateChatHide = createAction<any>(
    Type.PATCH__UPDATE_CHAT_HIDE__SUCCESS
  );
  export const failurePatchUpdateChatHide = createAction<any>(
    Type.PATCH__UPDATE_CHAT_HIDE__FAILURE
  );

  export const initPatchUpdateChatDelete = createAction(
    Type.PATCH__UPDATE_CHAT_DELETE__INIT
  );
  export const successPatchUpdateChatDelete = createAction<any>(
    Type.PATCH__UPDATE_CHAT_DELETE__SUCCESS
  );
  export const failurePatchUpdateChatDelete = createAction<any>(
    Type.PATCH__UPDATE_CHAT_DELETE__FAILURE
  );

  export const initDeleteFeedItem = createAction(Type.DELETE__FEED_ITEM__INIT);
  export const successDeleteFeedItem = createAction(
    Type.DELETE__FEED_ITEM__SUCCESS
  );
  export const failureDeleteFeedItem = createAction(
    Type.DELETE__FEED_ITEM__FAILURE
  );

  export const toggleHeaderMenu = createAction<any>(Type.SET_OPEN_HEADER_MENU);
}

export type HeaderModalActions = Omit<typeof IHeaderModalActions, "Type">;
