import { IDictionary } from "../models";

// Tests //

export enum EValidationTestType {
  hasValue = "hasValue",
  isPasswordMatch = "isPasswordMatch",
  isValueMatch = "isValueMatch",
  isEmail = "isEmail",
  isPhoneNumber = "isPhoneNumber",
  isThreeChars = "isThreeChars",
  isSixChars = "isSixChars",
  isMaxThirtyChars = "isMaxThirtyChars",
  isNumbersOnly = "isNumbersOnly",
  isLetterNumberUnderscorePeriod = "isLetterNumberUnderscorePeriod",
  isExpiredCreditCard = "isExpiredCreditCard",
  isName = "isName",
}

export enum EValidationMessageType {
  Standard = "Standard",
  Success = "Success",
  Error = "Error",
  Popup = "Popup",
}

// Test Messages //
export const VALIDATION_MESSAGE: IDictionary<any> = {
  [EValidationTestType.hasValue]: {
    message: "Required field cannot be blank",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isPasswordMatch]: {
    message: "Passwords don't match",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isValueMatch]: {
    message: "Please make sure both input fields match",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isEmail]: {
    message: "Email is invalid",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isPhoneNumber]: {
    message: "Phone number is invalid",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isThreeChars]: {
    message: "Must be at least 3 characters",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isSixChars]: {
    message: "Password must be at least 6 characters",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isMaxThirtyChars]: {
    message: "Cannot exceed 30 characters",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isNumbersOnly]: {
    message: "Must only contain numbers.",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isLetterNumberUnderscorePeriod]: {
    message: "Must only contain letters, numbers, underscores or periods.",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isExpiredCreditCard]: {
    message: "Credit / debit card expired",
    type: EValidationMessageType.Error,
  },
  [EValidationTestType.isName]: {
    message: "Names should not contain numbers.",
    type: EValidationMessageType.Error,
  },
};
