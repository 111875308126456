import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from "redux";
import { accountReducer, IAccountModel } from "./account/account-reducer";
import {
  earningsReducer,
  IEarningsModel,
} from "./account/earnings/earnings-reducer";
import {
  backgroundUploaderReducer,
  IBackgroundUploaderModel,
} from "./background-uploader/background-uploader-reducer";
import { chatsReducer, IChatsModel } from "./chats/chats-reducer";
import {
  IMessengerModel,
  messengerReducer,
} from "./chats/messenger/messenger-reducer";
import {
  headerModalReducer,
  IHeaderModalModel,
} from "./common/header-modal/header-modal-reducer";
import { discoverReducer, IDiscoverModel } from "./discover/discover-reducer";
import {
  IModelProfileModel,
  modelProfileReducer,
} from "./discover/model-profile/model-profile-reducer";
import { feedReducer, IFeedModel } from "./feed/feed-reducer";
// import { offline } from 'redux-offline';
// import offlineConfig from 'redux-offline/lib/defaults';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { ILandingModel, landingReducer } from "./landing/landing-reducer";
import { IPopupModel, popupReducer } from "./popup/popup-reducer";
import { IPromoModel, promoReducer } from "./promo/promo-reducer";
import { IPubnubModel, pubnubReducer } from "./pubnub/pubnub-reducer";
import {
  IWebsocketModel,
  websocketReducer,
} from "./websocket/websocket-reducer";

export interface RootState {
  landingStore: ILandingModel;
  discoverStore: IDiscoverModel;
  promoStore: IPromoModel;
  accountStore: IAccountModel;
  earningsStore: IEarningsModel;
  feedStore: IFeedModel;
  chatsStore: IChatsModel;
  messengerStore: IMessengerModel;
  modelProfileStore: IModelProfileModel;
  headerModalStore: IHeaderModalModel;
  pubnubStore: IPubnubModel;
  popupStore: IPopupModel;
  backgroundUploaderStore: IBackgroundUploaderModel;
  websocketStore: IWebsocketModel;
}

export function configureStore(initialState?: RootState): Store<RootState> {
  // const config = { ...offlineConfig, rehydrate: false };
  const middleware = [
    // routerMiddleware(history)
    // offline(config)
  ];
  let composeEnhancer = compose;

  if (process.env.NODE_ENV !== "production") {
    // composeEnhancer = composeWithDevTools as any;
    const { createLogger } = require("redux-logger");
    const logger = createLogger({ collapsed: true });
    middleware.push(logger);
  }

  const rootReducer = (state: RootState, action: any): RootState => {
    return combineReducers<RootState>({
      landingStore: landingReducer,
      discoverStore: discoverReducer,
      promoStore: promoReducer,
      accountStore: accountReducer,
      earningsStore: earningsReducer,
      feedStore: feedReducer,
      chatsStore: chatsReducer,
      messengerStore: messengerReducer,
      modelProfileStore: modelProfileReducer,
      headerModalStore: headerModalReducer,
      pubnubStore: pubnubReducer,
      popupStore: popupReducer,
      backgroundUploaderStore: backgroundUploaderReducer,
      websocketStore: websocketReducer,
    })(state, action);
  };

  const store = createStore(
    rootReducer as any,
    initialState,
    composeEnhancer(applyMiddleware(...middleware))
  ) as Store<RootState>;

  // if (module.hot) {
  //   module.hot.accept('./reducer-root', () => {
  //     const nextReducer = require('./reducer-root');
  //     store.replaceReducer(nextReducer);
  //   });
  // }

  return store;
}
