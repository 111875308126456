/**
 * Renders the appropriate content depending if the predicate is true or false
 */
import React from "react";
import { IDictionary } from "../models";
import { defined } from "./variable-evaluation";

export function renderIf(
  condition: boolean,
  content: {
    ifTrue: () => React.ReactNode;
    ifFalse: () => React.ReactNode;
  }
): React.ReactNode {
  if (condition) {
    return content.ifTrue();
  } else {
    return content.ifFalse();
  }
}

/**
 * Renders the given content only if the condition is true
 */
export function renderIfTrue(
  condition: boolean,
  content: () => React.ReactNode
): React.ReactNode {
  if (condition) {
    return content();
  }
  return null;
}

/**
 * Renders the given content only if the condition is false
 */
export function renderIfFalse(
  condition: boolean,
  content: () => React.ReactNode
): React.ReactNode {
  if (!condition) {
    return content();
  }
  return null;
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefined<T>(
  data: T,
  content: {
    isDefined?: (data: T) => React.ReactNode;
    isNotDefined?: () => React.ReactNode;
  }
): React.ReactNode {
  if (defined(data)) {
    return content.isDefined && content.isDefined(data);
  } else {
    return content.isNotDefined && content.isNotDefined();
  }
}

export function renderDefinedTrue<T>(
  data: T,
  content: (data: T) => React.ReactNode
): React.ReactNode {
  if (defined(data)) {
    return content(data);
  } else {
    return null;
  }
}

export function renderDefinedFalse<T>(
  data: T,
  content: (data: T) => React.ReactNode
): React.ReactNode {
  if (!defined(data)) {
    return content(data);
  } else {
    return null;
  }
}

/**
 * Renders a callback from the callbacks dictionary based on a key (similar to the switch statement)
 * @param key               Key of the callback to call
 * @param callbacks         All the available callbacks
 * @param defaultCallback   Available if nothing else matches
 */
export function renderSwitch(
  key: string | number,
  callbacks: IDictionary<() => React.ReactNode>,
  defaultCallback?: () => React.ReactNode
): React.ReactNode {
  if (callbacks[key]) {
    return callbacks[key]();
  } else {
    if (defaultCallback) {
      return defaultCallback();
    } else {
      return null;
    }
  }
}
