export const ICONS_COMMON = {
  Refresh: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  Refresh2: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M24.209 15.5554C24.209 10.8257 20.3833 7 15.6536 7C11.9247 7 8.76079 9.37292 7.5824 12.6982"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7383 13.7636L24.5156 15.9589L26.7109 12.1816"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50232 16.4453C7.50232 21.175 11.3281 25.0007 16.0577 25.0007C19.7866 25.0007 22.9505 22.6278 24.1289 19.3025"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.957 18.2364L7.19587 16.041L5.00052 19.8022"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Warning: (
    <svg width="90" height="90" viewBox="0 0 90 90">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E31D09"
          d="M45,90 C20.14719,90 0,69.85281 0,45 C0,20.14719 20.14719,0 45,0 C69.85281,0 90,20.14719 90,45 C90,69.85281 69.85281,90 45,90 Z"
        />
        <path
          fill="#FFFFFF"
          d="M48.22021,17.55329 L74.56643,63.51061 C75.66514,65.42716 75.00215,67.8715 73.08561,68.97021 C72.48006,69.31735 71.79422,69.5 71.09622,69.5 L18.40378,69.5 C16.19464,69.5 14.40378,67.70914 14.40378,65.5 C14.40378,64.80201 14.58643,64.11616 14.93357,63.51061 L41.27979,17.55329 C42.3785,15.63675 44.82284,14.97377 46.73939,16.07247 C47.35574,16.42581 47.86687,16.93694 48.22021,17.55329 Z"
        />
        <path
          fill="#E31D09"
          d="M43.07959 33L46.92041 33C47.74884 33 48.42041 33.67157 48.42041 34.5 48.42041 34.52584 48.41974 34.55168 48.41841 34.57748L47.57358 50.91082C47.53234 51.70806 46.8739 52.33333 46.07558 52.33333L43.92442 52.33333C43.1261 52.33333 42.46766 51.70806 42.42642 50.91082L41.58159 34.57748C41.5388 33.75016 42.17479 33.04479 43.00211 33.002 43.02791 33.00067 43.05375 33 43.07959 33zM44 56.16667L46 56.16667C46.82843 56.16667 47.5 56.83824 47.5 57.66667L47.5 59.5C47.5 60.32843 46.82843 61 46 61L44 61C43.17157 61 42.5 60.32843 42.5 59.5L42.5 57.66667C42.5 56.83824 43.17157 56.16667 44 56.16667z"
        />
      </g>
    </svg>
  ),
  Success: (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 66C51.2254 66 66 51.2254 66 33C66 14.7746 51.2254 0 33 0C14.7746 0 0 14.7746 0 33C0 51.2254 14.7746 66 33 66Z"
        fill="#0CB549"
      />
      <rect
        x="43.9951"
        y="23.6841"
        width="3.96"
        height="26.4"
        rx="1.98"
        transform="rotate(45 43.9951 23.6841)"
        fill="white"
      />
      <rect
        x="18.6387"
        y="35.9918"
        width="3.96"
        height="13.2"
        rx="1.98"
        transform="rotate(-45 18.6387 35.9918)"
        fill="white"
      />
    </svg>
  ),
  Close: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  ),
  GetAppButton: (
    <svg width="54" height="29" viewBox="0 0 54 29" fill="none">
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H50C51.933 0.5 53.5 2.067 53.5 4V24C53.5 25.933 51.933 27.5 50 27.5H4C2.067 27.5 0.5 25.933 0.5 24V4Z"
        transform="translate(0 1)"
        stroke="#0094f4"
      />
      <path
        d="M10.1455 9.09668V7.86621H6.09863V9.15137H8.6416V9.36328C8.62793 10.8262 7.52051 11.8105 5.90723 11.8105C4.05469 11.8105 2.89258 10.375 2.89258 8.05078C2.89258 5.76758 4.04785 4.3252 5.87305 4.3252C7.21973 4.3252 8.15625 4.97461 8.53906 6.16406H10.0771C9.74219 4.16797 8.11523 2.89648 5.87305 2.89648C3.11133 2.89648 1.32715 4.91992 1.32715 8.06445C1.32715 11.25 3.09082 13.2393 5.88672 13.2393C8.4707 13.2393 10.1455 11.6191 10.1455 9.09668ZM18.5195 11.6055H13.7891V8.64551H18.2666V7.31934H13.7891V4.53027H18.5195V3.13574H12.2578V13H18.5195V11.6055ZM24.7676 13V4.53027H27.8643V3.13574H20.1328V4.53027H23.2363V13H24.7676Z"
        transform="translate(13 6)"
        fill="#0094f4"
      />
      <path
        d="M4.58008 8.2168H7.04102V7.07422H4.58008V4.48438H3.41992V7.07422H0.958984V8.2168H3.41992V10.8594H4.58008V8.2168Z"
        transform="translate(3.5)"
        fill="#0094f4"
      />
    </svg>
  ),
  CloseCircle: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g filter="url(#CloseCircle--filter)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C0 3.58887 3.58887 0 8 0C12.4111 0 16 3.58887 16 8C16 12.4111 12.4111 16 8 16C3.58887 16 0 12.4111 0 8ZM11.3477 11.3477C11.4893 11.2051 11.4893 10.9756 11.3477 10.833L8.51367 8L11.3477 5.16602C11.4893 5.02344 11.4893 4.79395 11.3477 4.65137C11.2051 4.50977 10.9756 4.50977 10.833 4.65137L8 7.48535L5.16602 4.65137C5.02344 4.50977 4.79395 4.50977 4.65137 4.65137C4.50977 4.79395 4.50977 5.02344 4.65137 5.16602L7.48535 8L4.65137 10.833C4.50977 10.9756 4.50977 11.2051 4.65137 11.3477C4.72266 11.4189 4.81543 11.4541 4.9082 11.4541C5.00195 11.4541 5.09473 11.4189 5.16602 11.3477L8 8.51367L10.833 11.3477C10.9043 11.4189 10.9971 11.4541 11.0908 11.4541C11.1836 11.4541 11.2764 11.4189 11.3477 11.3477Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="CloseCircle--filter"
          x="0"
          y="0"
          width="16"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
  ),
  Clock: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 6 12 12 16 14" />
    </svg>
  ),
  Plus: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-plus"
    >
      <line x1="12" y1="5" x2="12" y2="19" />
      <line x1="5" y1="12" x2="19" y2="12" />
    </svg>
  ),
  Minus: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-minus"
    >
      <line x1="5" y1="12" x2="19" y2="12" />
    </svg>
  ),
  ChevronLeft: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 8 8 12 12 16" />
      <line x1="16" y1="12" x2="8" y2="12" />
    </svg>
  ),
  ChevronRight: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 16 16 12 12 8" />
      <line x1="8" y1="12" x2="16" y2="12" />
    </svg>
  ),
  Caret: (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none">
      <path
        d="M1 1L6 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 1L6 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Pencil: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M0.539909 15.7546C0.29451 15.7546 0.24543 15.7055 0.24543 15.7055C0.0981911 15.411 0.490829 13.4969 0.834387 12.8098L3.19022 15.1656C2.65034 15.411 1.32519 15.7546 0.539909 15.7546Z"
        fill="currentColor"
      />
      <path
        d="M0.932515 13.2516L2.74847 15.0675C2.15951 15.2639 1.12883 15.5093 0.539877 15.5093H0.490798C0.441718 15.0675 0.687117 13.9387 0.932515 13.2516ZM0.785276 12.4172C0.392638 12.8099 -0.245399 15.5583 0.0981595 15.9019C0.147239 15.951 0.343558 16.0001 0.539877 16.0001C1.42331 16.0001 3.23926 15.5093 3.58282 15.2148L0.785276 12.4172Z"
        fill="currentColor"
      />
      <path
        d="M2.15967 11.3865L13.1045 0.44168C13.2517 0.294441 13.448 0.245361 13.6443 0.245361C13.8406 0.245361 14.037 0.343521 14.1842 0.44168L15.5584 1.81591C15.7057 1.96315 15.7548 2.15947 15.7548 2.35579C15.7548 2.55211 15.6566 2.74843 15.5584 2.89567L4.61366 13.8405L2.15967 11.3865Z"
        fill="currentColor"
      />
      <path
        d="M13.6443 0.490798C13.7915 0.490798 13.8897 0.539877 13.9878 0.638037L15.3621 2.01227C15.5584 2.20859 15.5584 2.50307 15.3621 2.69939L4.61359 13.4969L2.55224 11.4356L13.3007 0.638037C13.3989 0.539877 13.497 0.490798 13.6443 0.490798ZM13.6443 0C13.3989 0 13.1535 0.0981595 12.9571 0.294479L1.81604 11.3865L4.61359 14.184L15.7056 3.04294C16.0983 2.65031 16.0983 2.06135 15.7056 1.66871L14.3314 0.294479C14.1351 0.0981595 13.8897 0 13.6443 0Z"
        fill="currentColor"
      />
    </svg>
  ),
  Preview: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#preview-clip0)">
        <path
          d="M17.037 10.5819V15.5555C17.037 17.9999 15.7778 19.2592 13.3333 19.2592H4.44443C1.99998 19.2592 0.740723 17.9999 0.740723 15.5555V6.66659C0.740723 4.22215 1.99998 2.96289 4.44443 2.96289H9.41797"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.8148 1.18506L8.88892 11.111"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5927 0.740723H19.2593"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.2593 0.740723V7.40739"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="preview-clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  QuestionMark: (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
        fill="currentColor"
      />
      <path
        d="M7.56052 3.26614C5.56456 3.26614 4.95972 4.83872 4.95972 5.6855C4.95972 6.0484 5.20165 6.29034 5.56456 6.29034C5.92746 6.29034 6.16939 6.0484 6.16939 5.6855C6.16939 5.56453 6.29036 4.41534 7.56052 4.41534C7.80246 4.41534 8.89117 4.47582 8.89117 5.56453C8.89117 6.0484 8.10488 6.59276 7.80246 6.77421C7.68149 6.89518 7.56052 6.95566 7.50004 7.01614C7.2581 7.25808 6.71375 7.74195 6.71375 9.19356C6.71375 9.55647 6.95569 9.7984 7.31859 9.7984C7.68149 9.7984 7.92343 9.55647 7.92343 9.19356C7.92343 8.2863 8.16536 8.04437 8.34681 7.9234L8.4073 7.86292C8.4073 7.86292 8.46778 7.80243 8.52827 7.74195C9.01214 7.43953 10.1008 6.65324 10.1008 5.56453C10.1008 4.05243 8.7702 3.26614 7.56052 3.26614Z"
        fill="white"
      />
      <path
        d="M7.37913 11.7339C7.71317 11.7339 7.98397 11.4631 7.98397 11.129C7.98397 10.795 7.71317 10.5242 7.37913 10.5242C7.04509 10.5242 6.77429 10.795 6.77429 11.129C6.77429 11.4631 7.04509 11.7339 7.37913 11.7339Z"
        fill="white"
      />
    </svg>
  ),
  Info: (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path
        d="M17.5856 9.97754C17.5856 14.5339 13.8919 18.2275 9.33557 18.2275C4.77922 18.2275 1.08557 14.5339 1.08557 9.97754C1.08557 5.42119 4.77922 1.72754 9.33557 1.72754C13.8919 1.72754 17.5856 5.42119 17.5856 9.97754Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M11.3356 14.9775H7.48778V14.506C7.59331 14.4973 7.69681 14.4866 7.79828 14.4737C7.89975 14.4607 7.98702 14.4392 8.06008 14.4091C8.18996 14.3574 8.28128 14.2842 8.33405 14.1894C8.38681 14.0904 8.4132 13.9612 8.4132 13.8018V10.0421C8.4132 9.89141 8.38073 9.76005 8.31578 9.64808C8.25084 9.5318 8.16967 9.43921 8.07225 9.3703C7.99919 9.31862 7.88758 9.2691 7.7374 9.22172C7.59128 9.17435 7.45734 9.14421 7.33557 9.13129V8.65971L10.3188 8.49175L10.4102 8.58865V13.7178C10.4102 13.8686 10.4406 13.9978 10.5015 14.1054C10.5624 14.2088 10.6496 14.2863 10.7633 14.338C10.8445 14.3768 10.9337 14.4112 11.0312 14.4414C11.1286 14.4715 11.23 14.493 11.3356 14.506V14.9775ZM10.4467 6.16617C10.4467 6.49347 10.3269 6.7734 10.0875 7.00596C9.85206 7.23421 9.572 7.34834 9.24729 7.34834C8.91852 7.34834 8.6344 7.23421 8.39493 7.00596C8.15952 6.7734 8.04181 6.49347 8.04181 6.16617C8.04181 5.83887 8.15952 5.55893 8.39493 5.32638C8.6344 5.09382 8.91852 4.97754 9.24729 4.97754C9.57606 4.97754 9.85815 5.09382 10.0936 5.32638C10.329 5.55893 10.4467 5.83887 10.4467 6.16617Z"
        fill="currentColor"
      />
    </svg>
  ),
  WarningCircle: (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M7 0C3.1 0 0 3.1 0 7C0 10.9 3.1 14 7 14C10.9 14 14 10.9 14 7C14 3.1 10.9 0 7 0ZM7.9 2.7L7.7 8.4H6.3L6.1 2.7H7.9ZM7 11.3C6.5 11.3 6 10.9 6 10.4C6 9.9 6.4 9.5 7 9.5C7.6 9.5 8 9.9 8 10.4C8 10.9 7.5 11.3 7 11.3Z"
        fill="#EB5757"
      />
    </svg>
  ),
};
