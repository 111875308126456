import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IPopupEmailResend } from "../../common/popups/popup-onboarding/popup-email-resend";
import { IPopupEmailVerify } from "../../common/popups/popup-onboarding/popup-email-verify";
import {
  EPopupEmailVerifyUsingCodeType,
  IPopupEmailVerifyUsingCode,
} from "../../common/popups/popup-onboarding/popup-email-verify-using-code";
import { EPopupOnboardingType } from "../../models";
import { RootState } from "../../store";
import { omit } from "../../utils/object";
import { defined } from "../../utils/variable-evaluation";
import {
  getUser,
  postSendSignInEmail,
  resetPostSendSignInEmail,
  resetUserOnboarding,
  setOnboardingPopup,
} from "../account-action-creators";
import { AccountActions, IAccountActions } from "../account-actions";
import { IOnboardingConfig, IOnboardingInitConfig } from "../account-models";

export enum EOnboardingType {
  Init = "init",
  Complete = "complete",
}

export namespace IOnboarding {
  export interface Props extends IOnboardingConfig {
    type: EOnboardingType;
    accountActions?: AccountActions;
  }
}

@(connect(
  (state: RootState) => ({}),
  (dispatch: Dispatch): Pick<IOnboarding.Props, "accountActions"> => ({
    accountActions: bindActionCreators(omit(IAccountActions, "Type"), dispatch),
  })
) as any)
export class Onboarding extends React.PureComponent<IOnboarding.Props> {
  public componentDidMount() {
    this.handleSetPopup();
  }

  public componentDidUpdate(prevProps: IOnboarding.Props) {
    if (prevProps.type !== this.props.type) {
      this.handleSetPopup();
    }
  }

  private handleSetPopup = () => {
    const { init, complete, accountActions } = this.props;
    if (defined(init)) {
      this.setPopupEmailVerify(init!, accountActions!);
    } else if (defined(complete)) {
      if (complete!.isJustSetUserName) {
        this.setPopupEssentialInfo(accountActions!);
      } else {
        this.setPopupEmailVerified(accountActions!);
      }
    }
  };

  private setPopupEmailVerify = (
    config: IOnboardingInitConfig,
    actions: AccountActions
  ) => {
    if (config.isCodeOnboarding) {
      const props: IPopupEmailVerifyUsingCode.Props = {
        type: EPopupEmailVerifyUsingCodeType.Onboarding,
        email: config.email!,
        isAlreadyConverted: config.isAlreadyConverted,
        successCallback: () => {
          getUser(actions); // reload user
        },
        hideClose: !config.isClosable,
        onClose: () => {
          resetUserOnboarding(EOnboardingType.Init, actions);
          setOnboardingPopup(null, actions);
        },
        accountActions: actions,
      };
      setOnboardingPopup(
        {
          type: EPopupOnboardingType.PopupEmailVerifyUsingCode,
          props,
        },
        actions
      );
    } else {
      const props: IPopupEmailVerify.Props = {
        email: config.email!,
        onResend: () => {
          postSendSignInEmail(config, actions);
        },
        onResendDifferentEmail: () => {
          this.setPopupEmailResend(config, actions);
          resetPostSendSignInEmail(actions);
        },
      };
      if (config.isVerifyResend) {
        props.onClose = () => {
          resetUserOnboarding(EOnboardingType.Init, actions);
          setOnboardingPopup(null, actions);
        };
      }
      setOnboardingPopup(
        {
          type: EPopupOnboardingType.PopupEmailVerify,
          props,
        },
        actions
      );
    }
  };

  private setPopupEmailResend = (
    config: IOnboardingInitConfig,
    actions: AccountActions
  ) => {
    const props: IPopupEmailResend.Props = {
      email: config.email!,
      onResend: (newEmail: string) => {
        const newConfig: IOnboardingInitConfig = { ...config, email: newEmail };
        postSendSignInEmail(newConfig, actions);
        this.setPopupEmailVerify(newConfig, actions);
      },
    };
    if (config.isVerifyResend) {
      props.onClose = () => {
        resetUserOnboarding(EOnboardingType.Init, actions);
        setOnboardingPopup(null, actions);
      };
    }
    setOnboardingPopup(
      {
        type: EPopupOnboardingType.PopupEmailResend,
        props,
      },
      actions
    );
  };

  // Onboarding Complete Pop-up
  private setPopupEmailVerified = (actions: AccountActions) => {
    setOnboardingPopup(
      {
        type: EPopupOnboardingType.PopupEmailVerified,
        props: {
          onContinue: () => this.setPopupEssentialInfo(actions),
        },
      },
      actions
    );
  };

  private setPopupEssentialInfo = (actions: AccountActions) => {
    setOnboardingPopup(
      {
        type: EPopupOnboardingType.PopupEssentialInfo,
        props: {
          onContinue: () => {
            setOnboardingPopup(null, actions);
          },
        },
      },
      actions
    );
  };

  public render() {
    return null;
  }
}
