export function omit<T extends object, K extends keyof T>(
  target: T,
  ...omitKeys: K[]
): Omit<T, K> {
  return (Object.keys(target) as K[]).reduce(
    (res: any, key) => {
      if (!(omitKeys.indexOf(key) >= 0)) {
        res[key] = target[key];
      }
      return res;
    },
    {} as Omit<T, K>
  );
}
