import React from "react";
import { getUser } from "../../../../account/account-action-creators";
import { AccountActions } from "../../../../account/account-actions";
import {
  API_ROOT,
  DEFAULT_ERROR_MESSAGE,
  FROM_TABLET,
  UNRELIABLE_EMAIL_DOMAINS,
} from "../../../../globals";
import { BasicEmptyPopup } from "../../../../popup/basic-empty-popup";
import {
  ERequestStatus,
  IPostRequest,
  isError,
  isFetched,
  isFetching,
  requestInit,
} from "../../../../utils/async";
import { postRequest } from "../../../../utils/fetch";
import {
  formattingOverrides,
  moveCursorToEndOfInput,
} from "../../../../utils/inputs";
import { renderIf, renderIfTrue } from "../../../../utils/react-conditional";
import { censorEmail, checkEmailSuggestions } from "../../../../utils/string";
import { EValidationMessageType } from "../../../../utils/validation-configs";
import { defined, exists } from "../../../../utils/variable-evaluation";
import { Button, EButtonType } from "../../../button";
import { ICONS_COMMON } from "../../../icons/icons-common";
import { ValidationMessage } from "../../../validation-message";
import { IPopupWrapper } from "../../common/popup-wrapper";
import styles from "./PopupEmailVerifyUsingCode.module.scss";

const RESEND_EMAIL_DELAY = 30; // seconds
const AUTO_CLOSE_DELAY = 5; // seconds

export enum EPopupEmailVerifyUsingCodeType {
  Onboarding = "Onboarding",
  ActionVerify = "ActionVerify",
  // NoActionVerify = "NoActionVerify",
}

export namespace IPopupEmailVerifyUsingCode {
  export interface Props extends IPopupWrapper.Props {
    type: EPopupEmailVerifyUsingCodeType;
    email: string;
    // customTitle?: string;
    isAlreadyConverted?: boolean;
    fetchAction?: <T>(code: string) => Promise<T>;
    successCallback: () => void;
    accountActions: AccountActions;
  }
  export interface State {
    postEmailVerify: IPostRequest<any, any>;
    postEmailVerifyResend: IPostRequest<any, any>;
    stage: EStage;
    email: string;
    resendSecondsLeft: number;
    autoCloseSecondsLeft: number;
    code1: string;
    code2: string;
    code3: string;
    code4: string;
    emailSuggestion: string;
  }
}

enum EStage {
  EnterCode = "EnterCode",
  Help = "Help",
  EnterDifferentAddress = "EnterDifferentAddress",
  Done = "Done",
}

export class PopupEmailVerifyUsingCode extends React.PureComponent<
  IPopupEmailVerifyUsingCode.Props,
  IPopupEmailVerifyUsingCode.State
> {
  private resendInterval: any;
  private doneInterval: any;
  constructor(props: IPopupEmailVerifyUsingCode.Props, context?: any) {
    super(props, context);
    this.state = {
      postEmailVerify: requestInit(),
      postEmailVerifyResend: requestInit(),
      stage: EStage.EnterCode,
      email: props.email,
      resendSecondsLeft:
        !defined(props.onClose) || props.hideClose ? RESEND_EMAIL_DELAY : 0,
      autoCloseSecondsLeft: AUTO_CLOSE_DELAY,
      code1: "",
      code2: "",
      code3: "",
      code4: "",
      emailSuggestion: checkEmailSuggestions(props.email),
    };
  }

  public componentDidMount() {
    this.startResendCountdown();
    if (window.innerWidth >= FROM_TABLET) {
      // autoFocus for desktop
      const firstInputElm = document.getElementById(
        "input-code-1"
      ) as HTMLInputElement;
      firstInputElm && firstInputElm.focus(); // auto focus
      // HACK: no idea why we need this but we probably need this
      setTimeout(() => {
        firstInputElm && firstInputElm.focus(); // auto focus
      }, 300);
    }
  }

  public componentDidUpdate(
    prevProps: IPopupEmailVerifyUsingCode.Props,
    prevState: IPopupEmailVerifyUsingCode.State
  ) {
    if (
      isFetching(prevState.postEmailVerifyResend) &&
      !isFetching(this.state.postEmailVerifyResend) &&
      isFetched(this.state.postEmailVerifyResend)
    ) {
      this.setState({
        stage: EStage.EnterCode,
      });
    }
    if (
      isFetching(prevState.postEmailVerify) &&
      !isFetching(this.state.postEmailVerify) &&
      isFetched(this.state.postEmailVerify)
    ) {
      this.setState(
        {
          stage: EStage.Done,
        },
        () => {
          if (
            this.props.type === EPopupEmailVerifyUsingCodeType.Onboarding
            // || this.props.type === EPopupEmailVerifyUsingCodeType.NoActionVerify
          ) {
            this.startDoneCountdown();
          } else {
            this.props.onClose!();
          }
        }
      );
    }
    if (
      isFetching(prevState.postEmailVerify) &&
      !isFetching(this.state.postEmailVerify) &&
      isError(this.state.postEmailVerify)
    ) {
      // clear fields
      this.setState({
        code1: "",
        code2: "",
        code3: "",
        code4: "",
      });
    }
  }

  public componentWillUnmount() {
    clearInterval(this.resendInterval);
    clearInterval(this.doneInterval);
  }

  private handleChange = (e: any) => {
    const value = e.currentTarget.value;
    this.setState({
      email: value,
      emailSuggestion: checkEmailSuggestions(value),
    });
  };

  private handleVerify = () => {
    const { code1, code2, code3, code4 } = this.state;
    const code = `${code1}${code2}${code3}${code4}`;

    if (
      this.props.type === EPopupEmailVerifyUsingCodeType.Onboarding
      // || this.props.type === EPopupEmailVerifyUsingCodeType.NoActionVerify
    ) {
      this.setState({
        postEmailVerify: {
          status: ERequestStatus.Fetching,
        },
      });
      postRequest(`${API_ROOT}/v1/users/verify-email`, { code }).then(
        (res) => {
          this.setState({
            postEmailVerify: {
              responseData: res,
              status: ERequestStatus.Fetched,
            },
          });
          this.props.successCallback();
        },
        (errorRes) => {
          this.setState({
            postEmailVerify: {
              error: errorRes,
              status: ERequestStatus.Error,
            },
          });
        }
      );
    } else if (
      this.props.type === EPopupEmailVerifyUsingCodeType.ActionVerify
    ) {
      this.setState({
        postEmailVerify: {
          status: ERequestStatus.Fetching,
        },
      });
      this.props.fetchAction!(code).then(
        (res) => {
          this.setState({
            postEmailVerify: {
              responseData: res,
              status: ERequestStatus.Fetched,
            },
          });
          this.props.successCallback();
        },
        (errorRes) => {
          this.setState({
            postEmailVerify: {
              error: errorRes,
              status: ERequestStatus.Error,
            },
          });
        }
      );
    }
  };

  private handleResendEmail = (newOrExistingEmail: string) => {
    if (
      this.props.type === EPopupEmailVerifyUsingCodeType.Onboarding
      // || this.props.type === EPopupEmailVerifyUsingCodeType.NoActionVerify
    ) {
      this.setState({
        postEmailVerifyResend: {
          status: ERequestStatus.Fetching,
        },
      });
      postRequest(`${API_ROOT}/v1/users/change-verify-email`, {
        email: newOrExistingEmail,
      }).then(
        (res) => {
          this.setState({
            postEmailVerifyResend: {
              responseData: res,
              status: ERequestStatus.Fetched,
            },
            resendSecondsLeft: RESEND_EMAIL_DELAY, // reset
          });

          getUser(this.props.accountActions); // reload user
        },
        (errorRes) => {
          this.setState({
            postEmailVerifyResend: {
              error: errorRes,
              status: ERequestStatus.Error,
            },
          });
        }
      );
    } else if (
      this.props.type === EPopupEmailVerifyUsingCodeType.ActionVerify
    ) {
      this.setState({
        postEmailVerifyResend: {
          status: ERequestStatus.Fetching,
        },
      });
      postRequest(`${API_ROOT}/v1/users/send-verification-code`, {}).then(
        (res) => {
          this.setState({
            postEmailVerifyResend: {
              responseData: res,
              status: ERequestStatus.Fetched,
            },
            resendSecondsLeft: RESEND_EMAIL_DELAY, // reset
          });
        },
        (errorRes) => {
          this.setState({
            postEmailVerifyResend: {
              error: errorRes,
              status: ERequestStatus.Error,
            },
          });
        }
      );
    }
  };

  private startDoneCountdown = () => {
    this.doneInterval = setInterval(() => {
      this.setState(
        {
          autoCloseSecondsLeft: this.state.autoCloseSecondsLeft - 1,
        },
        () => {
          if (this.state.autoCloseSecondsLeft <= 0) {
            clearInterval(this.doneInterval);
            this.props.onClose!();
          }
        }
      );
    }, 1000);
  };

  private startResendCountdown = () => {
    this.resendInterval = setInterval(() => {
      this.setState({
        resendSecondsLeft: this.state.resendSecondsLeft - 1,
      });
    }, 1000);
  };

  private renderEnterCode = () => {
    return (
      <>
        <div className={styles.content}>
          {renderIfTrue(
            this.props.type === EPopupEmailVerifyUsingCodeType.ActionVerify,
            () => (
              <>
                <p>
                  Please complete verification to change your payout
                  information.
                </p>
                <br />
              </>
            )
          )}
          <p>Enter the 4-digit code sent to</p>
          <p>
            <b>
              {this.props.type === EPopupEmailVerifyUsingCodeType.ActionVerify
                ? censorEmail(this.state.email)
                : this.state.email}
            </b>
          </p>
          <br />
          <p>(It might be in the Spam folder)</p>
          <br />
          <p>
            <b>Enter code</b>
          </p>
          <form
            // autoComplete="off"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();

              this.handleVerify();
            }}
          >
            <div className={styles.codeWrapper}>
              <span className={styles.codeBox}>
                <input
                  {...formattingOverrides()}
                  name="code-1"
                  id="input-code-1"
                  // required
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                  value={this.state.code1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ code1: e.target.value });
                  }}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const clipboardText = e.clipboardData.getData("Text");
                    if (
                      /^[0-9][0-9][0-9][0-9]$/.test(clipboardText) &&
                      clipboardText.length === 4
                    ) {
                      this.setState(
                        {
                          code1: clipboardText[0],
                          code2: clipboardText[1],
                          code3: clipboardText[2],
                          code4: clipboardText[3],
                        },
                        () => {
                          // auto submit
                          this.handleVerify();
                        }
                      );
                      document.getElementById("input-code-4")!.focus();
                    }
                  }}
                  maxLength={1}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    const input = e.target;
                    moveCursorToEndOfInput(input);

                    // Work around Chrome's little problem
                    window.setTimeout(function () {
                      moveCursorToEndOfInput(input);
                    }, 1);
                  }}
                  onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    const inputElm = e.target as HTMLInputElement;
                    if (e.key.match(/^[\d\w]$/i) && inputElm.value.length > 0) {
                      document.getElementById("input-code-2")!.focus();
                    }
                  }}
                />
              </span>
              <span className={styles.codeBox}>
                <input
                  {...formattingOverrides()}
                  name="code-2"
                  id="input-code-2"
                  // required
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                  value={this.state.code2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ code2: e.target.value });
                  }}
                  maxLength={1}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    const input = e.target;
                    moveCursorToEndOfInput(input);

                    // Work around Chrome's little problem
                    window.setTimeout(function () {
                      moveCursorToEndOfInput(input);
                    }, 1);
                  }}
                  onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    const inputElm = e.target as HTMLInputElement;
                    if (e.which === 8) {
                      // backspace
                      document.getElementById("input-code-1")!.focus();
                    } else if (
                      e.key.match(/^[\d\w]$/i) &&
                      inputElm.value.length > 0
                    ) {
                      document.getElementById("input-code-3")!.focus();
                    }
                  }}
                />
              </span>
              <span className={styles.codeBox}>
                <input
                  {...formattingOverrides()}
                  name="code-3"
                  id="input-code-3"
                  // required
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                  value={this.state.code3}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ code3: e.target.value });
                  }}
                  maxLength={1}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    const input = e.target;
                    moveCursorToEndOfInput(input);

                    // Work around Chrome's little problem
                    window.setTimeout(function () {
                      moveCursorToEndOfInput(input);
                    }, 1);
                  }}
                  onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    const inputElm = e.target as HTMLInputElement;
                    if (e.which === 8) {
                      // backspace
                      document.getElementById("input-code-2")!.focus();
                    } else if (
                      e.key.match(/^[\d\w]$/i) &&
                      inputElm.value.length > 0
                    ) {
                      document.getElementById("input-code-4")!.focus();
                    }
                  }}
                />
              </span>
              <span className={styles.codeBox}>
                <input
                  {...formattingOverrides()}
                  name="code-4"
                  id="input-code-4"
                  // required
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                  value={this.state.code4}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ code4: e.target.value });
                  }}
                  maxLength={1}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    const input = e.target;
                    moveCursorToEndOfInput(input);

                    // Work around Chrome's little problem
                    window.setTimeout(function () {
                      moveCursorToEndOfInput(input);
                    }, 1);
                  }}
                  onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.which === 8) {
                      // backspace
                      document.getElementById("input-code-3")!.focus();
                    }
                  }}
                />
              </span>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                type="submit"
                buttonType={EButtonType.BlueWide}
                isLoading={isFetching(this.state.postEmailVerify)}
                isDisabled={
                  !exists(this.state.code1) ||
                  !exists(this.state.code2) ||
                  !exists(this.state.code3) ||
                  !exists(this.state.code4)
                }
              >
                {this.props.type === EPopupEmailVerifyUsingCodeType.ActionVerify
                  ? "Verify"
                  : "Verify My Account"}
              </Button>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                type="button"
                buttonType={EButtonType.BlueLink}
                onClick={() => this.setState({ stage: EStage.Help })}
              >
                I can’t see the email in my inbox
              </Button>
            </div>
          </form>
        </div>
        <div className={styles.validationWrapper}>
          {renderIfTrue(isFetched(this.state.postEmailVerifyResend), () => (
            <ValidationMessage
              type={EValidationMessageType.Success}
              message="Email sent successfully!"
            />
          ))}
          {renderIfTrue(isFetched(this.state.postEmailVerify), () => (
            <ValidationMessage
              type={EValidationMessageType.Success}
              message="Email verified successfully!"
            />
          ))}
          {renderIfTrue(isError(this.state.postEmailVerify), () => (
            <ValidationMessage
              type={EValidationMessageType.Error}
              message="Wrong code. Please try again."
            />
          ))}
        </div>
      </>
    );
  };

  private renderEmailSuggestion = () => {
    return (
      <div className={styles.suggestion}>
        <p>Did you mean&nbsp;</p>
        <Button
          type="button"
          buttonType={EButtonType.BlueLink}
          onClick={() => {
            this.setState(
              {
                email: this.state.emailSuggestion,
                emailSuggestion: "", // reset
              },
              () => {
                this.handleResendEmail(this.state.email);
              }
            );
          }}
        >
          {this.state.emailSuggestion}
        </Button>
        <p>?</p>
      </div>
    );
  };

  private renderHelp = () => {
    return (
      <>
        <div className={styles.content}>
          {renderIf(
            this.props.type === EPopupEmailVerifyUsingCodeType.ActionVerify,
            {
              ifTrue: () => (
                <p>
                  If you can’t find it please check your junk/spam folder or
                  resending.
                </p>
              ),
              ifFalse: () => (
                <>
                  <p>
                    If you can’t find it check your junk/spam folder or resend
                    to a different address.
                  </p>

                  {UNRELIABLE_EMAIL_DOMAINS.some((domain) =>
                    this.state.email.includes(domain)
                  ) && (
                    <>
                      <hr />
                      <p>
                        <i>
                          <b>Please note:</b> While we do accept Yahoo, Outlook,
                          Hotmail, and iCloud addresses, delivery to these
                          providers may be less reliable. For the best
                          experience, we recommend using a Gmail address or an
                          address from a different provider.
                        </i>
                      </p>
                    </>
                  )}
                </>
              ),
            }
          )}
          {renderIfTrue(exists(this.state.emailSuggestion), () => (
            <div className={styles.suggestionWrapper}>
              <p>
                You might also have input an incorrect email address (
                <b>{this.state.email}</b>)
              </p>

              {this.renderEmailSuggestion()}

              <hr />
            </div>
          ))}
          {renderIfTrue(
            this.props.type === EPopupEmailVerifyUsingCodeType.Onboarding,
            // || this.props.type === EPopupEmailVerifyUsingCodeType.NoActionVerify,
            () => (
              <>
                <div className={styles.buttonWrapper}>
                  <Button
                    type="button"
                    buttonType={EButtonType.BlueLink}
                    onClick={() =>
                      this.setState({ stage: EStage.EnterDifferentAddress })
                    }
                  >
                    Send to a different address
                  </Button>
                </div>
                <div className={styles.separator}>OR</div>
              </>
            )
          )}
          <div className={styles.buttonWrapper}>
            <Button
              type="button"
              buttonType={EButtonType.BlueWide}
              isLoading={isFetching(this.state.postEmailVerifyResend)}
              isDisabled={!this.state.email || this.state.resendSecondsLeft > 0}
              onClick={() => this.handleResendEmail(this.state.email)}
            >
              {this.state.resendSecondsLeft > 0
                ? `Resend verification (${this.state.resendSecondsLeft})`
                : "Resend verification"}
            </Button>
          </div>
        </div>
        <div className={styles.validationWrapper}>
          {/* {renderIfTrue(isFetched(this.state.postEmailVerifyResend), () => (
            <ValidationMessage
              type={EValidationMessageType.Success}
              message="Email sent successfully!"
            />
          ))} */}
          {renderIfTrue(isError(this.state.postEmailVerifyResend), () => (
            <ValidationMessage
              type={EValidationMessageType.Error}
              message={
                (this.state.postEmailVerifyResend.error &&
                  this.state.postEmailVerifyResend.error.message) ||
                DEFAULT_ERROR_MESSAGE
              }
            />
          ))}
        </div>
      </>
    );
  };

  private renderEnterDifferentAddress = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();

          this.handleResendEmail(this.state.email);
        }}
      >
        <div className={styles.content}>
          <p>
            If you can’t find it check your junk/spam folder or resend to a
            different address.
          </p>
          <div className={styles.fieldWrapper}>
            <label htmlFor="email">Send to a different address</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="email@example.com"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </div>
          {renderIfTrue(
            exists(this.state.emailSuggestion),
            this.renderEmailSuggestion
          )}
          <div className={styles.buttonWrapper}>
            <Button
              type="submit"
              buttonType={EButtonType.BlueWide}
              isLoading={isFetching(this.state.postEmailVerifyResend)}
              isDisabled={!this.state.email || this.state.resendSecondsLeft > 0}
            >
              {this.state.resendSecondsLeft > 0
                ? `Submit (${this.state.resendSecondsLeft})`
                : "Submit"}
            </Button>
          </div>
        </div>
        <div className={styles.validationWrapper}>
          {/* {renderIfTrue(isFetched(this.state.postEmailVerifyResend), () => (
            <ValidationMessage
              type={EValidationMessageType.Success}
              message="Email sent successfully!"
            />
          ))} */}
          {renderIfTrue(isError(this.state.postEmailVerifyResend), () => (
            <ValidationMessage
              type={EValidationMessageType.Error}
              message={
                (this.state.postEmailVerifyResend.error &&
                  this.state.postEmailVerifyResend.error.message) ||
                DEFAULT_ERROR_MESSAGE
              }
            />
          ))}
        </div>
      </form>
    );
  };

  private renderDone = () => {
    return (
      <div className={styles.content}>
        <div>{ICONS_COMMON.Success}</div>
        <p style={{ marginTop: 25 }}>
          Your email has been successfully verified.
        </p>
        <div className={styles.buttonWrapper}>
          <Button
            type="button"
            buttonType={EButtonType.BlueWide}
            onClick={() => this.props.onClose!()}
          >
            Continue ({this.state.autoCloseSecondsLeft})
          </Button>
        </div>
      </div>
    );
  };

  private getTitle = () => {
    if (this.state.stage === EStage.Done) {
      return "Email Verified";
    }
    if (isFetched(this.state.postEmailVerifyResend)) {
      return "CHECK YOUR EMAIL";
    }
    if (
      this.state.stage === EStage.Help ||
      this.state.stage === EStage.EnterDifferentAddress
    ) {
      return "Can’t see verification e-mail";
    }
    if (this.props.isAlreadyConverted) {
      return "Secure your Alua account";
    }
    if (this.props.type === EPopupEmailVerifyUsingCodeType.ActionVerify) {
      return "Verification required";
    }
    // if (
    //   this.props.type === EPopupEmailVerifyUsingCodeType.NoActionVerify
    // ) {
    //   return this.props.customTitle || "Verify your email";
    // }
    return "Please verify your email";
  };

  private renderStageContent = (stage: EStage) => {
    switch (stage) {
      case EStage.EnterCode:
        return this.renderEnterCode();
      case EStage.Help:
        return this.renderHelp();
      case EStage.EnterDifferentAddress:
        return this.renderEnterDifferentAddress();
      case EStage.Done:
        return this.renderDone();
    }
  };

  public render() {
    const core = (
      <div
        id="popup-email-verify-using-code"
        className={styles.PopupEmailVerifyUsingCode}
      >
        <h3>{this.getTitle()}</h3>
        {this.renderStageContent(this.state.stage)}
      </div>
    );

    if (this.props.type === EPopupEmailVerifyUsingCodeType.Onboarding) {
      return <BasicEmptyPopup>{core}</BasicEmptyPopup>;
    }

    return core;
  }
}
