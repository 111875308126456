import { createAction } from "redux-actions";

export namespace IPubnubActions {
  export enum Type {
    ADD_TYPING_USER = "ADD_TYPING_USER",
    REMOVE_TYPING_USER = "REMOVE_TYPING_USER",

    ADD_ONLINE_USER = "ADD_ONLINE_USER",
    ADD_ONLINE_USERS = "ADD_ONLINE_USERS",
    REMOVE_ONLINE_USER = "REMOVE_ONLINE_USER",

    SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES",
    SET_UNREAD_MESSAGES_FAVORITES = "SET_UNREAD_MESSAGES_FAVORITES",

    SET_PUBNUB_POPUP = "SET_PUBNUB_POPUP",

    SET_MESSAGE_ALERT = "SET_MESSAGE_ALERT",
  }

  export const addTypingUser = createAction<string>(Type.ADD_TYPING_USER);
  export const removeTypingUser = createAction<string>(Type.REMOVE_TYPING_USER);

  export const addOnlineUser = createAction<string>(Type.ADD_ONLINE_USER);
  export const addOnlineUsers = createAction<string[]>(Type.ADD_ONLINE_USERS);
  export const removeOnlineUser = createAction<any>(Type.REMOVE_ONLINE_USER);

  export const setUnreadMessages = createAction<any>(Type.SET_UNREAD_MESSAGES);
  export const setUnreadMessagesFavorites = createAction<any>(
    Type.SET_UNREAD_MESSAGES_FAVORITES
  );

  export const setPubnubPopup = createAction<any>(Type.SET_PUBNUB_POPUP);

  export const setMessageAlert = createAction<any>(Type.SET_MESSAGE_ALERT);
}

export type PubnubActions = Omit<typeof IPubnubActions, "Type">;
