import { createAction } from "redux-actions";

export namespace IChatsActions {
  export enum Type {
    FILTERS__SET = "FILTERS__SET",

    GET__FETCH_CHATS__INIT = "GET__FETCH_CHATS__INIT",
    GET__FETCH_CHATS__SUCCESS = "GET__FETCH_CHATS__SUCCESS",
    GET__FETCH_CHATS__FAILURE = "GET__FETCH_CHATS__FAILURE",

    GET__FETCH_CHAT_TOTALS__INIT = "GET__FETCH_CHAT_TOTALS__INIT",
    GET__FETCH_CHAT_TOTALS__SUCCESS = "GET__FETCH_CHAT_TOTALS__SUCCESS",
    GET__FETCH_CHAT_TOTALS__FAILURE = "GET__FETCH_CHAT_TOTALS__FAILURE",

    ADD__CHAT_TO_CHATS_LIST = "ADD__CHAT_TO_CHATS_LIST",
    REMOVE__CHAT_FROM_CHATS_LIST = "REMOVE__CHAT_FROM_CHATS_LIST",

    SET__CHAT_POPUP = "SET__CHAT_POPUP",

    SET__CHAT_EDITING = "SET__CHAT_EDITING",
    TOGGLE__CHAT_SELECTION = "TOGGLE__CHAT_SELECTION",
    SET__ALL_CHATS_SELECTION = "SET__ALL_CHATS_SELECTION",
    TOGGLE__ALL_CHATS_SELECTION = "TOGGLE__ALL_CHATS_SELECTION",

    PATCH__UPDATE_CHATS__INIT = "PATCH__UPDATE_CHATS__INIT",
    PATCH__UPDATE_CHATS__SUCCESS = "PATCH__UPDATE_CHATS__SUCCESS",
    PATCH__UPDATE_CHATS__FAILURE = "PATCH__UPDATE_CHATS__FAILURE",

    SET__BROADCAST_STAGE = "SET__BROADCAST_STAGE",

    POST__BROADCAST_MESSAGE__INIT = "POST__BROADCAST_MESSAGE__INIT",
    POST__BROADCAST_MESSAGE__UPDATE_PROGRESS = "POST__BROADCAST_MESSAGE__UPDATE_PROGRESS",
    POST__BROADCAST_MESSAGE__SUCCESS = "POST__BROADCAST_MESSAGE__SUCCESS",
    POST__BROADCAST_MESSAGE__FAILURE = "POST__BROADCAST_MESSAGE__FAILURE",

    DELETE__BROADCAST_MESSAGE__INIT = "DELETE__BROADCAST_MESSAGE__INIT",
    DELETE__BROADCAST_MESSAGE__SUCCESS = "DELETE__BROADCAST_MESSAGE__SUCCESS",

    SET__BROADCAST_MESSAGE_MEDIA = "SET__BROADCAST_MESSAGE_MEDIA",

    POST__BROADCAST__INIT = "POST__BROADCAST__INIT",
    POST__BROADCAST__SUCCESS = "POST__BROADCAST__SUCCESS",
    POST__BROADCAST__FAILURE = "POST__BROADCAST__FAILURE",
  }
  export const setFilters = createAction<any>(Type.FILTERS__SET);

  export const addChatToChatList = createAction<any>(
    Type.ADD__CHAT_TO_CHATS_LIST
  );
  export const removeChatFromChatList = createAction<any>(
    Type.REMOVE__CHAT_FROM_CHATS_LIST
  );

  export const setChatPopup = createAction<any>(Type.SET__CHAT_POPUP);

  export const initGetChats = createAction(Type.GET__FETCH_CHATS__INIT);
  export const successGetChats = createAction<any>(
    Type.GET__FETCH_CHATS__SUCCESS
  );
  export const failureGetChats = createAction<any>(
    Type.GET__FETCH_CHATS__FAILURE
  );

  export const initGetChatTotals = createAction(
    Type.GET__FETCH_CHAT_TOTALS__INIT
  );
  export const successGetChatTotals = createAction<any>(
    Type.GET__FETCH_CHAT_TOTALS__SUCCESS
  );
  export const failureGetChatTotals = createAction<any>(
    Type.GET__FETCH_CHAT_TOTALS__FAILURE
  );

  export const setChatEditing = createAction<any>(Type.SET__CHAT_EDITING);
  export const toggleChatSelection = createAction(Type.TOGGLE__CHAT_SELECTION);
  export const setAllChatsSelection = createAction<any>(
    Type.SET__ALL_CHATS_SELECTION
  );
  export const toggleAllChatsSelection = createAction(
    Type.TOGGLE__ALL_CHATS_SELECTION
  );

  export const initPatchUpdateChats = createAction(
    Type.PATCH__UPDATE_CHATS__INIT
  );
  export const successPatchUpdateChats = createAction<any>(
    Type.PATCH__UPDATE_CHATS__SUCCESS
  );
  export const failurePatchUpdateChats = createAction<any>(
    Type.PATCH__UPDATE_CHATS__FAILURE
  );

  export const setBroadcastStage = createAction<any>(Type.SET__BROADCAST_STAGE);

  export const initPostBroadcastMessage = createAction(
    Type.POST__BROADCAST_MESSAGE__INIT
  );
  export const updatePostBroadcastMessageProgress = createAction(
    Type.POST__BROADCAST_MESSAGE__UPDATE_PROGRESS
  );
  export const successPostBroadcastMessage = createAction<any>(
    Type.POST__BROADCAST_MESSAGE__SUCCESS
  );
  export const failurePostBroadcastMessage = createAction<any>(
    Type.POST__BROADCAST_MESSAGE__FAILURE
  );

  export const initDeleteBroadcastMessage = createAction(
    Type.DELETE__BROADCAST_MESSAGE__INIT
  );
  export const successDeleteBroadcastMessage = createAction<any>(
    Type.DELETE__BROADCAST_MESSAGE__SUCCESS
  );

  export const setBroadcastMessageMedia = createAction<any>(
    Type.SET__BROADCAST_MESSAGE_MEDIA
  );

  export const initPostBroadcast = createAction(Type.POST__BROADCAST__INIT);
  export const successPostBroadcast = createAction<any>(
    Type.POST__BROADCAST__SUCCESS
  );
  export const failurePostBroadcast = createAction<any>(
    Type.POST__BROADCAST__FAILURE
  );
}

export type ChatsActions = Omit<typeof IChatsActions, "Type">;
