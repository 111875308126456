import { handleActions } from "redux-actions";
import { IPaidContentPopup } from "../../common/paid-content/paid-content-popup";
import { IPopupConfig } from "../../models";
import {
  removeDuplicates,
  removeInArrayBy,
  replaceInArrayBy,
  sortByCreatedAt,
} from "../../utils/array";
import {
  ERequestStatus,
  IGetRequest,
  IPostRequest,
  isFetching,
  requestInit,
} from "../../utils/async";
import { defined, isArray } from "../../utils/variable-evaluation";
import { IChatInfo } from "../chats-models";
import { IMessengerActions } from "./messenger-actions";
import { nextMessagesWithErrorMessage } from "./messenger-helpers";
import {
  CHAT_ACCOUNT_NO_LONGER_EXISTS_ERROR_MESSAGE,
  CHAT_BLOCKED_COUNTRY_ERROR_MESSAGE,
  CHAT_BLOCKED_ERROR_MESSAGE,
  CHAT_CLOSED_ERROR_MESSAGE,
  CHAT_FROZEN_ERROR_MESSAGE_MODEL,
  CHAT_FROZEN_ERROR_MESSAGE_USER,
  IDestructibleMessage,
  IGetFromLastIdMessagesConfig,
  IMessageInfo,
  IUpdateMessagesReadConfig,
  NO_CREDITS_ERROR_MESSAGE,
} from "./messenger-models";
// import { isSessionStorageSupport } from '../../utils/detectors';

export interface IMessengerModel {
  getChat: IGetRequest<any>;
  postCreateChat: IPostRequest<any, any>;
  chat: IChatInfo | null;
  getMessages: IGetRequest<any>;
  messages: IMessageInfo[] | null;
  nextMessagesUrl: string | null;
  getNextMessages: IGetRequest<any>;
  getMessageById: IGetRequest<any>;
  isHeaderMenuOpen: boolean;
  // blockedChats: string[];
  // frozenChats: string[];
  // noCreditsChats: string[];
  isForceBlocked: boolean;
  isForceFrozen: boolean;
  isForceNoCredits: boolean;
  getFromLastIdMessages: IGetRequest<any>;
  patchBuyPaidContent: IPostRequest<any, any>;
  postSendMessage: IPostRequest<any, any>;
  paidContentConfig: Partial<IPaidContentPopup.Props> | null;
  messengerPopup: IPopupConfig | null;
}

const INITIAL_STATE: IMessengerModel = {
  getChat: requestInit(),
  postCreateChat: requestInit(),
  chat: null,
  getMessages: requestInit(),
  messages: null,
  nextMessagesUrl: null,
  getNextMessages: requestInit(),
  getMessageById: requestInit(),
  isHeaderMenuOpen: false,
  // blockedChats: [],
  // frozenChats: [],
  // noCreditsChats: [],
  isForceBlocked: false,
  isForceFrozen: false,
  isForceNoCredits: false,
  getFromLastIdMessages: requestInit(),
  patchBuyPaidContent: requestInit(),
  postSendMessage: requestInit(),
  paidContentConfig: null,
  messengerPopup: null,
};

// let clientSideMessages: IClientSideMessage[] = isSessionStorageSupport ? Object.keys(sessionStorage).reduce((obj, key) => {
//   if (key.startsWith('client-side-message-')) {
//     obj.push(JSON.parse(sessionStorage.getItem(key)));
//   }
//   return obj;
// }, []) : [];
const clientSideMessages: IMessageInfo[] = [];

/** Reducer */
export const messengerReducer = handleActions<IMessengerModel, IMessengerModel>(
  {
    [IMessengerActions.Type.GET__FETCH_CHAT__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      // abort previous request if any
      if (isFetching(state.getChat) && state.getChat.abortController) {
        state.getChat.abortController.abort();
      }

      const abortController = action.payload.abortController as AbortController;

      return {
        ...state,
        getChat: {
          status: ERequestStatus.Fetching,
          abortController,
        },
        chat: action.payload.cachedChat || null, // optimization
        messages: null,
      };
    },
    [IMessengerActions.Type.GET__FETCH_CHAT__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      const chat: IChatInfo = action.payload;

      const activeOtherUserId = window.location.pathname.split("/")[2];
      if (chat.other_user._id !== activeOtherUserId) {
        // don't do anything if active chat is already changed
        return state;
      }

      // reconsider local blocks
      // const isBlockedChat = state.blockedChats.indexOf(chat._id) !== -1;
      // const isFrozenChat = state.frozenChats.indexOf(chat._id) !== -1;
      // const isNoCreditsChat = state.noCreditsChats.indexOf(chat._id) !== -1;

      return {
        ...state,
        getChat: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        chat,
        // blockedChats: isBlockedChat ? state.blockedChats.filter((chatId) => chatId !== chat._id) : state.blockedChats,
        isForceBlocked: false,
        // frozenChats: isFrozenChat ? state.frozenChats.filter((chatId) => chatId !== chat._id) : state.frozenChats,
        isForceFrozen: false,
        // noCreditsChats: isNoCreditsChat
        //   ? state.noCreditsChats.filter((chatId) => chatId !== chat._id)
        //   : state.noCreditsChats,
        isForceNoCredits: false,
      };
    },
    [IMessengerActions.Type.GET__FETCH_CHAT__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      if (action.payload && action.payload.name === "AbortError") {
        // abort happens if there is another fetch
        // therefore we can continue with status as Fetching here
        return state;
      }

      return {
        ...state,
        getChat: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
        chat: null,
        messages: null,
      };
    },
    [IMessengerActions.Type.GET__FETCH_CHAT__RESET]: (
      state,
      action: any
    ): IMessengerModel => {
      if (state.getChat.abortController) {
        state.getChat.abortController.abort();
      }
      return {
        ...state,
        getChat: requestInit(),
        chat: null,
        messages: null,
      };
    },
    [IMessengerActions.Type.GET__FETCH_CHAT_RELOAD__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      return state;
    },
    [IMessengerActions.Type.GET__FETCH_CHAT_RELOAD__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      const chat: IChatInfo = action.payload;

      // reconsider local blocks
      // const isBlockedChat = state.blockedChats.indexOf(chat._id) !== -1;
      // const isFrozenChat = state.frozenChats.indexOf(chat._id) !== -1;
      // const isNoCreditsChat = state.noCreditsChats.indexOf(chat._id) !== -1;

      return {
        ...state,
        // getChat: {
        //   responseData: action.payload,
        //   status: ERequestStatus.Fetched
        // },
        chat,
        // blockedChats: isBlockedChat ? state.blockedChats.filter((chatId) => chatId !== chat._id) : state.blockedChats,
        // isForceBlocked: false,
        // frozenChats: isFrozenChat ? state.frozenChats.filter((chatId) => chatId !== chat._id) : state.frozenChats,
        // isForceFrozen: false,
        // noCreditsChats: isNoCreditsChat
        //   ? state.noCreditsChats.filter((chatId) => chatId !== chat._id)
        //   : state.noCreditsChats,
        // isForceNoCredits: false,
      };
    },
    [IMessengerActions.Type.GET__FETCH_CHAT_RELOAD__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        // getChat: {
        //   error: action.error,
        //   status: ERequestStatus.Error
        // }
      };
    },
    [IMessengerActions.Type.POST__CREATE_CHAT__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        postCreateChat: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IMessengerActions.Type.POST__CREATE_CHAT__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        postCreateChat: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        chat: action.payload,
      };
    },
    [IMessengerActions.Type.POST__CREATE_CHAT__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        postCreateChat: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IMessengerActions.Type.UPDATE__CHAT_BY_ID]: (
      state,
      action: any
    ): IMessengerModel => {
      const { chat_id, newValues } = action.payload;

      if (!state.chat || chat_id !== state.chat._id) {
        return state;
      }

      return {
        ...state,
        chat: { ...state.chat, ...newValues },
      };
    },
    [IMessengerActions.Type.GET__FETCH_MESSAGES__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      // abort previous request if any
      if (isFetching(state.getMessages) && state.getMessages.abortController) {
        state.getMessages.abortController.abort();
      }

      // const chatId = action.payload.chatId as string;
      // const otherUserId = action.payload.otherUserId as string;
      const abortController = action.payload.abortController as AbortController;

      return {
        ...state,
        getMessages: {
          status: ERequestStatus.Fetching,
          abortController,
        },
        messages: null,
      };
    },
    [IMessengerActions.Type.GET__FETCH_MESSAGES__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      // const chatId = action.payload.chatId as string;
      const otherUserId = action.payload.otherUserId as string;
      const res = action.payload.res;

      const activeOtherUserId = window.location.pathname.split("/")[2];
      if (otherUserId !== activeOtherUserId) {
        // don't do anything if active chat is already changed
        return state;
      }

      const newMessages = res.data;

      let messages = newMessages || [];
      const currentChatClientSideMessages = clientSideMessages.filter(
        (m) => state.chat && m.chat_id === state.chat._id
      );

      // add client side messages if any
      messages = [...messages, ...currentChatClientSideMessages];
      messages = messages.sort(sortByCreatedAt);

      return {
        ...state,
        getMessages: {
          responseData: res.data,
          status: ERequestStatus.Fetched,
        },
        nextMessagesUrl: res.data.length > 0 ? res.next : "",
        messages,
      };
    },
    [IMessengerActions.Type.GET__FETCH_MESSAGES__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      if (action.payload && action.payload.name === "AbortError") {
        // abort happens if there is another fetch
        // therefore we can continue with status as Fetching here
        return state;
      }

      return {
        ...state,
        getMessages: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IMessengerActions.Type.GET__FETCH_MESSAGES__RESET]: (
      state,
      action: any
    ): IMessengerModel => {
      if (state.getMessages.abortController) {
        state.getMessages.abortController.abort();
      }
      return {
        ...state,
        getMessages: requestInit(),
        messages: null,
      };
    },
    [IMessengerActions.Type.GET__FETCH_NEXT_MESSAGES__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        getNextMessages: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IMessengerActions.Type.GET__FETCH_NEXT_MESSAGES__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      const newMessages = action.payload.data;

      let messages = [...newMessages, ...(state.messages || [])];
      messages = removeDuplicates(messages, "_id", true);
      messages = messages.sort(sortByCreatedAt);

      return {
        ...state,
        getNextMessages: {
          responseData: action.payload.data,
          status: ERequestStatus.Fetched,
        },
        nextMessagesUrl: action.payload.next,
        messages,
      };
    },
    [IMessengerActions.Type.GET__FETCH_NEXT_MESSAGES__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        getNextMessages: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IMessengerActions.Type.UPDATE__MESSAGES_READ]: (
      state,
      action: any
    ): IMessengerModel => {
      const config: IUpdateMessagesReadConfig = action.payload.config;
      const { timestampString, messageIds, destructibles } = config;
      let messageList = state.messages;
      if (messageList) {
        messageList.forEach((message) => {
          messageIds.forEach((id: string) => {
            if (id === message._id) {
              messageList = replaceInArrayBy("_id", messageList!, id, {
                ...message,
                is_read: true,
                read_at: timestampString,
              });
            }
          });
          if (
            defined(destructibles) &&
            isArray(destructibles) &&
            destructibles.length > 0
          ) {
            destructibles.forEach((destructible: IDestructibleMessage) => {
              if (destructible.message_id === message._id) {
                messageList = replaceInArrayBy(
                  "_id",
                  messageList!,
                  destructible.message_id,
                  {
                    ...message,
                    destruct_at: destructible.destruct_at,
                  }
                );
              }
            });
          }
        });
      }
      return { ...state, messages: messageList };
    },
    [IMessengerActions.Type.UPDATE__MESSAGE_REVEAL_MEDIA]: (
      state,
      action: any
    ): IMessengerModel => {
      let messageList = state.messages;
      if (messageList) {
        messageList = state.messages!.map((message) =>
          message._id === action.payload.messageId
            ? {
                ...message,
                isRevealed: true,
              }
            : message
        );
      }
      return {
        ...state,
        messages: messageList,
      };
    },
    [IMessengerActions.Type.UPDATE__MESSAGE_AS_PAID]: (
      state,
      action: any
    ): IMessengerModel => {
      let messageList = state.messages;
      if (messageList) {
        messageList = state.messages!.map((message) =>
          message._id === action.payload.messageId
            ? {
                ...message,
                is_paid: true,
                destruct_at: action.payload.destructAt,
              }
            : message
        );
      }
      return {
        ...state,
        messages: messageList,
      };
    },
    [IMessengerActions.Type.REMOVE__MESSAGE_BY_ID]: (
      state,
      action: any
    ): IMessengerModel => {
      const messages = removeInArrayBy(
        "_id",
        state.messages || [],
        action.payload.messageId
      );
      return {
        ...state,
        messages,
      };
    },
    [IMessengerActions.Type.REMOVE__CLIENT_SIDE_MESSAGE]: (
      state,
      action: any
    ): IMessengerModel => {
      const messages = removeInArrayBy(
        "__clientSideSendId",
        state.messages || [],
        action.payload.__clientSideSendId
      );

      // remove from global clientSideMessages too
      const index = clientSideMessages.findIndex(
        (m) => m.__clientSideSendId === action.payload.__clientSideSendId
      );
      if (index !== -1) {
        clientSideMessages.splice(index, 1);
      }
      // sessionStorage.removeItem(`client-side-message-${action.payload.__clientSideSendId}`);

      return {
        ...state,
        messages,
      };
    },
    [IMessengerActions.Type.RESET__POST_SEND_MESSAGE]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        postSendMessage: requestInit(),
      };
    },
    [IMessengerActions.Type.SET_MESSENGER_POPUP]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        messengerPopup: action.payload,
      };
    },
    // [IMessengerActions.Type.SET_CURRENT_ID]: (state, action: any) => {
    //   const nextCurrentId = action.payload.id;
    //   return {
    //     ...state,
    //     isFirstLoad: nextCurrentId !== state.currentId,
    //     currentId: nextCurrentId
    //   };
    // },
    // [IMessengerActions.Type.SET_FIRST_LOAD]: (state, action: any) => {
    //   return {
    //     ...state,
    //     isFirstLoad: action.payload.isFirstLoad
    //   };
    // },
    [IMessengerActions.Type.TOGGLE__HEADER]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        isHeaderMenuOpen: !state.isHeaderMenuOpen,
      };
    },
    [IMessengerActions.Type.SET__PAID_CONTENT_CONFIG]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        paidContentConfig: action.payload.paidContentConfig,
      };
    },
    [IMessengerActions.Type.GET__MESSAGE_BY_ID__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        getMessageById: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IMessengerActions.Type.GET__MESSAGE_BY_ID__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      const updatedMessage: IMessageInfo = action.payload;

      return {
        ...state,
        getMessageById: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        messages: (state.messages || []).map((message) =>
          message._id === updatedMessage._id ? updatedMessage : message
        ),
      };
    },
    [IMessengerActions.Type.GET__MESSAGE_BY_ID__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        getMessageById: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IMessengerActions.Type.GET__FROM_LAST_ID_MESSAGES__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        getFromLastIdMessages: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IMessengerActions.Type.GET__FROM_LAST_ID_MESSAGES__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      const {
        res,
        config,
      }: { res: any; config: IGetFromLastIdMessagesConfig } = action.payload;
      const messagesFromLastId = res.data;

      if (!state.chat || config.chat_id !== state.chat._id) {
        return state;
      }

      let messages = [
        ...(state.messages || []).filter(
          (message) => !defined(message.clientSideReceiveId)
        ),
        ...messagesFromLastId,
      ];
      messages = removeDuplicates(messages, "_id", true);
      messages = messages.sort(sortByCreatedAt);

      return {
        ...state,
        getFromLastIdMessages: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        messages,
      };
    },
    [IMessengerActions.Type.GET__FROM_LAST_ID_MESSAGES__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      // const { err, config }: { err: any, config: IGetFromLastIdMessagesConfig } = action.payload;

      // const messages = nextMessagesWithErrorMessage(
      //   state.messages || [],
      //   { ...config, error: err },
      //   'clientSideReceiveId'
      // );

      return {
        ...state,
        getFromLastIdMessages: {
          error: action.error,
          status: ERequestStatus.Error,
        },
        messages: state.messages,
      };
    },
    [IMessengerActions.Type.ADD_CLIENT_SIDE_MESSAGE_RECEIVED]: (
      state,
      action: any
    ): IMessengerModel => {
      const clientSideMessage: IMessageInfo = action.payload.clientSideMessage;

      if (
        !state.chat ||
        clientSideMessage.sender_id !== state.chat.other_user._id
      ) {
        return state;
      }

      let messages = state.messages || [];

      messages = [...messages, clientSideMessage];
      messages = removeDuplicates(messages, "_id", true);
      messages = messages.sort(sortByCreatedAt);

      return {
        ...state,
        messages,
      };
    },
    [IMessengerActions.Type.POST__SEND_MESSAGE__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      const newMessage: IMessageInfo = { ...action.payload.clientSideMessage }; // this can be both media or text
      delete newMessage.error; // remove error on init (resend)

      const index = clientSideMessages.findIndex(
        (m) => m.__clientSideSendId === newMessage.__clientSideSendId
      );
      if (index !== -1) {
        // resend?
        clientSideMessages.splice(index, 1, newMessage); // replace
      } else {
        clientSideMessages.push(newMessage);
      }
      // sessionStorage.setItem(`client-side-message-${newMessage.__clientSideSendId}`, JSON.stringify(newMessage));

      if (
        !state.chat ||
        newMessage.chat_id !== state.chat._id ||
        state.getMessages.status === ERequestStatus.Fetching
      ) {
        return state;
      }

      let messages = state.messages || [];

      let isAlreadyInTheList = false; // for resend it will be already there
      messages = messages.map((message: IMessageInfo) => {
        if (message.__clientSideSendId === newMessage.__clientSideSendId) {
          isAlreadyInTheList = true;
          return newMessage;
        }
        return message;
      });
      if (!isAlreadyInTheList) {
        messages = [...messages, newMessage];
      }
      messages.sort(sortByCreatedAt);

      return {
        ...state,
        postSendMessage: {
          __clientSideSendId: action.payload.__clientSideSendId,
          status: ERequestStatus.Fetching,
        } as any,
        messages,
      };
    },
    [IMessengerActions.Type.UPDATE__SEND_MESSAGE_PROGRESS]: (
      state,
      action: any
    ): IMessengerModel => {
      // update in global clientSideMessages too with new progress value
      const oldMessage = clientSideMessages.find(
        (m) => m.__clientSideSendId === action.payload.__clientSideSendId
      );
      let newMessage: IMessageInfo | null = null;
      if (oldMessage !== null) {
        newMessage = {
          ...oldMessage!,
          uploadProgress: action.payload.progress,
          loaded: action.payload.loaded,
          total: action.payload.total,
        };
        const index = clientSideMessages.findIndex(
          (m) => m.__clientSideSendId === action.payload.__clientSideSendId
        );
        clientSideMessages.splice(index, 1, newMessage);
      }

      if (
        !state.chat ||
        (newMessage && newMessage.chat_id !== state.chat._id) ||
        state.getMessages.status === ERequestStatus.Fetching
      ) {
        return state;
      }

      let messages = state.messages || [];

      let isAlreadyInTheList = false;
      messages = messages.map((message) => {
        if (
          newMessage &&
          message.__clientSideSendId === newMessage.__clientSideSendId
        ) {
          isAlreadyInTheList = true;
          return newMessage;
        }
        return message;
      });
      if (!isAlreadyInTheList) {
        // to handle cases like exit chat and return back
        // if this message is from this chat but is not in the list
        // we have to add it
        messages = [...messages!, newMessage!];
      }
      messages.sort(sortByCreatedAt);

      return {
        ...state,
        messages,
      };
    },
    [IMessengerActions.Type.POST__SEND_MESSAGE__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      // remove from global clientSideMessages too
      const index = clientSideMessages.findIndex(
        (m) => m.__clientSideSendId === action.payload.__clientSideSendId
      );
      if (index !== -1) {
        clientSideMessages.splice(index, 1);
      }
      // sessionStorage.removeItem(`client-side-message-${__clientSideSendId}`);

      const newMessage: IMessageInfo = action.payload.message;

      if (!state.chat || newMessage.chat_id !== state.chat._id) {
        return state;
      }

      let messages = state.messages || [];
      messages = replaceInArrayBy(
        "__clientSideSendId",
        messages,
        action.payload.__clientSideSendId,
        newMessage
      );
      messages = removeDuplicates(messages, "_id");

      return {
        ...state,
        postSendMessage: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        messages,
      };
    },
    [IMessengerActions.Type.POST__SEND_MESSAGE__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      const newMessage: IMessageInfo = action.payload;

      // let blockedChats = state.blockedChats;
      let isForceBlocked = state.isForceBlocked;
      if (
        action.payload.error.message === CHAT_CLOSED_ERROR_MESSAGE ||
        action.payload.error.message === CHAT_BLOCKED_ERROR_MESSAGE ||
        action.payload.error.message === CHAT_BLOCKED_COUNTRY_ERROR_MESSAGE ||
        action.payload.error.message ===
          CHAT_ACCOUNT_NO_LONGER_EXISTS_ERROR_MESSAGE
      ) {
        // blockedChats = addToArrayIfNotThere(
        //   blockedChats,
        //   action.payload.chat_id
        // );
        isForceBlocked = true;
      }

      // let frozenChats = state.frozenChats;
      let isForceFrozen = state.isForceFrozen;
      if (
        action.payload.error.message === CHAT_FROZEN_ERROR_MESSAGE_MODEL ||
        action.payload.error.message === CHAT_FROZEN_ERROR_MESSAGE_USER
      ) {
        // frozenChats = addToArrayIfNotThere(frozenChats, action.payload.chat_id);
        isForceFrozen = true;
      }

      // let noCreditsChats = state.noCreditsChats;
      let isForceNoCredits = state.isForceNoCredits;
      if (action.payload.error.message === NO_CREDITS_ERROR_MESSAGE) {
        // noCreditsChats = addToArrayIfNotThere(
        //   noCreditsChats,
        //   action.payload.chat_id
        // );
        isForceNoCredits = true;
      }

      let messages = state.messages || [];
      if (state.chat && state.chat.other_user._id === newMessage.recipient_id) {
        messages = nextMessagesWithErrorMessage(
          messages,
          action.payload,
          "__clientSideSendId"
        );
      }

      return {
        ...state,
        postSendMessage: {
          error: action.payload.error,
          status: ERequestStatus.Error,
        },
        // blockedChats,
        isForceBlocked,
        // frozenChats,
        isForceFrozen,
        // noCreditsChats,
        isForceNoCredits,
        messages,
      };
    },
    [IMessengerActions.Type.PATCH__BUY_PAID_CONTENT__INIT]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        patchBuyPaidContent: {
          requestData: action.payload.messageId,
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IMessengerActions.Type.PATCH__BUY_PAID_CONTENT__SUCCESS]: (
      state,
      action: any
    ): IMessengerModel => {
      const updatedMessage: IMessageInfo = action.payload;
      return {
        ...state,
        patchBuyPaidContent: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        messages: (state.messages || []).map((message) =>
          message._id === updatedMessage._id ? updatedMessage : message
        ),
      };
    },
    [IMessengerActions.Type.PATCH__BUY_PAID_CONTENT__FAILURE]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        patchBuyPaidContent: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IMessengerActions.Type.PATCH__BUY_PAID_CONTENT__RESET]: (
      state,
      action: any
    ): IMessengerModel => {
      return {
        ...state,
        patchBuyPaidContent: requestInit(),
      };
    },
    [IMessengerActions.Type.UPDATE__UNLOCK_BY_PAYING_USD]: (
      state,
      action: any
    ): IMessengerModel => {
      const unlockedMessage = action.payload.message;
      return {
        ...state,
        messages: (state.messages || []).map((message) =>
          message._id === unlockedMessage._id ? unlockedMessage : message
        ),
      };
    },
  },
  INITIAL_STATE
);
