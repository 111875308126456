import React from "react";

export function retry<T extends React.ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  interval = 1000
) {
  return new Promise<{ default: T }>((resolve, reject) => {
    factory()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          // if (retriesLeft === 1) {
          //   // reject('maximum retries exceeded');
          //   reject(error);
          //   return;
          // }

          // Passing on "reject" is the important part
          retry(factory, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function lazyWithRetry<T extends React.ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): React.LazyExoticComponent<T> {
  return React.lazy(() => retry(factory));
}
