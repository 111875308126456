import { handleActions } from "redux-actions";
import { IPopupConfig } from "../models";
import {
  ERequestStatus,
  IGetRequest,
  IPostRequest,
  IRequest,
  requestInit,
} from "../utils/async";
import { IViewportDimensions } from "./landing-action-creators";
import { ILandingActions } from "./landing-actions";

export interface ILandingModel {
  landingPopup: IPopupConfig | null;
  getUserById: IGetRequest<any>;
  getImageByHash: IGetRequest<any>;
  postImageThrough: IPostRequest<any, any>;
  patchImageType: IPostRequest<any, any>;
  deleteImage: IRequest<any>;
  viewportDimensions: IViewportDimensions;
  deleteRequestStatus: ERequestStatus;
  uploaderProcessingList: string[];
}

export const landingStoreInitialState: ILandingModel = {
  landingPopup: null,
  getUserById: requestInit(),
  getImageByHash: requestInit(),
  postImageThrough: requestInit(),
  deleteImage: requestInit(),
  patchImageType: requestInit(),
  viewportDimensions: {
    width: 0,
    height: 0,
    heightScreen: 0,
    isWidthResizing: false,
    isHeightResizing: false,
  },
  deleteRequestStatus: ERequestStatus.None,
  uploaderProcessingList: [],
};

/** Reducer */
export const landingReducer = handleActions<ILandingModel, ILandingModel>(
  {
    [ILandingActions.Type.SET_VIEWPORT_DIMENSIONS]: (state, action: any) => {
      return {
        ...state,
        viewportDimensions: { ...state.viewportDimensions, ...action.payload },
      };
    },
    [ILandingActions.Type.GET__USER_BY_ID__INIT]: (state, action) => {
      return {
        ...state,
        getUserById: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [ILandingActions.Type.GET__USER_BY_ID__SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getUserById: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [ILandingActions.Type.GET__USER_BY_ID__FAILURE]: (state, action) => {
      return {
        ...state,
        getUserById: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [ILandingActions.Type.SET_LANDING_POPUP]: (state, action: any) => {
      return {
        ...state,
        landingPopup: action.payload,
      };
    },
    [ILandingActions.Type.PATCH__IMAGE_TYPE__INIT]: (state, action: any) => {
      return {
        ...state,
        patchImageType: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [ILandingActions.Type.PATCH__IMAGE_TYPE__SUCCESS]: (state, action: any) => {
      return {
        ...state,
        patchImageType: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [ILandingActions.Type.PATCH__IMAGE_TYPE__FAILURE]: (state, action: any) => {
      return {
        ...state,
        patchImageType: {
          error: action.payload.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [ILandingActions.Type.POST__IMAGE_THROUGH__RESET]: (state, action: any) => {
      return {
        ...state,
        postImageThrough: {
          status: ERequestStatus.None,
        },
        [`${action.payload.mediaType}RequestStatus`]: ERequestStatus.None,
      };
    },
    [ILandingActions.Type.DELETE__IMAGE__INIT]: (state, action: any) => {
      return {
        ...state,
        deleteImage: {
          status: ERequestStatus.Fetching,
        },
        deleteRequestStatus: ERequestStatus.Fetching,
      };
    },
    [ILandingActions.Type.DELETE__IMAGE__SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteImage: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        deleteRequestStatus: ERequestStatus.Fetched,
      };
    },
    [ILandingActions.Type.DELETE__IMAGE__FAILURE]: (state, action: any) => {
      return {
        ...state,
        deleteImage: {
          error: action.payload.error,
          status: ERequestStatus.Error,
        },
        deleteRequestStatus: ERequestStatus.Error,
      };
    },
    [ILandingActions.Type.SET__UPLOADER_PROCESSING]: (state, action: any) => {
      return {
        ...state,
        uploaderProcessingList: [
          ...state.uploaderProcessingList,
          action.payload.uploaderId,
        ],
      };
    },
    [ILandingActions.Type.REMOVE__UPLOADER_PROCESSING]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        uploaderProcessingList: state.uploaderProcessingList.filter(
          (uploaderId) => uploaderId !== action.payload.uploaderId
        ),
      };
    },
  },
  landingStoreInitialState
);
