import { DEFAULT_EXPIRY_DAYS_COOKIE, DOMAIN_COOKIE } from "../globals";
import { defined } from "./variable-evaluation";

export function setCookie(
  name: string,
  value: string,
  days = DEFAULT_EXPIRY_DAYS_COOKIE
): void {
  const date = new Date();
  const age = days * 24 * 60 * 60;
  date.setTime(date.getTime() + age * 1000);
  const expires = `; Expires=${date.toUTCString()}; Max-Age=${age}`;
  if (defined(DOMAIN_COOKIE)) {
    document.cookie = `${name}=${
      value + expires
    }; Path=/; Domain=${DOMAIN_COOKIE}`;
  } else {
    document.cookie = `${name}=${value + expires}; Path=/;`;
  }
}

export function getCookie(name: string): string | null {
  /*
   * after adding a cookie like
   * >> document.cookie = "bar=test; expires=Thu, 14 Jun 2018 13:05:38 GMT; path=/"
   * the value of document.cookie may look like
   * >> "foo=value; bar=test"
   */
  const nameEQ = name + "="; // what we are looking for
  const ca = document.cookie.split(";"); // split into separate cookies
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]; // the current cookie
    while (c.charAt(0) === " ") c = c.substring(1, c.length); // remove leading spaces
    if (c.indexOf(nameEQ) === 0) {
      // if it is the searched cookie
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null; // no cookie found
}

export function deleteCookie(name: string): void {
  setCookie(name, "", -1);
}
