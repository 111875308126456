import { IGif } from "@giphy/js-types";
import { History } from "history";
import { getUser, updateUser } from "../../account/account-action-creators";
import { AccountActions } from "../../account/account-actions";
import { IUserInfo, IVaultMedia } from "../../account/account-models";
import { IPaidContentPopup } from "../../common/paid-content/paid-content-popup";
import {
  createMediaHash,
  getMediaAvailable,
  getMediaByHash,
} from "../../common/uploader/media-uploader-helpers";
import {
  EFileType,
  getFileSizeInMegaBytes,
  IFileWrapper,
} from "../../common/uploader/uploader-helpers";
import { API_ROOT, IS_PROD, MEDIA_ROOT } from "../../globals";
import {
  EPopupMessengerType,
  EPopupType,
  IMediaType,
  IPopupConfig,
} from "../../models";
import { IBasicEmptyPopup } from "../../popup/basic-empty-popup";
import { popPopup, pushPopup } from "../../popup/popup-action-creators";
import { PopupActions } from "../../popup/popup-actions";
import { PubnubActions } from "../../pubnub/pubnub-actions";
import { preventContextMenu } from "../../utils/events";
import {
  EResponseStatus,
  getRequest,
  globalHeaders,
  patchRequest,
  postRequest,
} from "../../utils/fetch";
import { promisifyXhr } from "../../utils/promise";
import { sendWarningMessage } from "../../utils/slack";
import { replaceUtf16UnpairedSurrogates } from "../../utils/string";
import { timeStampInMs } from "../../utils/time";
import { defined } from "../../utils/variable-evaluation";
import { removeChatFromChatList } from "../chats-action-creators";
import { ChatsActions } from "../chats-actions";
import { setPresence } from "../chats-helpers";
import {
  DENIED_FREE_CHAT_ERROR_MESSAGE,
  IChatInfo,
  MAX_FREE_CHATS_ERROR_MESSAGE,
} from "../chats-models";
import { MessengerActions } from "./messenger-actions";
import {
  BALANCE_TOO_LOW_ERROR,
  CHAT_ACCOUNT_NO_LONGER_EXISTS_ERROR_MESSAGE,
  CHAT_BLOCKED_COUNTRY_ERROR_MESSAGE,
  CHAT_BLOCKED_ERROR_MESSAGE,
  CHAT_CLOSED_ERROR_MESSAGE,
  CHAT_FROZEN_ERROR_MESSAGE_MODEL,
  CHAT_FROZEN_ERROR_MESSAGE_USER,
  CHAT_MESSAGE_INVALID_JSON_SINGLE_UNPAIRED_UTF16_SURROGATE_ERROR,
  CHAT_MESSAGE_INVALID_JSON_UNKNOWN_JSON_ENCODING_DECODING_ERROR,
  EMessageType,
  IGetFromLastIdMessagesConfig,
  IMessageInfo,
  IUpdateMessageAsPaidConfig,
  IUpdateMessagesReadConfig,
  MESSAGE_ALREADY_PURCHASED_ERROR,
  NO_CREDITS_ERROR_MESSAGE,
  SENDING_MEDIA_BLOCKED,
} from "./messenger-models";
import { UnlockFreeChatPopup } from "./unlock-free-chat-popup";
export interface IGetChatErr {
  status: number;
  message: string;
}

export function getChat(
  otherUserId: string,
  actions: MessengerActions,
  chatsActions: ChatsActions,
  pubnubActions: PubnubActions,
  popupActions: PopupActions,
  autoCreateChat?: boolean,
  history?: History,
  cachedChat?: IChatInfo,
  onSuccess?: (chat: IChatInfo) => void
) {
  if (cachedChat) {
    onSuccess && onSuccess(cachedChat);
  }

  const abortController = new AbortController();
  const url = `${API_ROOT}/v1/chat?recipient_id=${otherUserId}`;
  actions.initGetChat({
    url,
    otherUserId,
    cachedChat,
    abortController,
  });
  getRequest<IChatInfo>(url, { abortController }).then(
    (res) => {
      setPresence(res, pubnubActions);
      actions.successGetChat(res);
      if (!cachedChat) {
        onSuccess && onSuccess(res);
      }
      window.Rollbar.captureEvent(
        {
          name: "getChat",
          response: JSON.stringify(res),
        },
        "debug"
      );
    },
    (err: IGetChatErr) => {
      if (err.status === 404 && autoCreateChat) {
        postCreateChat(
          otherUserId,
          actions,
          chatsActions,
          popupActions,
          history!,
          true,
          undefined,
          (newChat: IChatInfo) => {
            onSuccess && onSuccess(newChat);
          }
        );
      } else {
        if (err.status === 410) {
          removeChatFromChatList(otherUserId, chatsActions);
        }
        actions.failureGetChat(err);
      }
    }
  );
}

export function resetGetChat(actions: MessengerActions) {
  actions.resetGetChat();
}

export function getChatReload(
  otherUserId: string,
  actions: MessengerActions,
  chatsActions: ChatsActions,
  pubnubActions: PubnubActions
) {
  const url = `${API_ROOT}/v1/chat?recipient_id=${otherUserId}`;
  actions.initGetChatReload({ url, otherUserId });
  getRequest<IChatInfo>(url).then(
    (res) => {
      setPresence(res, pubnubActions);
      actions.successGetChatReload(res);
      window.Rollbar.captureEvent(
        {
          name: "getChatReload",
          response: JSON.stringify(res),
        },
        "debug"
      );
    },
    (err: IGetChatErr) => {
      if (err.status === 410) {
        removeChatFromChatList(otherUserId, chatsActions);
      }
      actions.failureGetChatReload(err);
    }
  );
}

export function postCreateChat(
  recipient_id: string,
  actions: MessengerActions,
  chatsActions: ChatsActions,
  popupActions: PopupActions,
  history: History,
  noChatRedirect?: boolean,
  backTo?: string,
  onSuccess?: (chat: IChatInfo) => void
) {
  actions.initPostCreateChat(recipient_id);
  postRequest<IChatInfo>(`${API_ROOT}/v1/chat`, { recipient_id }).then(
    (res) => {
      // addChatToChatList(res, chatsActions); // the chat is actually not created until first message is sent (or at least it's not appearing in the chats list API endpoint) so we cannot use this
      if (!noChatRedirect) {
        history.push({
          pathname: `/chats/${recipient_id}`,
          state: {
            chat: res,
            backTo,
          },
        });
      }
      actions.successPostCreateChat(res);
      onSuccess && onSuccess(res);
    },
    (err) => {
      if (
        defined(err) &&
        (err.message === MAX_FREE_CHATS_ERROR_MESSAGE ||
          err.message === DENIED_FREE_CHAT_ERROR_MESSAGE ||
          err.status === EResponseStatus.PaymentRequired)
      ) {
        const popupProps: IBasicEmptyPopup.Props = {
          children: (
            <UnlockFreeChatPopup
              message={err.message}
              onConfirm={() => {
                popPopup(null, popupActions);
                history.push({
                  pathname: "/account/get-credits",
                  state: {
                    backTo: window.location.pathname,
                  },
                });
              }}
            />
          ),
          onClose: () => {
            popPopup(null, popupActions);
          },
        };
        pushPopup(
          {
            type: EPopupType.BasicEmptyPopup,
            props: popupProps,
          },
          popupActions
        );
      } else if (
        defined(err) &&
        (err.message === CHAT_FROZEN_ERROR_MESSAGE_MODEL ||
          err.message === CHAT_FROZEN_ERROR_MESSAGE_USER)
      ) {
        pushPopup(
          {
            type: EPopupType.BasicClosePopup,
            props: {
              emoj: "🚨",
              title: "Your account is frozen",
              sentence: err.message,
              onClose: () => {
                popPopup(null, popupActions);
              },
            },
          },
          popupActions
        );
      }
      actions.failurePostCreateChat(err);
    }
  );
}

export function updateChatById(
  chat_id: string,
  newValues: Partial<IChatInfo>,
  actions: MessengerActions
) {
  actions.updateChatById({ chat_id, newValues });
}

const MESSAGES_FETCHED = 50;

interface IGetMessagesConfig {
  chatId: string;
  otherUserId: string;
  unreadMessages: number;
  actions: MessengerActions;
}

interface IGetNextMessagesConfig {
  chatId: string;
  nextUrl: string;
  unreadMessages: number;
  actions: MessengerActions;
}

export function getMessages(
  config: IGetMessagesConfig,
  actions: MessengerActions
) {
  const abortController = new AbortController();
  actions.initGetMessages({
    chatId: config.chatId,
    otherUserId: config.otherUserId,
    abortController,
  });
  getRequest(
    `${API_ROOT}/v1/chats/${config.chatId}/messages?limit=${MESSAGES_FETCHED}&offset=0`,
    {
      abortController,
    }
  ).then((res) => {
    const newValues = {
      unread_messages: Math.max(0, config.unreadMessages - MESSAGES_FETCHED),
    };
    updateChatById(config.chatId, newValues, config.actions);
    actions.successGetMessages({
      res,
      chatId: config.chatId,
      otherUserId: config.otherUserId,
    });
  }, actions.failureGetMessages);
}

export function resetGetMessages(actions: MessengerActions) {
  actions.resetGetMessages();
}

export function getNextMessages(
  config: IGetNextMessagesConfig,
  actions: MessengerActions
) {
  actions.initGetNextMessages({ nextUrl: config.nextUrl });
  // setFirstLoad(false, actions);
  getRequest(API_ROOT + config.nextUrl).then((res) => {
    const newValues = {
      unread_messages: Math.max(0, config.unreadMessages - MESSAGES_FETCHED),
    };
    updateChatById(config.chatId, newValues, config.actions);
    actions.successGetNextMessages(res);
  }, actions.failureGetNextMessages);
}

export function addClientSideReceiveMessage(
  message: IMessageInfo,
  actions: MessengerActions
) {
  const { chat_id, recipient_id, sender_id, text, clientSideReceiveId, type } =
    message;
  const clientSideMessage: IMessageInfo = {
    chat_id,
    recipient_id,
    sender_id,
    text,
    clientSideReceiveId,
    type,
  };
  actions.addClientSideMessageReceived({ clientSideMessage });
}

export function getMessageById(
  messageId: string,
  actions: MessengerActions,
  successCallback?: (message: IMessageInfo) => void,
  errorCallback?: (error: any) => void
) {
  actions.initGetMessageById({ messageId });
  getRequest(`${API_ROOT}/v1/messages?ids=${messageId}`).then(
    (res: any) => {
      const message = res.data[0];
      actions.successGetMessageById(message);
      successCallback && successCallback(message);
    },
    (err: any) => {
      actions.failureGetMessageById(err);
      errorCallback && errorCallback(err);
    }
  );
}

export function getFromLastIdMessages(
  config: IGetFromLastIdMessagesConfig,
  actions: MessengerActions
) {
  actions.initGetFromLastIdMessages();
  getRequest(
    `${API_ROOT}/v1/chats/${config.chat_id}/messages?last_message_id=${config.last_message_id}`
  ).then(
    (res) => {
      updateUserAndChatFromLastId(res, config);
      actions.successGetFromLastIdMessages({ res, config });
    },
    (err) => {
      actions.failureGetFromLastIdMessages({ err, config });
    }
  );
}

function updateUserAndChatFromLastId(
  res: any,
  config: IGetFromLastIdMessagesConfig
) {
  // User
  updateUser({ credits: res.credits }, config.accountActions); // update my users Credits amount
  // Chat
  let newValues = {};
  if (defined(res.chat)) {
    newValues = { ...newValues, ...res.chat };
  }
  newValues = {
    ...newValues,
    unread_messages: Math.max(0, config.unread_messages - 1),
  };
  updateChatById(config.chat_id, newValues, config.actions);
}

export async function postSendMessage(
  message: IMessageInfo,
  mediaFileWrapper: IFileWrapper | undefined,
  vaultMedia: IVaultMedia | undefined,
  gif: IGif | undefined,
  user: IUserInfo,
  actions: MessengerActions,
  accountActions: AccountActions,
  popupActions: PopupActions,
  successCallback?: (response: any) => void
) {
  const isBendinary = true;

  if (!defined(message.created_at)) {
    message.created_at = new Date(Date.now() + 60 * 60 * 1000).toJSON(); // 60 minutes in future for sorting
  }
  if (!defined(message.__clientSideSendId)) {
    message.__clientSideSendId = timeStampInMs();
  }
  message.mediaFileWrapper = mediaFileWrapper;
  message.vaultMedia = vaultMedia;
  if (gif) {
    message.gif = {
      gif_url: gif.images.original.url,
      width: gif.images.original.width,
      height: gif.images.original.height,
    };
  }

  // attempt to send, and then re-send max 3 times
  let sendAttemptNumber = 0;

  function onSuccess(res: any) {
    updateUser({ credits: res.credits }, accountActions); // update my users Credits amount
    actions.successPostSendMessage({
      ...res,
      __clientSideSendId: message.__clientSideSendId,
    });
    successCallback &&
      successCallback({
        ...res,
        __clientSideSendId: message.__clientSideSendId,
      });
  }

  function onError(error: any, media?: Partial<IMediaType>) {
    if (error.message === NO_CREDITS_ERROR_MESSAGE) {
      // reload user object to update Credits and update chat UI
      getUser(accountActions);
      // remove client side message
      removeClientSideMessage(message.__clientSideSendId!, actions);

      sendWarningMessage(
        `\`${
          window.username
        }\` Messenger postSendMessage got \`${NO_CREDITS_ERROR_MESSAGE}\` in chat \`${
          message.chat_id
        }\`. \`${JSON.stringify(
          error
        )}\` INFO: His user object will be reloaded...`,
        "#web-logs",
        "broke-bot",
        ":briefcase:"
      );
    }

    if (error.message === SENDING_MEDIA_BLOCKED) {
      // remove client side message
      removeClientSideMessage(message.__clientSideSendId!, actions);

      pushPopup(
        {
          type: EPopupType.BasicClosePopup,
          props: {
            emoj: "😢",
            title: "Sending media blocked",
            sentence:
              "We are sorry. This members privacy settings prevent media from being sent...",
            onClose: () => {
              popPopup(null, popupActions);
            },
          },
        },
        popupActions
      );

      sendWarningMessage(
        `\`${
          window.username
        }\` Messenger postSendMessage got \`${SENDING_MEDIA_BLOCKED}\` in chat \`${
          message.chat_id
        }\`. \`${JSON.stringify(
          error
        )}\` INFO: He will see popup about media prevented from being sent...`,
        "#web-logs",
        "broke-bot",
        ":no_entry:"
      );
    }

    if (
      (error.message ===
        CHAT_MESSAGE_INVALID_JSON_UNKNOWN_JSON_ENCODING_DECODING_ERROR ||
        error.message ===
          CHAT_MESSAGE_INVALID_JSON_SINGLE_UNPAIRED_UTF16_SURROGATE_ERROR) &&
      sendAttemptNumber < 2
    ) {
      message.text = replaceUtf16UnpairedSurrogates(message.text || "");
      nextAttempt();
    } else if (
      error.message !== CHAT_CLOSED_ERROR_MESSAGE &&
      error.message !== CHAT_BLOCKED_ERROR_MESSAGE &&
      error.message !== CHAT_BLOCKED_COUNTRY_ERROR_MESSAGE &&
      error.message !== CHAT_ACCOUNT_NO_LONGER_EXISTS_ERROR_MESSAGE &&
      error.message !== CHAT_FROZEN_ERROR_MESSAGE_MODEL &&
      error.message !== CHAT_FROZEN_ERROR_MESSAGE_USER &&
      error.message !== NO_CREDITS_ERROR_MESSAGE &&
      error.message !== SENDING_MEDIA_BLOCKED &&
      error.message !==
        CHAT_MESSAGE_INVALID_JSON_UNKNOWN_JSON_ENCODING_DECODING_ERROR &&
      error.message !==
        CHAT_MESSAGE_INVALID_JSON_SINGLE_UNPAIRED_UTF16_SURROGATE_ERROR &&
      sendAttemptNumber < 4
    ) {
      nextAttempt(media);
    } else {
      actions.failurePostSendMessage({
        ...message,
        error,
      });
    }
  }

  function nextAttempt(media?: Partial<IMediaType>) {
    setTimeout(() => {
      postRequest(`${API_ROOT}/v1/messages`, {
        type: message.type,
        chat_id: message.chat_id,
        recipient_id: message.recipient_id,
        sender_id: message.sender_id,
        text: message.text,
        gif_url: message.type === EMessageType.Gif ? message.text : undefined,
        price: message.price,
        price_in_dollars: message.price_in_dollars,
        self_destruct: message.self_destruct,
        ...(isBendinary
          ? {
              media_id: media ? media.public_id : undefined, // BENDINARY
            }
          : {
              public_id: media ? media.public_id : undefined, // CLOUDINARY
            }),
        ...(vaultMedia
          ? {
              public_id: vaultMedia.public_id,
              media_id: vaultMedia.media_id,
            }
          : {}),
        width: media
          ? media.width
          : gif
            ? gif.images.original.width
            : undefined,
        height: media
          ? media.height
          : gif
            ? gif.images.original.height
            : undefined,
        video_length: media ? media.video_length : undefined,
      }).then(onSuccess, (err) => onError(err, media));
      sendAttemptNumber++;
    }, sendAttemptNumber * 1000);
  }

  if (message.type === EMessageType.Text || message.type === EMessageType.Gif) {
    actions.initPostSendMessage({
      clientSideMessage: message,
    });
    nextAttempt(); // first attempt
  } else if (mediaFileWrapper) {
    const md5Hash = createMediaHash(mediaFileWrapper!.file, user);

    const clientSideMedia: IMediaType = {
      public_id: md5Hash,
      th: mediaFileWrapper!.elm.src,
      st: mediaFileWrapper!.elm.src,
      rt: mediaFileWrapper!.elm.src,
      width: mediaFileWrapper!.width,
      height: mediaFileWrapper!.height,
      isClientSide: true,
      uploadProgress: 100,
      loaded: mediaFileWrapper!.file.size,
      total: mediaFileWrapper!.file.size,
    };

    if (mediaFileWrapper!.type === EFileType.Video) {
      clientSideMedia.video_url = mediaFileWrapper!.elm.src;
      clientSideMedia.video_length = mediaFileWrapper!.duration;
    }

    // NOTE: In Feed Uploader there is only 1 request to upload to /v1/media
    // and it will automatically upload and create a new feed post
    // that's why we don't do this in feed, it's handled by API
    let response;
    let isAvailable = false;
    if (isBendinary) {
      try {
        response = await getMediaAvailable(md5Hash, message.price! > 0);
        isAvailable = response.status === true;
      } catch (error) {} // swallow this as it's not needed
    } else {
      try {
        response = await getMediaByHash(md5Hash);
        isAvailable = true;
      } catch (error) {} // swallow this as it's not needed
    }

    if (isAvailable) {
      // this media was already uploaded before
      const { width, height, video_length = 0 } = response;
      actions.initPostSendMessage({
        clientSideMessage: {
          ...message,
          image: message.type === EMessageType.Image ? clientSideMedia : null,
          video: message.type === EMessageType.Video ? clientSideMedia : null,
        },
      });

      nextAttempt({
        public_id: md5Hash,
        width,
        height,
        video_length,
      }); // first attempt
    } else {
      // need to upload first

      // POST file request using XMLHttpRequest
      // because Fetch API is still lacking the support for request progression
      // (https://fetch.spec.whatwg.org/#fetch-api)
      const xhr = new XMLHttpRequest();
      const uploadFormData = new FormData();

      if (isBendinary) {
        // BENDINARY:
        uploadFormData.append("file", mediaFileWrapper!.file);
        uploadFormData.append("md5", md5Hash);
        uploadFormData.append(
          "type",
          message.price! > 0 ? "paid_message" : "message"
        );

        xhr.open("POST", `${MEDIA_ROOT}/v1/media`);

        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.setRequestHeader("cache-control", "no-cache"); // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
        Object.entries(globalHeaders()).forEach(([key, value]) => {
          xhr.setRequestHeader(key, value);
        });
      } else {
        // CLOUDINARY:
        const CLOUD_NAME = IS_PROD ? "alua" : "aparlay";

        uploadFormData.append("file", mediaFileWrapper!.file);
        uploadFormData.append("public_id", md5Hash);
        if (mediaFileWrapper!.type === EFileType.Image) {
          uploadFormData.append("upload_preset", "image_1440");
          uploadFormData.append("tags", "web");
        }
        if (mediaFileWrapper!.type === EFileType.Video) {
          uploadFormData.append("unsigned", "true");
          uploadFormData.append("resource_type", "video");
          let video_upload_preset = "chat_video";
          const fileSize = getFileSizeInMegaBytes(mediaFileWrapper!.file);
          const enable_eager_upload =
            (IS_PROD && fileSize > 99) || (!IS_PROD && fileSize > 39);
          if (enable_eager_upload) {
            video_upload_preset = video_upload_preset + "_eager";
          }
          uploadFormData.append("upload_preset", video_upload_preset);
          uploadFormData.append(
            "tags",
            `web, ${enable_eager_upload ? "compressed_eager" : "compressed"}`
          );
        }

        xhr.open(
          "POST",
          `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/${
            mediaFileWrapper!.type
          }/upload`
        );

        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.setRequestHeader("cache-control", "no-cache"); // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
      }

      const clientSideMedia: IMediaType = {
        public_id: md5Hash,
        th: mediaFileWrapper!.elm.src,
        st: mediaFileWrapper!.elm.src,
        rt: mediaFileWrapper!.elm.src,
        width: mediaFileWrapper!.width,
        height: mediaFileWrapper!.height,
        isClientSide: true,
        uploadProgress: 0,
        loaded: 0,
        total: mediaFileWrapper!.file.size,
        abortUpload: () => {
          xhr.abort();
        },
      };

      if (mediaFileWrapper!.type === EFileType.Video) {
        clientSideMedia.video_url = mediaFileWrapper!.elm.src;
        clientSideMedia.video_length = mediaFileWrapper!.duration;
      }

      actions.initPostSendMessage({
        clientSideMessage: {
          ...message,
          image: message.type === EMessageType.Image ? clientSideMedia : null,
          video: message.type === EMessageType.Video ? clientSideMedia : null,
        },
      });

      const handleProgress = (
        progress: number,
        loaded: number,
        total: number
      ) => {
        // update client side message upload progress
        actions.updateSendMessageProgress({
          progress,
          loaded,
          total,
          __clientSideSendId: message.__clientSideSendId,
        });
      };

      const handleSuccess = ({ response }: any) => {
        // send the message after upload
        const { width, height, video_length, duration = 0 } = response;
        nextAttempt({
          public_id: md5Hash,
          width,
          height,
          // video_length
          video_length: isBendinary ? video_length : Math.floor(duration),
        }); // first attempt
      };

      const handleError = ({ status, response, isAbort }: any) => {
        if (!isAbort) {
          let preview = null;
          if (mediaFileWrapper!.type === EFileType.Image) {
            preview = (
              <img
                width="100"
                height="100"
                src={mediaFileWrapper!.elm.src}
                alt=""
                style={{ background: "#000", objectFit: "contain" }}
              />
            );
          }
          if (mediaFileWrapper!.type === EFileType.Video) {
            preview = (
              <video
                width="100"
                height="100"
                preload="auto"
                playsInline
                autoPlay
                muted
                onContextMenu={preventContextMenu}
                src={mediaFileWrapper!.elm.src}
                style={{ background: "#000", objectFit: "contain" }}
              />
            );
          }
          let errorMessage =
            "This file failed to upload. Please check your network connection and try again.";
          if (defined(response.message)) {
            errorMessage = response.message;
          }
          pushPopup(
            {
              type: EPopupType.BasicClosePopup,
              props: {
                title: "Upload error",
                sentence: errorMessage,
                children: preview,
                onClose: () => {
                  popPopup(null, popupActions);
                },
              },
            },
            popupActions
          );
          sendWarningMessage(
            `\`${
              window.username
            }\` Chats MessengerUploader failed to upload: ${errorMessage} ${status} ${JSON.stringify(
              response
            )} ${JSON.stringify(mediaFileWrapper)} file mimeType: ${
              mediaFileWrapper?.file?.type
            } md5Hash: ${md5Hash}`,
            IS_PROD && status >= 400 ? "#api-status" : "#web-logs"
          );
          actions.failurePostSendMessage({
            ...message,
            isAbort,
            error: response,
          });
        }
        removeClientSideMessage(message.__clientSideSendId!, actions);
      };

      promisifyXhr(xhr, handleProgress).then(handleSuccess, handleError);
      xhr.send(uploadFormData);
    }
  } else if (vaultMedia) {
    const clientSideMedia: IMediaType = {
      public_id: vaultMedia.media_id || vaultMedia.public_id || "",
      th: vaultMedia.th,
      st: vaultMedia.st,
      rt: vaultMedia.rt,
      width: vaultMedia.width,
      height: vaultMedia.height,
      video_url: vaultMedia.video_url,
      video_length: vaultMedia.video_length,
      isClientSide: true,
      uploadProgress: 100,
      loaded: 0,
      total: 0,
    };

    actions.initPostSendMessage({
      clientSideMessage: {
        ...message,
        image: message.type === EMessageType.Image ? clientSideMedia : null,
        video: message.type === EMessageType.Video ? clientSideMedia : null,
      },
    });

    nextAttempt({
      public_id: vaultMedia.media_id,
      width: vaultMedia.width,
      height: vaultMedia.height,
      video_length: vaultMedia.video_length,
    }); // first attempt
  }
}

export enum EWarnedCategory {
  Global = "global",
  Payments = "payments",
  CompetingApps = "competing_apps",
}

export interface IPostMessageWarned {
  type?: string;
  category_id: EWarnedCategory;
  recipient_id?: string;
  autoMessageCategory?: string;
  message: string;
}

export function postMessageWarned(
  body: IPostMessageWarned,
  actions: MessengerActions
) {
  actions.initPostMessageWarned({ body });
  postRequest(`${API_ROOT}/v1/messages/warned`, body).then(
    actions.successPostMessageWarned,
    actions.failurePostMessageWarned
  );
}

export function resetPostSendMessage(actions: MessengerActions) {
  actions.resetPostSendMessage();
}

export function toggleHeader(actions: MessengerActions) {
  actions.toggleHeader();
}

export function setMessengerPopup(
  config: IPopupConfig | null,
  actions: MessengerActions
) {
  actions.setMessengerPopup(config);
}

export function togglePaidContent(
  paidContentConfig: Partial<IPaidContentPopup.Props> | null,
  actions: MessengerActions
) {
  actions.togglePaidContent({ paidContentConfig });
}

export function patchBuyPaidContent(
  messageId: string,
  user: IUserInfo,
  otherUser: IUserInfo,
  accountActions: AccountActions,
  actions: MessengerActions,
  successCallback: (response: any) => void
) {
  actions.initPatchBuyPaidContent({ messageId });
  patchRequest<IMessageInfo>(
    `${API_ROOT}/v1/messages/${messageId}/buy`,
    {}
  ).then(
    (res) => {
      getUser(accountActions); // reload user to update his Credits
      actions.successPatchBuyPaidContent(res);
      successCallback(res);
    },
    (err) => {
      // IMPORTANT: log every error response here!
      let isNoNeedToReport = false;
      if (err && err.message && err.message === BALANCE_TOO_LOW_ERROR) {
        isNoNeedToReport = true;
      }
      const errorMessage = err && err.message;
      if (!isNoNeedToReport) {
        sendWarningMessage(
          `\`${
            user.username
          }\` got error for patchBuyPaidContent in chat with \`${
            otherUser.username
          }\` on message \`${messageId}\`: \`${errorMessage}\` \`${
            errorMessage === "cannot parse response" ? err : ""
          } \`${
            errorMessage && errorMessage === MESSAGE_ALREADY_PURCHASED_ERROR
              ? "INFO: Message will be reloaded for the user..."
              : ""
          }`,
          "#web-logs",
          "unlockbot",
          ":closed_lock_with_key:"
        );
      }

      if (err && err.message === MESSAGE_ALREADY_PURCHASED_ERROR) {
        getMessageById(messageId, actions); // reload the message
        getUser(accountActions); // reload user to update his Credits too
      }
      if (err && err.message === BALANCE_TOO_LOW_ERROR) {
        getUser(accountActions); // reload user to update his Credits
        setMessengerPopup(
          {
            type: EPopupMessengerType.PopupNotEnoughCredits,
            props: {
              userId: user._id,
              onClose: () => {
                setMessengerPopup(null, actions);
                resetPatchBuyPaidContent(actions);
              },
            },
          },
          actions
        );
      }
      actions.failurePatchBuyPaidContent(err);
    }
  );
}

export function resetPatchBuyPaidContent(actions: MessengerActions) {
  actions.resetPatchBuyPaidContent();
}

export function updateMessageRevealMedia(
  messageId: string,
  actions: MessengerActions
) {
  actions.updateMessageRevealMedia({ messageId });
}

export function updateMessageAsPaid(
  config: IUpdateMessageAsPaidConfig,
  actions: MessengerActions
) {
  actions.updateMessageAsPaid(config);
}

export function removeMessageFromId(
  messageId: string,
  actions: MessengerActions
) {
  actions.removeMessageFromId({ messageId });
}

export function removeClientSideMessage(
  __clientSideSendId: string,
  actions: MessengerActions
) {
  actions.removeClientSideMessage({ __clientSideSendId });
}

export function updateMessagesRead(
  config: IUpdateMessagesReadConfig,
  actions: MessengerActions
) {
  actions.updateMessagesRead({ config });
}

export function updateMessageUnlockByPayingUsd(
  message: IMessageInfo,
  actions: MessengerActions
) {
  actions.updateMessageUnlockByPayingUsd({ message });
}
