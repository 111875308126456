import { createAction } from "redux-actions";
import { IUploadQueue } from "../models";

export namespace IBackgroundUploaderActions {
  export enum Type {
    ADD__UPLOAD_QUEUE = "ADD__UPLOAD_QUEUE",
    UPDATE__UPLOAD_QUEUE = "UPDATE__UPLOAD_QUEUE",
    REMOVE__UPLOAD_QUEUE = "REMOVE__UPLOAD_QUEUE",
  }

  export const addUploadQueue = createAction<IUploadQueue>(
    Type.ADD__UPLOAD_QUEUE
  );
  export const updateUploadQueue = createAction<IUploadQueue>(
    Type.UPDATE__UPLOAD_QUEUE
  );
  export const removeUploadQueue = createAction<string>(
    Type.REMOVE__UPLOAD_QUEUE
  );
}

export type BackgroundUploaderActions = Omit<
  typeof IBackgroundUploaderActions,
  "Type"
>;
