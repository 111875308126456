import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
  // getUserConfirmation: (message, callback) => {
  //   if (message === 'special message') {
  //     // special action
  //     console.log('special action');
  //     callback(false);
  //   }
  //   return confirm(message); // default action
  // }
});

history.listen((location) => {
  console.info("location changed", location);
});
