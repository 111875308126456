import { handleActions } from "redux-actions";
import {
  ERequestStatus,
  IGetRequest,
  IPostRequest,
  requestInit,
} from "../../utils/async";
import { ISubscriptionInfo, IUserInfo } from "../account-models";
import {
  IGetBroadcastStatsConfig,
  IGetPayoutsConfig,
  IGetReferrersConfig,
  IGetSubscribersConfig,
} from "./earnings-action-creators";
import { IEarningsActions } from "./earnings-actions";
import {
  IAccountInfo,
  IBroadcastStatsItem,
  IPayout,
  IPayoutTotals,
} from "./earnings-models";

export interface IEarningsModel {
  getPayoutTotals: IGetRequest<any>;
  getPayoutStats: IGetRequest<any>;
  getPayouts: IGetRequest<any>;
  nextPayoutsUrl: string | null;
  payouts: IPayout[] | null;
  getAccountInfo: IGetRequest<any>;
  patchPayoutMethod: IGetRequest<any>;
  postPayoutRequest: IPostRequest<any, any>;
  postAntiMoneyLaundering: IPostRequest<any, any>;
  accountInfo: IAccountInfo | null;
  payoutTotals: IPayoutTotals | null;
  getSubscribers: IGetRequest<any>;
  nextSubscribersUrl: string | null;
  subscribers: ISubscriptionInfo[] | null;
  getReferrers: IGetRequest<any>;
  nextReferrersUrl: string | null;
  referrers: IUserInfo[] | null;
  getBroadcastStats: IGetRequest<any>;
  nextBroadcastStatsUrl: string | null;
  broadcastStats: IBroadcastStatsItem[] | null;
}

const INITIAL_STATE: IEarningsModel = {
  getPayoutTotals: requestInit(),
  getPayoutStats: requestInit(),
  getPayouts: requestInit(),
  nextPayoutsUrl: null,
  payouts: null,
  getAccountInfo: requestInit(),
  patchPayoutMethod: requestInit(),
  postPayoutRequest: requestInit(),
  postAntiMoneyLaundering: requestInit(),
  accountInfo: null,
  payoutTotals: null,
  getSubscribers: requestInit(),
  nextSubscribersUrl: null,
  subscribers: null,
  getReferrers: requestInit(),
  nextReferrersUrl: null,
  referrers: null,
  getBroadcastStats: requestInit(),
  nextBroadcastStatsUrl: null,
  broadcastStats: null,
};

/** Reducer */
export const earningsReducer = handleActions<IEarningsModel, IEarningsModel>(
  {
    [IEarningsActions.Type.GET__PAYOUT_TOTALS__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getPayoutTotals: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IEarningsActions.Type.GET__PAYOUT_TOTALS__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getPayoutTotals: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        payoutTotals: action.payload,
      };
    },
    [IEarningsActions.Type.GET__PAYOUT_TOTALS__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getPayoutTotals: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.GET__PAYOUT_STATS__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getPayoutStats: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IEarningsActions.Type.GET__PAYOUT_STATS__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getPayoutStats: {
          responseData: action.payload.data,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IEarningsActions.Type.GET__PAYOUT_STATS__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getPayoutStats: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.GET__PAYOUTS__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      // const { isInitialRequest } = action.payload.config;
      return {
        ...state,
        getPayouts: {
          status: ERequestStatus.Fetching,
        },
        // payouts: isInitialRequest ? null : state.payouts
      };
    },
    [IEarningsActions.Type.GET__PAYOUTS__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      const config: IGetPayoutsConfig = action.payload.config;
      const res = action.payload.res;

      let payouts: IPayout[] = res.payouts;
      const getPayoutsNextUrl: string = res.payouts.length > 0 ? res.next : "";

      if (!config.isInitialRequest) {
        payouts = [...(state.payouts || []), ...res.payouts];
      }

      return {
        ...state,
        getPayouts: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
        payouts,
        nextPayoutsUrl: getPayoutsNextUrl,
      };
    },
    [IEarningsActions.Type.GET__PAYOUTS__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getPayouts: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.GET__ACCOUNT_INFO__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getAccountInfo: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IEarningsActions.Type.GET__ACCOUNT_INFO__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getAccountInfo: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        accountInfo: action.payload,
      };
    },
    [IEarningsActions.Type.GET__ACCOUNT_INFO__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getAccountInfo: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.PATCH__PAYOUT_METHOD__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        patchPayoutMethod: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IEarningsActions.Type.PATCH__PAYOUT_METHOD__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        patchPayoutMethod: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IEarningsActions.Type.PATCH__PAYOUT_METHOD__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        patchPayoutMethod: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.POST__PAYOUT_REQUEST__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        postPayoutRequest: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IEarningsActions.Type.POST__PAYOUT_REQUEST__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        postPayoutRequest: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IEarningsActions.Type.POST__PAYOUT_REQUEST__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        postPayoutRequest: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.POST__ANTI_MONEY_LAUNDERING__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        postAntiMoneyLaundering: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IEarningsActions.Type.POST__ANTI_MONEY_LAUNDERING__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        postAntiMoneyLaundering: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IEarningsActions.Type.POST__ANTI_MONEY_LAUNDERING__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        postAntiMoneyLaundering: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.GET__SUBSCRIBERS__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      // const { isInitialRequest } = action.payload.config;
      const nextState = {
        getSubscribers: {
          status: ERequestStatus.Fetching,
        },
        // subscribers: isInitialRequest ? null : state.subscribers
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IEarningsActions.Type.GET__SUBSCRIBERS__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      const config: IGetSubscribersConfig = action.payload.config;
      const res = action.payload.res;

      let subscribers: ISubscriptionInfo[] = res.data;
      const getSubscribersNextUrl: string = res.data.length > 0 ? res.next : "";

      if (!config.isInitialRequest) {
        subscribers = [...(state.subscribers || []), ...res.data];
      }

      return {
        ...state,
        nextSubscribersUrl: getSubscribersNextUrl,
        subscribers,
        getSubscribers: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IEarningsActions.Type.GET__SUBSCRIBERS__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getSubscribers: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.GET__REFERRERS__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      // const { isInitialRequest } = action.payload.config;
      const nextState = {
        getReferrers: {
          status: ERequestStatus.Fetching,
        },
        // referrers: isInitialRequest ? null : state.referrers
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IEarningsActions.Type.GET__REFERRERS__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      const config: IGetReferrersConfig = action.payload.config;
      const res = action.payload.res;

      let referrers: IUserInfo[] = res.data;
      const getReferrersNextUrl: string = res.data.length > 0 ? res.next : "";

      if (!config.isInitialRequest) {
        referrers = [...(state.referrers || []), ...res.data];
      }

      return {
        ...state,
        nextReferrersUrl: getReferrersNextUrl,
        referrers,
        getReferrers: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IEarningsActions.Type.GET__REFERRERS__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getReferrers: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IEarningsActions.Type.GET__BROADCAST_STATS__INIT]: (
      state,
      action: any
    ): IEarningsModel => {
      // const { isInitialRequest } = action.payload.config;
      const nextState = {
        getBroadcastStats: {
          status: ERequestStatus.Fetching,
        },
        // broadcastStats: isInitialRequest ? null : state.broadcastStats
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IEarningsActions.Type.GET__BROADCAST_STATS__SUCCESS]: (
      state,
      action: any
    ): IEarningsModel => {
      const config: IGetBroadcastStatsConfig = action.payload.config;
      const res = action.payload.res;

      let broadcastStats: IBroadcastStatsItem[] = res.data;
      const getBroadcastStatsNextUrl: string =
        res.data.length > 0 ? res.next : "";

      if (!config.isInitialRequest) {
        broadcastStats = [...(state.broadcastStats || []), ...res.data];
      }

      return {
        ...state,
        nextBroadcastStatsUrl: getBroadcastStatsNextUrl,
        broadcastStats,
        getBroadcastStats: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IEarningsActions.Type.GET__BROADCAST_STATS__FAILURE]: (
      state,
      action: any
    ): IEarningsModel => {
      return {
        ...state,
        getBroadcastStats: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
  },
  INITIAL_STATE
);
