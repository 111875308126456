import { handleActions } from "redux-actions";
import { IUserInfo } from "../../account/account-models";
import { IChatInfo } from "../../chats/chats-models";
import { IGetFeedConfig } from "../../feed/feed-helpers";
import { IFeedInfo } from "../../feed/feed-models";
import { IPopupConfig } from "../../models";
import {
  ERequestStatus,
  IGetRequest,
  IPostRequest,
  requestInit,
} from "../../utils/async";
import { IModelProfileActions } from "./model-profile-actions";

export interface IModelProfileModel {
  profileUser: IUserInfo | null;
  getProfileUser: IGetRequest<any>;
  getProfileChat: IGetRequest<any>;
  profileChat: IChatInfo | null;

  getProfileFeed: IGetRequest<any>;
  nextProfileFeedUrl: string | null;
  profileFeed: IFeedInfo[] | null;

  getProfileFeedPhotos: IGetRequest<any>;
  nextProfileFeedPhotosUrl: string | null;
  profileFeedPhotos: IFeedInfo[] | null;

  getProfileFeedVideos: IGetRequest<any>;
  nextProfileFeedVideosUrl: string | null;
  profileFeedVideos: IFeedInfo[] | null;

  patchFollow: IPostRequest<any, any>;
  deleteFollow: IPostRequest<any, any>;
  modelProfilePopup: IPopupConfig | null;
}

const INITIAL_STATE: IModelProfileModel = {
  profileUser: null,
  getProfileUser: requestInit(),
  getProfileChat: requestInit(),
  profileChat: null,

  getProfileFeed: requestInit(),
  nextProfileFeedUrl: null,
  profileFeed: null,

  getProfileFeedPhotos: requestInit(),
  nextProfileFeedPhotosUrl: null,
  profileFeedPhotos: null,

  getProfileFeedVideos: requestInit(),
  nextProfileFeedVideosUrl: null,
  profileFeedVideos: null,

  patchFollow: requestInit(),
  deleteFollow: requestInit(),
  modelProfilePopup: null,
};

/** Reducer */
export const modelProfileReducer = handleActions<
  IModelProfileModel,
  IModelProfileModel
>(
  {
    [IModelProfileActions.Type.GET__PROFILE_USER__INIT]: (
      state,
      action: any
    ): IModelProfileModel => {
      const cachedUser = action.payload as IUserInfo;
      return {
        ...state,
        getProfileUser: {
          status: ERequestStatus.Fetching,
        },
        profileUser: cachedUser || null,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_USER__SUCCESS]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        getProfileUser: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        profileUser: action.payload,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_USER__FAILURE]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        getProfileUser: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
        profileUser: null,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_USER__RESET]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        getProfileUser: requestInit(),
        profileUser: null,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED__INIT]: (
      state,
      action: any
    ): IModelProfileModel => {
      const config: IGetFeedConfig = action.payload.config;

      // prevent issue when feed request is delayed from other model
      // and then loads and overwrites the current model feed
      if (config.modelId !== state.profileUser?._id) return state;

      const nextState = {
        getProfileFeed: {
          status: ERequestStatus.Fetching,
        },
        profileFeed: config.isInitialRequest ? null : state.profileFeed,
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED__SUCCESS]: (
      state,
      action: any
    ): IModelProfileModel => {
      const config: IGetFeedConfig = action.payload.config;

      // prevent issue when feed request is delayed from other model
      // and then loads and overwrites the current model feed
      if (config.modelId !== state.profileUser?._id) return state;

      const res = action.payload.res;

      let profileFeed: IFeedInfo[] = res.data;
      const nextProfileFeedUrl: string = res.data.length > 0 ? res.next : "";

      if (!config.isInitialRequest) {
        profileFeed = [...(state.profileFeed || []), ...res.data];
      }

      return {
        ...state,
        nextProfileFeedUrl,
        profileFeed,
        getProfileFeed: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED__FAILURE]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        getProfileFeed: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED_PHOTOS__INIT]: (
      state,
      action: any
    ): IModelProfileModel => {
      const { isInitialRequest } = action.payload.config;
      const nextState = {
        getProfileFeedPhotos: {
          status: ERequestStatus.Fetching,
        },
        profileFeedPhotos: isInitialRequest ? null : state.profileFeedPhotos,
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED_PHOTOS__SUCCESS]: (
      state,
      action: any
    ): IModelProfileModel => {
      const config: IGetFeedConfig = action.payload.config;
      const res = action.payload.res;

      let profileFeedPhotos: IFeedInfo[] = res.data;
      const nextProfileFeedPhotosUrl: string =
        res.data.length > 0 ? res.next : "";

      if (!config.isInitialRequest) {
        profileFeedPhotos = [...(state.profileFeedPhotos || []), ...res.data];
      }

      return {
        ...state,
        nextProfileFeedPhotosUrl,
        profileFeedPhotos,
        getProfileFeedPhotos: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED_PHOTOS__FAILURE]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        getProfileFeedPhotos: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED_VIDEOS__INIT]: (
      state,
      action: any
    ): IModelProfileModel => {
      const { isInitialRequest } = action.payload.config;
      const nextState = {
        getProfileFeedVideos: {
          status: ERequestStatus.Fetching,
        },
        profileFeedVideos: isInitialRequest ? null : state.profileFeedVideos,
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED_VIDEOS__SUCCESS]: (
      state,
      action: any
    ): IModelProfileModel => {
      const config: IGetFeedConfig = action.payload.config;
      const res = action.payload.res;

      let profileFeedVideos: IFeedInfo[] = res.data;
      const nextProfileFeedVideosUrl: string =
        res.data.length > 0 ? res.next : "";

      if (!config.isInitialRequest) {
        profileFeedVideos = [...(state.profileFeedVideos || []), ...res.data];
      }

      return {
        ...state,
        nextProfileFeedVideosUrl,
        profileFeedVideos,
        getProfileFeedVideos: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED_VIDEOS__FAILURE]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        getProfileFeedVideos: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_FEED__RESET]: (
      state,
      action: any
    ): IModelProfileModel => {
      const nextState = {
        getProfileFeed: {
          status: ERequestStatus.None,
        },
        profileFeed: null,
        getProfileFeedPhotos: {
          status: ERequestStatus.None,
        },
        profileFeedPhotos: null,
        getProfileFeedVideos: {
          status: ERequestStatus.None,
        },
        profileFeedVideos: null,
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_CHAT__INIT]: (
      state,
      action: any
    ): IModelProfileModel => {
      const cachedChat = action.payload.cachedChat as IChatInfo;
      const abortController = action.payload.abortController as AbortController;
      return {
        ...state,
        getProfileChat: {
          status: ERequestStatus.Fetching,
          abortController,
        },
        profileChat: cachedChat || null,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_CHAT__SUCCESS]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        getProfileChat: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        profileChat: action.payload,
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_CHAT__FAILURE]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        getProfileChat: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IModelProfileActions.Type.GET__PROFILE_CHAT__RESET]: (
      state,
      action: any
    ): IModelProfileModel => {
      if (state.getProfileChat.abortController) {
        state.getProfileChat.abortController.abort();
      }
      return {
        ...state,
        getProfileChat: requestInit(),
        profileChat: null,
      };
    },
    [IModelProfileActions.Type.PATCH__FOLLOW__INIT]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        patchFollow: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IModelProfileActions.Type.PATCH__FOLLOW__SUCCESS]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        profileUser: {
          ...state.profileUser!,
          is_followee: true,
        },
        patchFollow: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IModelProfileActions.Type.PATCH__FOLLOW__FAILURE]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        patchFollow: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IModelProfileActions.Type.DELETE__FOLLOW__INIT]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        deleteFollow: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IModelProfileActions.Type.DELETE__FOLLOW__SUCCESS]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        profileUser: {
          ...state.profileUser!,
          is_followee: false,
        },
        deleteFollow: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IModelProfileActions.Type.DELETE__FOLLOW__FAILURE]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        deleteFollow: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IModelProfileActions.Type.SET_MODEL_PROFILE_POPUP]: (
      state,
      action: any
    ): IModelProfileModel => {
      return {
        ...state,
        modelProfilePopup: action.payload,
      };
    },
  },
  INITIAL_STATE
);
