import { IUserInfo } from "./account/account-models";
import { API_ROOT, IS_PROD } from "./globals";
import {
  isNotificationSupported,
  isServiceWorkerSupported,
} from "./utils/detectors";
import { authToken } from "./utils/fetch";

let deferredInstallPrompt: any = null;

export function init() {
  window.addEventListener("beforeinstallprompt", (e) => {
    console.log("beforeinstallprompt", e);

    // cache the event so it can be triggered later.
    deferredInstallPrompt = e;
    deferredInstallPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
        // deferredInstallPrompt = null;
      } else {
        console.log("User dismissed the A2HS prompt", choiceResult.outcome);
      }
    });
  });

  window.addEventListener("appinstalled", (evt) => {
    console.log("appinstalled", evt);
    // deferredInstallPrompt = null;
  });
}

export function isInstallPossible() {
  return !!deferredInstallPrompt;
}

export function promptInstall() {
  if (deferredInstallPrompt) {
    deferredInstallPrompt.prompt();
  }
}

export function registerServiceWorker() {
  console.log("registerServiceWorker");

  if (isServiceWorkerSupported) {
    navigator.serviceWorker.register("/service-worker.js");
  }
}

export function requestUpdateServiceWorker() {
  console.log("requestUpdateServiceWorker");

  if (isServiceWorkerSupported) {
    navigator.serviceWorker.ready.then(
      (swRegistration: ServiceWorkerRegistration) => {
        if (swRegistration.active) {
          swRegistration.update();
        }
      }
    );
  }
}

export function setupPushManager(user: IUserInfo) {
  console.log("setupPushManager", user);

  if (isServiceWorkerSupported) {
    navigator.serviceWorker.ready.then(
      (swRegistration: ServiceWorkerRegistration) => {
        if (swRegistration.active && isNotificationSupported) {
          swRegistration.active.postMessage(
            JSON.stringify({
              type: "setup",
              userId: user._id,
              username: user.username,
              accessToken: authToken(),
              apiRoot: API_ROOT,
              slackHookUrl: process.env.REACT_APP_SLACK_HOOK_URL!,
              slackChannel: IS_PROD
                ? "#web-push-debug-prod"
                : "#web-push-debug-dev",
              vapidPublicKey: process.env.REACT_APP_VAPID_PUBLIC_KEY!,
            })
          );
        }
      }
    );
  }
}

export function ensureSubscription() {
  console.log("ensureSubscription");

  if (isServiceWorkerSupported) {
    navigator.serviceWorker.ready.then(
      (swRegistration: ServiceWorkerRegistration) => {
        if (swRegistration.active && isNotificationSupported) {
          swRegistration.active.postMessage(
            JSON.stringify({
              type: "ensureSubscription",
            })
          );
        }
      }
    );
  }
}

export function deleteSubscription() {
  console.log("deleteSubscription");

  if (isServiceWorkerSupported) {
    navigator.serviceWorker.ready.then(
      (swRegistration: ServiceWorkerRegistration) => {
        if (swRegistration.active && isNotificationSupported) {
          swRegistration.active.postMessage(
            JSON.stringify({
              type: "logout",
            })
          );
        }
      }
    );
  }
}

export function updateBadge(count: number) {
  console.log("updateBadge", count);

  if (isServiceWorkerSupported) {
    navigator.serviceWorker.ready.then(
      (swRegistration: ServiceWorkerRegistration) => {
        if (swRegistration.active) {
          swRegistration.active.postMessage(
            JSON.stringify({
              type: "updateBadge",
              count,
            })
          );
        }
      }
    );
  }
}

export function pushNotification(subject: string, body: string) {
  console.log("pushNotification", subject, body);

  if (isServiceWorkerSupported) {
    navigator.serviceWorker.ready.then(
      (swRegistration: ServiceWorkerRegistration) => {
        if (swRegistration.active && isNotificationSupported) {
          swRegistration.active.postMessage(
            JSON.stringify({
              type: "internalNotification",
              subject,
              body,
            })
          );
        }
      }
    );
  }
}
