import { createAction } from "redux-actions";

export namespace IPromoActions {
  export enum Type {
    GET__SIMPLE_DISCOVER__INIT = "GET__SIMPLE_DISCOVER__INIT",
    GET__SIMPLE_DISCOVER__SUCCESS = "GET__SIMPLE_DISCOVER__SUCCESS",
    GET__SIMPLE_DISCOVER__FAILURE = "GET__SIMPLE_DISCOVER__FAILURE",
  }

  export const initGetSimpleDiscover = createAction(
    Type.GET__SIMPLE_DISCOVER__INIT
  );
  export const successGetSimpleDiscover = createAction<any>(
    Type.GET__SIMPLE_DISCOVER__SUCCESS
  );
  export const failureGetSimpleDiscover = createAction<any>(
    Type.GET__SIMPLE_DISCOVER__FAILURE
  );
}

export type PromoActions = Omit<typeof IPromoActions, "Type">;
