import { History } from "history";
import {
  EUserType,
  IProviderConfig,
  IUserInfo,
  IVaultMedia,
} from "../account/account-models";
import { IPopupConfirmDeleteChats } from "../common/popups/popup-chat/popup-confirm-delete-chats";
import { IPopupConfirmHideChats } from "../common/popups/popup-chat/popup-confirm-hide-chats";
import {
  createMediaHash,
  getMediaAvailable,
} from "../common/uploader/media-uploader-helpers";
import { EFileType, IFileWrapper } from "../common/uploader/uploader-helpers";
import { API_ROOT, IS_PROD, MEDIA_ROOT } from "../globals";
import {
  EPopupChatType,
  IDictionary,
  IMediaType,
  IPopupConfig,
} from "../models";
import { PubnubActions } from "../pubnub/pubnub-actions";
import {
  getRequest,
  globalHeaders,
  patchRequest,
  postRequest,
} from "../utils/fetch";
import { promisifyXhr } from "../utils/promise";
import { sendWarningMessage } from "../utils/slack";
import { timeStampInMs } from "../utils/time";
import { defined } from "../utils/variable-evaluation";
import { ChatsActions } from "./chats-actions";
import {
  EChatFilterCategory,
  EChatFilterType,
  getChatsUrl,
  IChatFilter,
  IGetChatsConfig,
  setPresence,
  TO_API_VALUE,
  transformChatResponse,
} from "./chats-helpers";
import {
  EBroadcastMessageType,
  EBroadcastStage,
  EPatchUpdateChatsAction,
  IBroadcastMessage,
  IChatInfo,
} from "./chats-models";

export function setFilters(
  filtersSet: IDictionary<IChatFilter>,
  user: IUserInfo,
  providerConfig: IProviderConfig,
  actions: ChatsActions,
  pubnubActions: PubnubActions,
  isSneakyReload?: boolean
) {
  getChats(actions, pubnubActions, {
    user,
    providerConfig,
    isInitialRequest: true,
    isSneakyReload: !!isSneakyReload,
    filtersSet,
  });
  actions.setFilters({ filtersSet });
}

export interface IGetChatsResponse {
  data: IChatInfo[];
  debug: string;
  next: string;
  prev: string;
  unread_messages: number;
}

export function getChats(
  actions: ChatsActions,
  pubnubActions: PubnubActions,
  config: IGetChatsConfig
) {
  const url = getChatsUrl(config);
  // get chat totals
  const { user, providerConfig } = config;
  if (
    defined(user) &&
    user.type === EUserType.Featured &&
    defined(providerConfig)
  ) {
    getChatTotals(actions);
  }

  actions.initGetChats({ url, config });
  getRequest<IGetChatsResponse>(url).then((res) => {
    actions.successGetChats({
      res: transformChatResponse(res, config, pubnubActions),
      config,
    });
  }, actions.failureGetChats);
  // window['chatsLastFetchedAt'] = Date.now();
}

export function getNextChats(
  nextUrl: string,
  actions: ChatsActions,
  pubnubActions: PubnubActions,
  config: IGetChatsConfig
) {
  actions.initGetChats({ config });
  getRequest(API_ROOT + nextUrl).then(
    (res) =>
      actions.successGetChats({
        res: transformChatResponse(res, config, pubnubActions),
        config,
      }),
    actions.failureGetChats
  );
}

export function getChatTotals(actions: ChatsActions) {
  actions.initGetChatTotals();
  getRequest(`${API_ROOT}/v1/chats/total`).then(
    actions.successGetChatTotals,
    actions.failureGetChatTotals
  );
}

export function getAndAddChatToChatList(
  otherUserId: string,
  actions: ChatsActions,
  pubnubActions: PubnubActions
) {
  const url = `${API_ROOT}/v1/chat?recipient_id=${otherUserId}`;
  getRequest<IChatInfo>(url).then((res) => {
    setPresence(res, pubnubActions);
    getChatTotals(actions);
    addChatToChatList(res, actions);
  });
}

export function addChatToChatList(chat: IChatInfo, actions: ChatsActions) {
  actions.addChatToChatList({ chat });
}

export function removeChatFromChatList(
  otherUserId: string,
  actions: ChatsActions
) {
  actions.removeChatFromChatList({ otherUserId });
}

export function setChatPopup(
  config: IPopupConfig | null,
  actions: ChatsActions
) {
  actions.setChatPopup(config);
}

export function setChatEditing(isEditing: boolean, actions: ChatsActions) {
  actions.setChatEditing(isEditing);
}

export function toggleChatSelection(chatId: string, actions: ChatsActions) {
  actions.toggleChatSelection(chatId);
}

export function setAllChatsSelection(
  isSelectAll: boolean,
  actions: ChatsActions
) {
  actions.setAllChatsSelection(isSelectAll);
}

export function toggleAllChatsSelection(actions: ChatsActions) {
  actions.toggleAllChatsSelection();
}

function setPopupConfirmDeleteChats(
  isSelectAll: boolean,
  chatIds: string[],
  onConfirm: () => void,
  actions: ChatsActions
) {
  const onClose = () => setChatPopup(null, actions);
  const props: IPopupConfirmDeleteChats.Props = {
    isSelectAll,
    chatIds,
    onConfirm: () => {
      onConfirm();
      onClose();
    },
    onClose,
  };
  setChatPopup(
    { type: EPopupChatType.PopupConfirmDeleteChats, props },
    actions
  );
}

function setPopupConfirmHideChats(
  isSelectAll: boolean,
  chatIds: string[],
  onConfirm: () => void,
  actions: ChatsActions
) {
  const onClose = () => setChatPopup(null, actions);
  const props: IPopupConfirmHideChats.Props = {
    isSelectAll,
    chatIds,
    onConfirm: () => {
      onConfirm();
      onClose();
    },
    onClose,
  };
  setChatPopup({ type: EPopupChatType.PopupConfirmHideChats, props }, actions);
}

export function patchUpdateChats(
  action: EPatchUpdateChatsAction,
  isSelectAll: boolean,
  chatIds: string[],
  filtersSet: IDictionary<IChatFilter>,
  actions: ChatsActions,
  history: History
) {
  function doPatch() {
    actions.initPatchUpdateChats();
    patchRequest(`${API_ROOT}/v1/chats`, {
      action,
      [isSelectAll ? "except_ids" : "ids"]: chatIds.join(","),
      type:
        (TO_API_VALUE as any)[EChatFilterType.Category][
          filtersSet[EChatFilterType.Category].value
        ] || filtersSet[EChatFilterType.Category].value,
    }).then((res) => {
      actions.successPatchUpdateChats(res);
      if (
        action === EPatchUpdateChatsAction.HIDE ||
        action === EPatchUpdateChatsAction.DELETE
      ) {
        history.push("/chats");
      }
    }, actions.failurePatchUpdateChats);
  }
  if (action === EPatchUpdateChatsAction.DELETE) {
    setPopupConfirmDeleteChats(isSelectAll, chatIds, doPatch, actions);
  } else if (action === EPatchUpdateChatsAction.HIDE) {
    setPopupConfirmHideChats(isSelectAll, chatIds, doPatch, actions);
  } else {
    doPatch();
  }
}

export function setBroadcastStage(
  stage: EBroadcastStage,
  actions: ChatsActions
) {
  actions.setBroadcastStage({ stage });
}

export async function postBroadcastMessage(
  broadcastMessage: IBroadcastMessage,
  mediaFileWrapper: IFileWrapper | null,
  vaultMedia: IVaultMedia | null,
  user: IUserInfo,
  actions: ChatsActions
) {
  const __clientSideSendId =
    broadcastMessage.__clientSideSendId || timeStampInMs();

  if (broadcastMessage.type === EBroadcastMessageType.Text) {
    actions.initPostBroadcastMessage({
      clientSideBroadcastMessage: {
        ...broadcastMessage,
        __clientSideSendId,
      },
    });
    // postRequest(`${API_ROOT}/v1/auto-messages/send`, {
    //   ...broadcastMessage
    // }).then(
    //   (response) => {
    //     actions.successPostBroadcastMessage({
    //       response,
    //       __clientSideSendId
    //     });
    //     // getAutoMessagesReload(actions);
    //   },
    //   (errorResponse) => {
    //     actions.failurePostBroadcastMessage({
    //       errorResponse,
    //       __clientSideSendId
    //     });
    //   }
    // );

    actions.successPostBroadcastMessage({
      clientSideBroadcastMessage: {
        ...broadcastMessage,
        _id: "_" + __clientSideSendId, // pretending that the message is from API
        __clientSideSendId,
      },
    });
  } else if (mediaFileWrapper) {
    const md5Hash = createMediaHash(mediaFileWrapper!.file, user);

    // NOTE: In Feed Uploader there is only 1 request to upload to /v1/media
    // and it will automatically upload and create a new feed post
    // that's why we don't do this in feed, it's handled by API
    const response = await getMediaAvailable(
      md5Hash,
      broadcastMessage.price! > 0
    );
    const isAvailable = response.status === true;

    if (isAvailable) {
      // this media was already uploaded before

      {
        const clientSideMedia: IMediaType = {
          public_id: md5Hash,
          th: mediaFileWrapper!.elm.src,
          st: mediaFileWrapper!.elm.src,
          rt: mediaFileWrapper!.elm.src,
          width: mediaFileWrapper!.width,
          height: mediaFileWrapper!.height,
          isClientSide: true,
          uploadProgress: 100,
          loaded: mediaFileWrapper!.file.size,
          total: mediaFileWrapper!.file.size,
        };

        if (mediaFileWrapper!.type === EFileType.Video) {
          clientSideMedia.video_url = mediaFileWrapper!.elm.src;
          clientSideMedia.video_length = mediaFileWrapper!.duration;
        }

        actions.initPostBroadcastMessage({
          clientSideBroadcastMessage: {
            ...broadcastMessage,
            __clientSideSendId,
            media: clientSideMedia,
            _id: undefined,
          },
        });
      }

      const { width, height, video_length, media } = response;

      {
        const clientSideMedia: IMediaType = {
          public_id: md5Hash,
          th: media ? media.th : mediaFileWrapper!.elm.src,
          st: media ? media.st : mediaFileWrapper!.elm.src,
          rt: media ? media.rt : mediaFileWrapper!.elm.src,
          sr: media ? media.sr : mediaFileWrapper!.elm.src,
          width,
          height,
          isClientSide: media ? false : true,
          abortUpload: undefined,
        };

        if (mediaFileWrapper!.type === EFileType.Video) {
          clientSideMedia.video_url = media
            ? media.video_url
            : mediaFileWrapper!.elm.src;
          clientSideMedia.video_length = video_length;
        }

        actions.successPostBroadcastMessage({
          clientSideBroadcastMessage: {
            ...broadcastMessage,
            _id: "_" + __clientSideSendId, // pretending that the message is from API
            __clientSideSendId,
            media: clientSideMedia,
          },
        });
      }
    } else {
      // need to upload first

      const uploadFormData = new FormData();
      uploadFormData.append("file", mediaFileWrapper!.file);
      uploadFormData.append("md5", md5Hash);
      uploadFormData.append(
        "type",
        broadcastMessage.price! > 0 ? "paid_message" : "message"
      );

      // POST file request using XMLHttpRequest
      // because Fetch API is still lacking the support for request progression
      // (https://fetch.spec.whatwg.org/#fetch-api)
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${MEDIA_ROOT}/v1/media`);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.setRequestHeader("cache-control", "no-cache"); // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
      Object.entries(globalHeaders()).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      const handleProgress = (
        progress: number,
        loaded: number,
        total: number
      ) => {
        // update upload progress
        actions.updatePostBroadcastMessageProgress({
          progress,
          loaded,
          total,
          __clientSideSendId,
        });
      };

      const handleSuccess = ({ response }: any) => {
        // send the message after upload
        const { width, height, video_length, media } = response;

        const clientSideMedia: IMediaType = {
          public_id: media.public_id,
          th: media.th,
          st: media.st,
          rt: media.rt,
          sr: media.sr,
          width,
          height,
          isClientSide: false,
          abortUpload: undefined,
        };

        if (mediaFileWrapper!.type === EFileType.Video) {
          clientSideMedia.video_url = media.video_url;
          clientSideMedia.video_length = video_length;
        }

        actions.successPostBroadcastMessage({
          clientSideBroadcastMessage: {
            ...broadcastMessage,
            _id: "_" + __clientSideSendId, // pretending that the message is from API
            __clientSideSendId,
            media: clientSideMedia,
          },
        });
      };

      const handleError = ({ status, response, isAbort }: any) => {
        if (!isAbort) {
          let errorMessage =
            "This file failed to upload. Please check your network connection and try again.";
          if (defined(response.message)) {
            errorMessage = response.message;
          }
          sendWarningMessage(
            `\`${
              window.username
            }\` Chats BroadcastMessagesUploader failed to upload: ${errorMessage} ${status} ${JSON.stringify(
              response
            )} ${JSON.stringify(mediaFileWrapper)} file mimeType: ${
              mediaFileWrapper?.file?.type
            } md5Hash: ${md5Hash}`,
            IS_PROD && status >= 400 ? "#api-status" : "#web-logs"
          );
        }
        actions.failurePostBroadcastMessage({
          isAbort,
          errorResponse: response,
          __clientSideSendId,
        });
      };

      const clientSideMedia: IMediaType = {
        public_id: md5Hash,
        th: mediaFileWrapper!.elm.src,
        st: mediaFileWrapper!.elm.src,
        rt: mediaFileWrapper!.elm.src,
        width: mediaFileWrapper!.width,
        height: mediaFileWrapper!.height,
        isClientSide: true,
        uploadProgress: 0,
        loaded: 0,
        total: mediaFileWrapper!.file.size,
        abortUpload: () => {
          xhr.abort();
        },
      };

      if (mediaFileWrapper!.type === EFileType.Video) {
        clientSideMedia.video_url = mediaFileWrapper!.elm.src;
        clientSideMedia.video_length = mediaFileWrapper!.duration;
      }

      actions.initPostBroadcastMessage({
        clientSideBroadcastMessage: {
          ...broadcastMessage,
          __clientSideSendId,
          media: clientSideMedia,
          _id: undefined,
        },
      });

      promisifyXhr(xhr, handleProgress).then(handleSuccess, handleError);
      xhr.send(uploadFormData);
    }
  } else if (vaultMedia) {
    const clientSideMedia: IMediaType = {
      public_id: vaultMedia.public_id,
      media_id: vaultMedia.media_id,
      th: vaultMedia.th,
      st: vaultMedia.st,
      rt: vaultMedia.rt,
      width: vaultMedia.width,
      height: vaultMedia.height,
      video_url: vaultMedia.video_url,
      video_length: vaultMedia.video_length,
      isClientSide: true,
      uploadProgress: 100,
      loaded: 0,
      total: 0,
    };

    actions.initPostBroadcastMessage({
      clientSideBroadcastMessage: {
        ...broadcastMessage,
        __clientSideSendId,
        media: clientSideMedia,
        _id: undefined,
      },
    });

    actions.successPostBroadcastMessage({
      clientSideBroadcastMessage: {
        ...broadcastMessage,
        _id: "_" + __clientSideSendId, // pretending that the message is from API
        __clientSideSendId,
        __isVaultMedia: true,
        media: clientSideMedia,
      },
    });
  }
}

// export async function setBroadcastMessageMedia(
//   broadcastMessage: IBroadcastMessage | null,
//   mediaFileWrapper: IFileWrapper | null,
//   user: IUserInfo,
//   actions: ChatsActions
// ) {
//   const __clientSideSendId = broadcastMessage.__clientSideSendId || timeStampInMs();

//   if (defined(mediaFileWrapper)) {
//     const md5Hash = createMediaHash(mediaFileWrapper.file, user);

//     const clientSideMedia: IMediaType = {
//       public_id: md5Hash,
//       th: mediaFileWrapper.elm.src,
//       st: mediaFileWrapper.elm.src,
//       rt: mediaFileWrapper.elm.src,
//       width: mediaFileWrapper.width,
//       height: mediaFileWrapper.height,
//       isClientSide: true,
//       uploadProgress: 0,
//       loaded: 0,
//       total: mediaFileWrapper.file.size,
//       abortUpload: undefined
//     };

//     if (mediaFileWrapper.type === EFileType.Video) {
//       clientSideMedia.video_url = mediaFileWrapper.elm.src;
//       clientSideMedia.video_length = mediaFileWrapper.duration;
//     }

//     actions.setBroadcastMessageMedia({
//       clientSideBroadcastMessage: {
//         ...broadcastMessage,
//         _id: '_' + md5Hash, // pretending that the message is from API
//         __clientSideSendId,
//         media: clientSideMedia,
//         mediaFileWrapper
//       }
//     });
//   } else {
//     actions.setBroadcastMessageMedia({
//       clientSideBroadcastMessage: null
//     });
//   }
// }

export function deleteBroadcastMessage(
  broadcastMessageId: string,
  actions: ChatsActions
) {
  actions.initDeleteBroadcastMessage({
    broadcastMessageId,
  });
  actions.successDeleteBroadcastMessage({
    broadcastMessageId,
  });
}

export async function postBroadcast(
  text: string,
  broadcastMediaMessage: IBroadcastMessage | null,
  filtersSet: IDictionary<IChatFilter>,
  chatsSelection: string[],
  isSelectAll: boolean,
  actions: ChatsActions
) {
  const category = filtersSet[EChatFilterType.Category]
    .value as EChatFilterCategory;

  actions.initPostBroadcast();
  postRequest(`${API_ROOT}/v1/broadcast/send`, {
    category:
      defined(TO_API_VALUE[EChatFilterType.Category]) &&
      defined((TO_API_VALUE[EChatFilterType.Category] as any)[category])
        ? (TO_API_VALUE[EChatFilterType.Category] as any)[category]
        : category,
    [isSelectAll ? "except_ids" : "ids"]: chatsSelection.join(","),
    type: broadcastMediaMessage
      ? broadcastMediaMessage.type
      : EBroadcastMessageType.Text,
    media_id: broadcastMediaMessage
      ? broadcastMediaMessage.media!.public_id
      : undefined,
    ...(broadcastMediaMessage?.__isVaultMedia
      ? {
          media_id: broadcastMediaMessage.media!.media_id,
          public_id: broadcastMediaMessage.media!.public_id,
        }
      : {}),
    price: broadcastMediaMessage ? broadcastMediaMessage.price : 0,
    price_in_dollars: broadcastMediaMessage
      ? broadcastMediaMessage.price_in_dollars
      : undefined,
    self_destruct: broadcastMediaMessage
      ? broadcastMediaMessage.self_destruct
      : false,
    width: broadcastMediaMessage ? broadcastMediaMessage.media!.width : 0,
    height: broadcastMediaMessage ? broadcastMediaMessage.media!.height : 0,
    video_length: broadcastMediaMessage
      ? broadcastMediaMessage.media!.video_length
      : 0,
    text,
  }).then(actions.successPostBroadcast, actions.failurePostBroadcast);
}
