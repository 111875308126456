import React from "react";
import { renderDefinedTrue } from "../../../../utils/react-conditional";
import { PopupHeader } from "../popup-header";
import styles from "./PopupBackground.module.scss";

export namespace IPopupBackground {
  export interface Props {
    title?: React.ReactNode;
    inlineStyle?: React.CSSProperties;
    children?: React.ReactNode;
  }
}

export const PopupBackground = React.memo(function PopupBackground(
  props: IPopupBackground.Props
) {
  return (
    <div className={styles.PopupBackground} style={props.inlineStyle}>
      {renderDefinedTrue(props.title, () => (
        <PopupHeader>{props.title}</PopupHeader>
      ))}
      {props.children}
    </div>
  );
});
