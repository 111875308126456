import { createAction } from "redux-actions";

export namespace IPopupActions {
  export enum Type {
    PUSH__POPUP = "PUSH__POPUP",
    POP__POPUP = "POP__POPUP",
  }

  export const pushPopup = createAction<any>(Type.PUSH__POPUP);
  export const popPopup = createAction(Type.POP__POPUP);
}

export type PopupActions = Omit<typeof IPopupActions, "Type">;
