import React from "react";
import { RouteComponentProps } from "react-router";
import { URL_LOGIN } from "../globals";
import { deleteCookie, getCookie } from "../utils/cookie";
import { renderIf } from "../utils/react-conditional";
import { exists } from "../utils/variable-evaluation";
import styles from "./ClearCacheUnban.module.scss";

export const IS_BANNED_NAME = "is-banned";

export namespace IClearCacheUnban {
  export interface Props extends RouteComponentProps<any> {}
  export interface State {
    [key: string]: string | number | null;
    count: number;
  }
}

export class ClearCacheUnban extends React.PureComponent<
  IClearCacheUnban.Props,
  IClearCacheUnban.State
> {
  intervalId: any;
  constructor(props: IClearCacheUnban.Props, context?: any) {
    super(props, context);
    this.state = {
      [IS_BANNED_NAME]: getCookie(IS_BANNED_NAME),
      count: 5,
    };
  }

  public componentDidMount() {
    deleteCookie(IS_BANNED_NAME);
    this.setState({
      [IS_BANNED_NAME]: null,
    });
    this.intervalId = setInterval(() => {
      if (this.state.count > 0) {
        this.setState({
          count: this.state.count - 1,
        });
      } else {
        window.location.href = URL_LOGIN;
      }
    }, 1000);
  }

  public componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  public render() {
    return (
      <div className={styles.ClearCacheUnban}>
        {renderIf(exists(this.state[IS_BANNED_NAME]), {
          ifTrue: () => (
            <div className={styles.container}>Clearing token...</div>
          ),
          ifFalse: () => (
            <div className={styles.container}>
              <div className={styles.text}>
                You have been successfully unbanned.
              </div>
              <div
                className={styles.text}
              >{`Redirecting in ${this.state.count} seconds`}</div>
            </div>
          ),
        })}
      </div>
    );
  }
}
