import deepEqual from "fast-deep-equal";
import { IOnboardingConfig } from "../account/account-models";
import { IInputConfig } from "../common/input";
import { API_ROOT, FROM_DESKTOP, FROM_TABLET, FROM_WIDE } from "../globals";
import { IDictionary } from "../models";
import { getLocalStorageValue } from "../utils/localStorage";
import { EUnit, timeDiff } from "../utils/time";
import { defined } from "../utils/variable-evaluation";

export const SUBSEQUENT_AMOUNT = 20;

export interface IGetProfilesConfig {
  isInitialRequest: boolean; // any request that doesn't use 'getNextProfiles' is true,
  // isDoubleRequest?: boolean; // two requests performed in sequence, one just to fill white-space, second for space below white space
  offset?: number;
  viewportWidth?: number; // required if isInitialRequest
  onboardingCode?: string;
  filtersSet: IDictionary<IDiscoverFilter>;
  isForceLoader?: boolean; // this is to force showing the loader (used in pullToRefresh)
}

export interface IDiscoverFilter {
  type?: EDiscoverFilterType;
  value?: any;
  defaultValue?: any;
  inputs?: IInputConfig[];
}

export function onboardingCode(onboardingConfig?: IOnboardingConfig) {
  return onboardingConfig?.init?.code_used;
}

/* Async */

// export function isModelSelected(prevProps, props) {
//   return (
//     prevProps.match.params.profileId !== props.match.params.profileId &&
//     defined(props.match.params.profileId)
//   );
// }

/* Viewport Size */

export function numberOfColumns(width: number) {
  if (width < FROM_TABLET) {
    return 2;
  }
  if (width < FROM_DESKTOP) {
    return 3;
  }
  if (width < FROM_WIDE) {
    return 4;
  }
  return 5;
}

/* Filters */

export enum EDiscoverFilterType {
  Featured = "featured",
  Query = "query",
  Type = "type",
  Gender = "gender",
}

export function getDefaultDiscoverFiltersState() {
  const localGenderPreference = JSON.parse(
    getLocalStorageValue("discoverFiltersGender") || "{}"
  );

  return {
    [EDiscoverFilterType.Featured]: {
      type: EDiscoverFilterType.Featured,
      value: true,
    },
    [EDiscoverFilterType.Query]: {
      type: EDiscoverFilterType.Query,
      value: "",
    },
    [EDiscoverFilterType.Type]: {
      type: EDiscoverFilterType.Type,
      value: null,
    },
    [EDiscoverFilterType.Gender]: {
      type: EDiscoverFilterType.Gender,
      value: {
        male: defined(localGenderPreference["male"])
          ? localGenderPreference["male"]
          : true,
        female: defined(localGenderPreference["female"])
          ? localGenderPreference["female"]
          : true,
        other: defined(localGenderPreference["other"])
          ? localGenderPreference["other"]
          : true,
      },
    },
  };
}

export function isDefaultFiltersSet(filtersSet: IDictionary<IDiscoverFilter>) {
  return deepEqual(filtersSet, getDefaultDiscoverFiltersState());
}

/* API */

const TO_API_KEY = {
  [EDiscoverFilterType.Featured]: "featured",
  [EDiscoverFilterType.Query]: "q",
  [EDiscoverFilterType.Type]: "type",
  [EDiscoverFilterType.Gender]: "gender",
};

const TO_API_VALUE = {
  [EDiscoverFilterType.Query]: {
    "": null,
  },
  [EDiscoverFilterType.Type]: {
    nearby: "near",
  },
  [EDiscoverFilterType.Gender]: {
    other: "trans",
  },
};

export function getProfilesUrl(config: IGetProfilesConfig) {
  const { isInitialRequest, offset, filtersSet } = config;
  // const initAmount = numberOfColumns(viewportWidth) * 4;
  const initAmount = 20;
  const limit = isInitialRequest ? initAmount : SUBSEQUENT_AMOUNT;
  let volumeQueries = `?offset=0&limit=${limit}`;
  if (defined(offset)) {
    volumeQueries = `?offset=${offset}&limit=${limit}`;
  }
  let url = `${API_ROOT}/v1/users/discover${volumeQueries}`;
  if (defined(config.onboardingCode)) {
    url += `&code_used=${config.onboardingCode}`;
  }
  if (defined(filtersSet)) {
    Object.keys(filtersSet).forEach((filterKey) => {
      const filter = filtersSet[filterKey];
      const apiKey = (TO_API_KEY as any)[filterKey];
      let apiValue = filter.value;

      if (
        defined((TO_API_VALUE as any)[filterKey]) &&
        defined((TO_API_VALUE as any)[filterKey][filter.value])
      ) {
        apiValue = (TO_API_VALUE as any)[filterKey][filter.value];
      }
      if (
        typeof filter.value === "object" &&
        filter.value !== null &&
        !Array.isArray(filter.value)
      ) {
        apiValue = Object.keys(filter.value)
          .filter((key) => filter.value[key])
          .map((key) => {
            if (
              defined((TO_API_VALUE as any)[filterKey]) &&
              defined((TO_API_VALUE as any)[filterKey][key])
            ) {
              return (TO_API_VALUE as any)[filterKey][key];
            }
            return key;
          })
          .join(",");
      }
      if (defined(apiValue) && apiValue !== "") {
        url += `&${apiKey}=${apiValue}`;
      }
    });
  }
  return url;
}

export function isNew(created_at: string) {
  const hoursDifference = timeDiff("now", created_at, EUnit.Hour);
  return hoursDifference < 72;
}
