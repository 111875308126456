import { handleActions } from "redux-actions";
import { IAccountActions } from "../account/account-actions";
import { IUserInfo } from "../account/account-models";
import { IDictionary, IPopupConfig } from "../models";
import { removeDuplicates, sortByLastMessageTime } from "../utils/array";
import {
  ERequestStatus,
  IGetRequest,
  IPostRequest,
  IRequest,
  requestInit,
} from "../utils/async";
import { defined } from "../utils/variable-evaluation";
import { IChatsActions } from "./chats-actions";
import {
  DEFAULT_CHAT_FILTERS_STATE,
  IChatFilter,
  IGetChatsConfig,
} from "./chats-helpers";
import {
  EBroadcastStage,
  IBroadcastMessage,
  IChatInfo,
  IChatTotals,
} from "./chats-models";
import { IMessengerActions } from "./messenger/messenger-actions";

export interface IChatsModel {
  filtersSet: IDictionary<IChatFilter>;
  chats: IChatInfo[];
  // chatCategories: IChatCategories;
  chatTotals: IChatTotals;
  userItems: IUserInfo[];
  getChatsNextUrl: string;
  getChats: IGetRequest<any>;
  getChatTotals: IGetRequest<any>;
  chatPopup: IPopupConfig | null;
  isEditing: boolean;
  isSelectAll: boolean;
  chatsSelection: string[]; // only ids
  patchUpdateChats: IPostRequest<any, any>;
  broadcastMessages: IBroadcastMessage[];
  broadcastStage: EBroadcastStage;
  postBroadcastMessage: IPostRequest<any, any>;
  postBroadcast: IPostRequest<any, any>;
  deleteBroadcastMessage: IRequest<any>;
}

const INITIAL_STATE: IChatsModel = {
  filtersSet: DEFAULT_CHAT_FILTERS_STATE,
  chats: [],
  // chatCategories: {
  //   all: [],
  //   online: [],
  //   fans: [],
  //   subscribers: [],
  //   favorites: [],
  //   unreplied: [],
  //   recent: [],
  //   vip: []
  // },
  chatTotals: {
    all: 0,
    online: 0,
    fans: 0,
    subscribers: 0,
    favorites: 0,
    unreplied: 0,
    vip: 0,
    recent: 0,
    sent: 0,
  },
  userItems: [],
  getChatsNextUrl: "",
  getChats: requestInit(),
  getChatTotals: requestInit(),
  chatPopup: null,
  isEditing: false,
  isSelectAll: false,
  chatsSelection: [],
  patchUpdateChats: requestInit(),
  broadcastMessages: [],
  broadcastStage: EBroadcastStage.None,
  postBroadcastMessage: requestInit(),
  postBroadcast: requestInit(),
  deleteBroadcastMessage: requestInit(),
};

/** Reducer */
export const chatsReducer = handleActions<IChatsModel, IChatsModel>(
  {
    [IAccountActions.Type.GET__USER__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      const user = action.payload as IUserInfo;
      if (user.is_broadcasting) {
        return {
          ...state,
          broadcastStage: EBroadcastStage.Broadcasting,
        };
      } else if (state.broadcastStage === EBroadcastStage.Broadcasting) {
        // fallback in case of ws type broadcast_done failing
        return {
          ...state,
          broadcastStage: EBroadcastStage.None,
        };
      }
      return state;
    },
    [IChatsActions.Type.FILTERS__SET]: (state, action: any): IChatsModel => {
      return {
        ...state,
        filtersSet: {
          // ...state.filtersSet,
          ...action.payload.filtersSet,
        },
      };
    },
    [IChatsActions.Type.GET__FETCH_CHATS__INIT]: (
      state,
      action: any
    ): IChatsModel => {
      const { isInitialRequest, isSneakyReload } = action.payload
        .config as IGetChatsConfig;
      const nextState = {
        getChats: {
          status: isSneakyReload
            ? state.getChats.status
            : ERequestStatus.Fetching,
        },
        chats: isInitialRequest && !isSneakyReload ? [] : state.chats,
        // chatCategories: {
        //   ...state.chatCategories,
        //   [filtersSet[EChatFilterType.Category].value as EChatFilterCategory]:
        //     isInitialRequest && !isSneakyReload ? [] : state.chats
        // },
        userItems: isInitialRequest && !isSneakyReload ? [] : state.userItems,
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IChatsActions.Type.GET__FETCH_CHATS__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      const config: IGetChatsConfig = action.payload.config;
      let chats: IChatInfo[] = action.payload.res.data || [];
      let userItems: IUserInfo[] = action.payload.res.data_users || [];
      const getChatsNextUrl: string = action.payload.res.next;

      if (!config.isInitialRequest) {
        chats = [...state.chats, ...chats];
        userItems = [...state.userItems, ...userItems];
      }

      chats = removeDuplicates(chats, "_id", true).sort(sortByLastMessageTime);

      let nextState = {
        getChatsNextUrl,
        chats,
        // chatCategories: {
        //   ...state.chatCategories,
        //   [config.filtersSet[EChatFilterType.Category].value as EChatFilterCategory]: chats
        // },
        userItems,
        getChats: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };

      return {
        ...state,
        ...nextState,
      };
    },
    [IChatsActions.Type.GET__FETCH_CHATS__FAILURE]: (
      state,
      action
    ): IChatsModel => {
      return {
        ...state,
        getChats: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IChatsActions.Type.GET__FETCH_CHAT_TOTALS__INIT]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        getChatTotals: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IChatsActions.Type.GET__FETCH_CHAT_TOTALS__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        chatTotals: {
          ...state.chatTotals,
          ...action.payload.data,
        },
        getChatTotals: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IChatsActions.Type.GET__FETCH_CHAT_TOTALS__FAILURE]: (
      state,
      action
    ): IChatsModel => {
      return {
        ...state,
        getChatTotals: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IMessengerActions.Type.GET__FETCH_CHAT__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      const updatedChat = action.payload as IChatInfo;
      return {
        ...state,
        chats: state.chats
          .map((chat) => (chat._id === updatedChat._id ? updatedChat : chat))
          .sort(sortByLastMessageTime),
        // chatCategories: Object.keys(state.chatCategories).reduce(
        //   (p, c: EChatFilterCategory) => ({
        //     ...p,
        //     [c]: state.chatCategories[c]
        //       .map((chat) => (chat._id === updatedChat._id ? updatedChat : chat))
        //       .sort(sortByLastMessageTime)
        //   }),
        //   {}
        // )
      };
    },
    [IMessengerActions.Type.GET__FETCH_CHAT_RELOAD__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      const updatedChat = action.payload as IChatInfo;
      return {
        ...state,
        chats: state.chats
          .map((chat) => (chat._id === updatedChat._id ? updatedChat : chat))
          .sort(sortByLastMessageTime),
        // chatCategories: Object.keys(state.chatCategories).reduce(
        //   (p, c: EChatFilterCategory) => ({
        //     ...p,
        //     [c]: state.chatCategories[c]
        //       .map((chat) => (chat._id === updatedChat._id ? updatedChat : chat))
        //       .sort(sortByLastMessageTime)
        //   }),
        //   {}
        // )
      };
    },
    [IMessengerActions.Type.UPDATE__CHAT_BY_ID]: (
      state,
      action: any
    ): IChatsModel => {
      const { chat_id, newValues } = action.payload;
      return {
        ...state,
        chats: state.chats
          .map((chat) =>
            chat._id === chat_id ? { ...chat, ...newValues } : chat
          )
          .sort(sortByLastMessageTime),
        // chatCategories: Object.keys(state.chatCategories).reduce(
        //   (p, c: EChatFilterCategory) => ({
        //     ...p,
        //     [c]: state.chatCategories[c]
        //       .map((chat) => (chat._id === chat_id ? { ...chat, ...newValues } : chat))
        //       .sort(sortByLastMessageTime)
        //   }),
        //   {}
        // )
      };
    },
    [IChatsActions.Type.ADD__CHAT_TO_CHATS_LIST]: (
      state,
      action: any
    ): IChatsModel => {
      let chats = [...state.chats, action.payload.chat];

      chats = removeDuplicates(chats, "_id", true).sort(sortByLastMessageTime);

      return {
        ...state,
        chats,
        // chatCategories: {
        //   ...state.chatCategories,
        //   all: removeDuplicates(
        //     [...state.chatCategories.all, action.payload.chat],
        //     '_id',
        //     true
        //   ).sort(sortByLastMessageTime)
        // }
      };
    },
    [IChatsActions.Type.REMOVE__CHAT_FROM_CHATS_LIST]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        chats: state.chats.filter(
          (chat) => chat.other_user._id !== action.payload.otherUserId
        ),
        // chatCategories: Object.keys(state.chatCategories).reduce(
        //   (p, c: EChatFilterCategory) => ({
        //     ...p,
        //     [c]: state.chatCategories[c].filter(
        //       (chat) => chat.other_user._id !== action.payload.otherUserId
        //     )
        //   }),
        //   {}
        // )
      };
    },
    [IChatsActions.Type.SET__CHAT_POPUP]: (state, action: any): IChatsModel => {
      return {
        ...state,
        chatPopup: action.payload,
      };
    },
    [IChatsActions.Type.SET__CHAT_EDITING]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        isEditing: action.payload,
        isSelectAll: false, // reset
        chatsSelection: [], // reset
      };
    },
    [IChatsActions.Type.TOGGLE__CHAT_SELECTION]: (
      state,
      action: any
    ): IChatsModel => {
      let chatsSelection: string[];
      if (state.chatsSelection.indexOf(action.payload) !== -1) {
        chatsSelection = state.chatsSelection.filter(
          (chatId) => chatId !== action.payload
        );
      } else {
        chatsSelection = [...state.chatsSelection, action.payload];
      }
      return {
        ...state,
        chatsSelection,
      };
    },
    [IChatsActions.Type.SET__ALL_CHATS_SELECTION]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        isSelectAll: action.payload,
        chatsSelection: [],
      };
    },
    [IChatsActions.Type.TOGGLE__ALL_CHATS_SELECTION]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        isSelectAll: !state.isSelectAll,
        chatsSelection: [],
      };
    },
    [IChatsActions.Type.PATCH__UPDATE_CHATS__INIT]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        patchUpdateChats: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IChatsActions.Type.PATCH__UPDATE_CHATS__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        patchUpdateChats: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        // isEditing: false, // reset
        isSelectAll: false, // reset
        chatsSelection: [], // reset
      };
    },
    [IChatsActions.Type.PATCH__UPDATE_CHATS__FAILURE]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        patchUpdateChats: {
          error: action.payload.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IChatsActions.Type.SET__BROADCAST_STAGE]: (
      state,
      action: any
    ): IChatsModel => {
      const { stage } = action.payload;
      return {
        ...state,
        broadcastStage: stage,
      };
    },
    [IChatsActions.Type.POST__BROADCAST_MESSAGE__INIT]: (
      state,
      action: any
    ): IChatsModel => {
      const { clientSideBroadcastMessage } = action.payload;
      return {
        ...state,
        // broadcastMessages: [...state.broadcastMessages, clientSideBroadcastMessage],
        broadcastMessages: [clientSideBroadcastMessage],
        postBroadcastMessage: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IChatsActions.Type.POST__BROADCAST_MESSAGE__UPDATE_PROGRESS]: (
      state,
      action: any
    ): IChatsModel => {
      const { progress, loaded, total, __clientSideSendId } = action.payload;
      return {
        ...state,
        broadcastMessages: state.broadcastMessages.map((m) => {
          if (m.__clientSideSendId === __clientSideSendId) {
            return {
              ...m,
              media: {
                ...m.media!,
                uploadProgress: progress,
                loaded,
                total,
              },
            };
          }
          return m;
        }),
      };
    },
    [IChatsActions.Type.POST__BROADCAST_MESSAGE__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      const { clientSideBroadcastMessage } = action.payload;
      return {
        ...state,
        // broadcastMessages: state.broadcastMessages.map((m) => {
        //   // replace clientSideBroadcastMessage with API response
        //   if (m.__clientSideSendId === __clientSideSendId) {
        //     return response.data;
        //   }
        //   return m;
        // }),
        broadcastMessages: state.broadcastMessages.map((m) => {
          // replace clientSideBroadcastMessage with fake API response
          if (
            m.__clientSideSendId ===
            clientSideBroadcastMessage.__clientSideSendId
          ) {
            return clientSideBroadcastMessage;
          }
          return m;
        }),
        postBroadcastMessage: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IChatsActions.Type.POST__BROADCAST_MESSAGE__FAILURE]: (
      state,
      action: any
    ): IChatsModel => {
      const { isAbort, errorResponse, __clientSideSendId } = action.payload;

      let broadcastMessages = [...state.broadcastMessages];
      if (isAbort) {
        // remove clientSideBroadcastMessage
        broadcastMessages = broadcastMessages.filter(
          (m) => m.__clientSideSendId !== __clientSideSendId
        );
      } else {
        // update clientSideBroadcastMessage
        broadcastMessages = broadcastMessages.map((m) => {
          if (m.__clientSideSendId === __clientSideSendId) {
            return {
              ...m,
              __isUploadFailed: true,
              __errorMessage: (errorResponse && errorResponse.message) || "",
            };
          }
          return m;
        });
      }
      return {
        ...state,
        broadcastMessages,
        postBroadcastMessage: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IChatsActions.Type.DELETE__BROADCAST_MESSAGE__INIT]: (
      state,
      action: any
    ): IChatsModel => {
      const { broadcastMessageId } = action.payload;
      return {
        ...state,
        broadcastMessages: state.broadcastMessages.map((m) => {
          if (m._id === broadcastMessageId) {
            return {
              ...m,
              __isBeingDeleted: true,
            };
          }
          return m;
        }),
        deleteBroadcastMessage: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IChatsActions.Type.DELETE__BROADCAST_MESSAGE__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      const { broadcastMessageId } = action.payload;
      return {
        ...state,
        broadcastMessages: state.broadcastMessages.filter(
          (m) => m._id !== broadcastMessageId
        ),
        deleteBroadcastMessage: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    // [IChatsActions.Type.DELETE__BROADCAST_MESSAGE__FAILURE]: (state, action: any): IChatsModel => {
    //   const { errorResponse, broadcastMessageId } = action.payload;
    //   return {
    //     ...state,
    //     broadcastMessages: state.broadcastMessages.map((m) => {
    //       if (m._id === broadcastMessageId) {
    //         return {
    //           ...m,
    //           __isBeingDeleted: false,
    //           __errorMessage: (errorResponse && errorResponse.message) || ''
    //         };
    //       }
    //       return m;
    //     }),
    //     deleteBroadcastMessage: {
    //       error: action.payload,
    //       status: ERequestStatus.Error
    //     }
    //   };
    // },
    [IChatsActions.Type.SET__BROADCAST_MESSAGE_MEDIA]: (
      state,
      action: any
    ): IChatsModel => {
      const { clientSideBroadcastMessage } = action.payload;
      return {
        ...state,
        broadcastMessages: defined(clientSideBroadcastMessage)
          ? [clientSideBroadcastMessage]
          : [],
      };
    },
    [IChatsActions.Type.POST__BROADCAST__INIT]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        postBroadcast: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IChatsActions.Type.POST__BROADCAST__SUCCESS]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        postBroadcast: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        broadcastMessages: [], // reset
      };
    },
    [IChatsActions.Type.POST__BROADCAST__FAILURE]: (
      state,
      action: any
    ): IChatsModel => {
      return {
        ...state,
        postBroadcast: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
  },
  INITIAL_STATE
);
