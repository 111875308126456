import { handleActions } from "redux-actions";
import { IPopupConfig } from "../models";
import { defined } from "../utils/variable-evaluation";
import { IPopupActions } from "./popup-actions";

export interface IPopupModel {
  popups: IPopupConfig[];
}

const INITIAL_STATE: IPopupModel = {
  popups: [],
};

/** Reducer */
export const popupReducer = handleActions<IPopupModel, IPopupModel>(
  {
    [IPopupActions.Type.PUSH__POPUP]: (state, action: any): IPopupModel => {
      const popup: IPopupConfig = action.payload;
      const isAlreadyInTheList =
        defined(popup.id) &&
        state.popups.findIndex((p) => p.id === popup.id) !== -1;
      if (isAlreadyInTheList) {
        return state;
      }
      return {
        ...state,
        popups: [...state.popups, action.payload],
      };
    },
    [IPopupActions.Type.POP__POPUP]: (state, action: any): IPopupModel => {
      const id: string | null = action.payload;
      let newPopups = [...state.popups];
      if (defined(id)) {
        newPopups = newPopups.filter((p) => defined(p.id) && p.id !== id);
      } else {
        // if no id then just remove the last one added
        newPopups = newPopups.filter((p, i, { length }) => i !== length - 1);
      }
      return {
        ...state,
        popups: newPopups,
      };
    },
  },
  INITIAL_STATE
);
