import { createAction } from "redux-actions";

export namespace IFeedActions {
  export enum Type {
    GET__FEED__INIT = "GET__FEED__INIT",
    GET__FEED__SUCCESS = "GET__FEED__SUCCESS",
    GET__FEED__FAILURE = "GET__FEED__FAILURE",

    SET__UPLOAD_CONTENT_CONFIG = "SET__UPLOAD_CONTENT_CONFIG",
    SET__EDIT_CONTENT_CONFIG = "SET__EDIT_CONTENT_CONFIG",

    POST__FEED__INIT = "POST__FEED__INIT",
    POST__FEED__PROGRESS = "POST_FEED_PROGRESS",
    POST__FEED__SUCCESS = "POST__FEED__SUCCESS",
    POST__FEED__FAILURE = "POST__FEED__FAILURE",

    PATCH__FEED_ITEM__INIT = "PATCH__FEED_ITEM__INIT",
    PATCH__FEED_ITEM__SUCCESS = "PATCH__FEED_ITEM__SUCCESS",
    PATCH__FEED_ITEM__FAILURE = "PATCH__FEED_ITEM__FAILURE",
    RESET__PATCH_FEED_ITEM = "RESET__PATCH_FEED_ITEM",

    SET__UNREAD_FEEDS = "SET__UNREAD_FEEDS",

    // DELETE__FEED_ITEM_BY_ID__INIT = 'DELETE__FEED_ITEM_BY_ID__INIT',
    // DELETE__FEED_ITEM_BY_ID__SUCCESS = 'DELETE__FEED_ITEM_BY_ID__SUCCESS',
    // DELETE__FEED_ITEM_BY_ID__FAILURE = 'DELETE__FEED_ITEM_BY_ID__FAILURE',

    // PATCH__FEED_ITEM_BY_ID__INIT = 'PATCH__FEED_ITEM_BY_ID__INIT',
    // PATCH__FEED_ITEM_BY_ID__SUCCESS = 'PATCH__FEED_ITEM_BY_ID__SUCCESS',
    // PATCH__FEED_ITEM_BY_ID__FAILURE = 'PATCH__FEED_ITEM_BY_ID__FAILURE',

    // GET__FEED_BY_ID__INIT = 'GET__FEED_BY_ID__INIT',
    // GET__FEED_BY_ID__SUCCESS = 'GET__FEED_BY_ID__SUCCESS',
    // GET__FEED_BY_ID__FAILURE = 'GET__FEED_BY_ID__FAILURE',

    // DELETE__FM_FEED_ITEM_BY_ID__INIT = 'DELETE__FM_FEED_ITEM_BY_ID__INIT',
    // DELETE__FM_FEED_ITEM_BY_ID__SUCCESS = 'DELETE__FM_FEED_ITEM_BY_ID__SUCCESS',
    // DELETE__FM_FEED_ITEM_BY_ID__FAILURE = 'DELETE__FM_FEED_ITEM_BY_ID__FAILURE',

    // PATCH__FM_FEED_ITEM_BY_ID__INIT = 'PATCH__FM_FEED_ITEM_BY_ID__INIT',
    // PATCH__FM_FEED_ITEM_BY_ID__SUCCESS = 'PATCH__FEED_ITEM_BY_ID__SUCCESS',
    // PATCH__FM_FEED_ITEM_BY_ID__FAILURE = 'PATCH__FEED_ITEM_BY_ID__FAILURE',

    // GET__FOLLOWING__INIT = 'GET__FOLLOWING__INIT',
    // GET__FOLLOWING__SUCCESS = 'GET__FOLLOWING__SUCCESS',
    // GET__FOLLOWING__FAILURE = 'GET__FOLLOWING__FAILURE'
  }
  export const initGetFeed = createAction<any>(Type.GET__FEED__INIT);
  export const successGetFeed = createAction<any>(Type.GET__FEED__SUCCESS);
  export const failureGetFeed = createAction<any>(Type.GET__FEED__FAILURE);

  export const toggleUploadContent = createAction<any>(
    Type.SET__UPLOAD_CONTENT_CONFIG
  );
  export const toggleEditContent = createAction<any>(
    Type.SET__EDIT_CONTENT_CONFIG
  );

  export const initPostFmFeed = createAction<any>(Type.POST__FEED__INIT);
  export const progressPostFmFeed = createAction<any>(
    Type.POST__FEED__PROGRESS
  );
  export const successPostFmFeed = createAction<any>(Type.POST__FEED__SUCCESS);
  export const failurePostFmFeed = createAction<any>(Type.POST__FEED__FAILURE);

  export const initPatchFeedItem = createAction(Type.PATCH__FEED_ITEM__INIT);
  export const successPatchFeedItem = createAction<any>(
    Type.PATCH__FEED_ITEM__SUCCESS
  );
  export const failurePatchFeedItem = createAction<any>(
    Type.PATCH__FEED_ITEM__FAILURE
  );
  export const resetPatchFeedItem = createAction(Type.RESET__PATCH_FEED_ITEM);

  // export const initDeleteFeedItemById = createAction<any>(Type.DELETE__FEED_ITEM_BY_ID__INIT);
  // export const successDeleteFeedItemById = createAction<any>(Type.DELETE__FEED_ITEM_BY_ID__SUCCESS);
  // export const failureDeleteFeedItemById = createAction<any>(Type.DELETE__FEED_ITEM_BY_ID__FAILURE);

  // export const initPatchFeedItemById = createAction<any>(Type.PATCH__FEED_ITEM_BY_ID__INIT);
  // export const successPatchFeedItemById = createAction<any>(Type.PATCH__FEED_ITEM_BY_ID__SUCCESS);
  // export const failurePatchFeedItemById = createAction<any>(Type.PATCH__FEED_ITEM_BY_ID__FAILURE);

  // export const initGetFeedById = createAction<any>(Type.GET__FEED_BY_ID__INIT);
  // export const successGetFeedById = createAction<any>(Type.GET__FEED_BY_ID__SUCCESS);
  // export const failureGetFeedById = createAction<any>(Type.GET__FEED_BY_ID__FAILURE);

  // export const initDeleteFmFeedItemById = createAction<any>(Type.DELETE__FM_FEED_ITEM_BY_ID__INIT);
  // export const successDeleteFmFeedItemById = createAction<any>(
  //   Type.DELETE__FM_FEED_ITEM_BY_ID__SUCCESS
  // );
  // export const failureDeleteFmFeedItemById = createAction<any>(
  //   Type.DELETE__FM_FEED_ITEM_BY_ID__FAILURE
  // );

  // export const initPatchFmFeedItemById = createAction<any>(Type.PATCH__FM_FEED_ITEM_BY_ID__INIT);
  // export const successPatchFmFeedItemById = createAction<any>(
  //   Type.PATCH__FM_FEED_ITEM_BY_ID__SUCCESS
  // );
  // export const failurePatchFmFeedItemById = createAction<any>(
  //   Type.PATCH__FM_FEED_ITEM_BY_ID__FAILURE
  // );

  // export const initGetFollowing = createAction(Type.GET__FOLLOWING__INIT);
  // export const successGetFollowing = createAction<any>(Type.GET__FOLLOWING__SUCCESS);
  // export const failureGetFollowing = createAction<any>(Type.GET__FOLLOWING__FAILURE);

  export const setUnreadFeeds = createAction<any>(Type.SET__UNREAD_FEEDS);
}

export type FeedActions = Omit<typeof IFeedActions, "Type">;
