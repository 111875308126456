import md5 from "md5";
import { AccountActions } from "../../account/account-actions";
import { EUserType, IUserInfo } from "../../account/account-models";
import { IChatInfo } from "../../chats/chats-models";
import { MessengerActions } from "../../chats/messenger/messenger-actions";
import { API_ROOT } from "../../globals";
import { LandingActions } from "../../landing/landing-actions";
// import { setMessengerPopup } from '../../chats/messenger/messenger-action-creators';
import { IDictionary } from "../../models";
import { PopupActions } from "../../popup/popup-actions";
import { ERequestStatus } from "../../utils/async";
import { getRequest } from "../../utils/fetch";
import { defined } from "../../utils/variable-evaluation";
import { ICONS_BUTTON } from "../icons/icons-common/IconsButton";
import {
  ESpinnerSize,
  ESpinnerType,
  LOADING_SPINNER,
} from "../icons/icons-common/IconsLoading";
import {
  // ALLOWED_IMAGE_TYPES,
  ALLOWED_VIDEO_TYPES,
  FORBIDDEN_IMAGE_TYPES,
} from "./uploader-helpers";

export const DEFAULT_UPLOAD_ASYNC_CONFIG: IDictionary<IAsyncConfig> = {
  [ERequestStatus.None]: { text: "", icon: ICONS_BUTTON.PlusCircle },
  [ERequestStatus.Fetching]: {
    text: "Saving",
    icon: LOADING_SPINNER(ESpinnerSize.Small)[ESpinnerType.Ios],
  },
  [ERequestStatus.Fetched]: { text: "Saved", icon: ICONS_BUTTON.Check },
  [ERequestStatus.Error]: { text: "Error", icon: ICONS_BUTTON.CrossCircle },
};

export interface IAsyncConfig {
  text?: React.ReactNode;
  icon?: React.ReactNode;
}

export interface IUploadConfig extends IUploadChatConfig, IUploadNonFeedConfig {
  requestStatus: ERequestStatus;
  popupActions?: PopupActions;
}

export interface IUploadChatConfig {
  chat?: IChatInfo;
}

export interface IUploadNonFeedConfig extends IUploadActions {
  username?: string;
  userType?: EUserType;
  requestStatus?: ERequestStatus;
}

export interface IUploadActions {
  accountActions?: AccountActions;
  messengerActions?: MessengerActions;
  landingActions?: LandingActions;
}

export interface IUploadData {
  md5Hash: string;
  mediaType: EMediaType;
  username: string; // For loader stack
}

export enum EMediaType {
  Avatar = "avatar",
  Featured = "featured",
  Image = "image",
  Video = "video",
  NotSupported = "Not Supported",
  ImageOrVideo = "imageOrVideo",
}

export function validateType(
  mediaType: EMediaType,
  fileType: string
): EMediaType | undefined {
  const isImage = fileType.indexOf("image") > -1;
  const isVideo = fileType.indexOf("video") > -1;

  // const isImageSupported = ALLOWED_IMAGE_TYPES.indexOf(fileType) > -1;
  const isImageSupported = FORBIDDEN_IMAGE_TYPES.indexOf(fileType) === -1;
  const isVideoSupported = ALLOWED_VIDEO_TYPES.indexOf(fileType) > -1;

  if (
    mediaType === EMediaType.Image ||
    mediaType === EMediaType.Avatar ||
    mediaType === EMediaType.Featured
  ) {
    // allow only images here
    if (isImage && isImageSupported) {
      return mediaType;
    } else {
      return EMediaType.NotSupported;
    }
  }
  if (mediaType === EMediaType.Video) {
    // allow only videos here
    if (isVideo && isVideoSupported) {
      return EMediaType.Video;
    } else {
      return EMediaType.NotSupported;
    }
  }
  if (mediaType === EMediaType.ImageOrVideo) {
    // both images and videos
    if (isImage) {
      if (isImageSupported) {
        return EMediaType.Image;
      } else {
        return EMediaType.NotSupported;
      }
    }
    if (isVideo) {
      if (isVideoSupported) {
        return EMediaType.Video;
      } else {
        return EMediaType.NotSupported;
      }
    }
  }
}

export function createMediaHash(file: File, user?: IUserInfo): string {
  const userId = user ? user._id : "";
  const mediaId = md5("" + file.name + file.size + file.lastModified + userId);
  return mediaId;
}

// use with Cloudinary
export function getMediaByHash(
  md5Hash: string // public_id
) {
  // try to get the existing media by using hashed string with file info
  return getRequest(`${API_ROOT}/v1/users/images/public_id/${md5Hash}`);
}

// use with Bendinary
export function getMediaAvailable(
  mediaId: string,
  isPaid: boolean
): Promise<any> {
  const type = isPaid ? "paid_message" : "message";
  // try to get the existing media by using mediaId
  return getRequest(
    `${API_ROOT}/v1/media/available?media_id=${mediaId}&type=${type}`
  );
}

// export function popupUsersCannotSendVideo(uploadConfig: IUploadConfig) {
//   setMessengerPopup(
//     {
//       type: EPopupMessengerType.PopupUsersCannotSendVideo,
//       props: {
//         onClose: () => {
//           setMessengerPopup(null, uploadConfig.messengerActions);
//         }
//       }
//     },
//     uploadConfig.messengerActions
//   );
// }

// export function popupConfirmSend(
//   uploadConfig: IUploadConfig,
//   mediaProperties,
//   mediaSrc,
//   onConfirm: () => void,
//   onClearValue: () => void
// ) {
//   setMessengerPopup(
//     {
//       type: EPopupMessengerType.PopupConfirmSend,
//       props: {
//         mediaProperties,
//         mediaSrc,
//         onConfirm: () => {
//           onConfirm();
//           setMessengerPopup(null, uploadConfig.messengerActions);
//         },
//         onClose: () => {
//           setMessengerPopup(null, uploadConfig.messengerActions);
//           onClearValue();
//         }
//       }
//     },
//     uploadConfig.messengerActions
//   );
// }

export function buttonContent(
  asyncConfig: any,
  requestStatus: ERequestStatus
): IAsyncConfig {
  let text = null;
  let icon = null;
  if (defined(requestStatus)) {
    text = DEFAULT_UPLOAD_ASYNC_CONFIG[requestStatus].text;
    icon = DEFAULT_UPLOAD_ASYNC_CONFIG[requestStatus].icon;
  }
  if (defined(asyncConfig)) {
    Object.keys(DEFAULT_UPLOAD_ASYNC_CONFIG).forEach((key) => {
      if (requestStatus === key) {
        if (defined(asyncConfig[key])) {
          text = defined(asyncConfig[key].text)
            ? asyncConfig[key].text
            : DEFAULT_UPLOAD_ASYNC_CONFIG[key].text;
          icon = defined(asyncConfig[key].icon)
            ? asyncConfig[key].icon
            : DEFAULT_UPLOAD_ASYNC_CONFIG[key].icon;
        }
      }
    });
  }
  return { text, icon };
}
