import { defined } from "./variable-evaluation";

export enum ERequestStatus {
  None = "None",
  Fetching = "Fetching",
  Fetched = "Fetched",
  Error = "Error",
}

export interface IRequest<RES> {
  status: ERequestStatus;
  responseData?: RES;
  error?: any;
}

export interface IGetRequest<RES> {
  status: ERequestStatus;
  responseData?: RES;
  error?: any;
  abortController?: AbortController;
}

export interface IPostRequest<REQ, RES> {
  status: ERequestStatus;
  requestData?: REQ;
  responseData?: RES;
  error?: any;
}

export function isNone<T>(object: IGetRequest<T>): boolean {
  return object && object.status === ERequestStatus.None;
}

export function isFetching<T>(object: IGetRequest<T>): boolean {
  return object && object.status === ERequestStatus.Fetching;
}

export function isFetched<T>(object: IGetRequest<T>): boolean {
  return object && object.status === ERequestStatus.Fetched;
}

export function isError<T>(object: IGetRequest<T>): boolean {
  return object && object.status === ERequestStatus.Error;
}

export function requestInit(dummyData?: any) {
  if (defined(dummyData)) {
    return {
      status: ERequestStatus.None,
      responseData: dummyData,
    };
  } else {
    return {
      status: ERequestStatus.None,
    };
  }
}

export function isContinuousArrayFetched(getRequest: IGetRequest<any>) {
  return (
    defined(getRequest) &&
    defined(getRequest.responseData) &&
    !isNone(getRequest) &&
    !isError(getRequest)
  );
}

export function chainAsyncStatusFromObject(
  firstAsync: IRequest<any>,
  secondAsync: IRequest<any>
) {
  if (!isFetched(firstAsync)) {
    return firstAsync.status;
  } else {
    return secondAsync.status;
  }
}

// TODO: improve to handle as array
export function chainAsyncStatus(
  firstAsync: ERequestStatus,
  secondAsync: ERequestStatus,
  thirdAsync?: ERequestStatus
) {
  if (firstAsync !== ERequestStatus.Fetched) {
    return firstAsync;
  }
  if (!thirdAsync) {
    return secondAsync;
  }
  if (secondAsync !== ERequestStatus.Fetched) {
    return secondAsync;
  }
  return thirdAsync;
}
